import { nanoid } from "nanoid";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DpElementNumberRecognition } from "src/app/types/dialplans";
import PropertyDisplay from "src/components/PropertyDisplay";
import SideBar, { SideBarProps } from "src/components/SideBar";
import {
  getDpElementTitle,
  numberRecognitionConditionsDisplay,
} from "src/utils/dialPlan";

type NumberRecognitionSideBarProps = SideBarProps & {
  dpElement: DpElementNumberRecognition;
};

const NumberRecognitionSideBar: React.FC<NumberRecognitionSideBarProps> = (
  props
) => {
  const { t } = useTranslation();
  const { dpElement } = props;
  const branchIds = useMemo(
    () => dpElement.branches.map(() => nanoid()),
    [dpElement]
  );
  return (
    <SideBar {...props}>
      <div className="px-3 py-2">
        <div className="text-l font-semibold mb-8">
          {t("dp-editor.number-recognition-side-bar.title")}
        </div>
        {branchIds.map((branchId, idx) => (
          <div
            className="border border-r-0 border-l-0 border-t-0 border-solid border-gs-800 py-4"
            key={branchId}
          >
            <PropertyDisplay
              className="mb-3"
              title={
                <span className="text-amber-500">
                  {t("controls.condition")} #{idx + 1}
                </span>
              }
              value={getDpElementTitle(dpElement, { branch: idx })}
            />
            <PropertyDisplay
              title={`${t("controls.numbers")}`}
              value={numberRecognitionConditionsDisplay(
                dpElement.branches[idx]
              )}
            />
          </div>
        ))}
        <div className="border border-r-0 border-l-0 border-t-0 border-solid border-gs-800 py-4">
          <PropertyDisplay
            className="mb-3"
            title={
              <span className="text-amber-500">
                {t("dp-editor.dp-element.default-branch")}
              </span>
            }
            value={t("controls.all-other-numbers").toString()}
          />
        </div>
      </div>
    </SideBar>
  );
};

export default NumberRecognitionSideBar;
