import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/app/store";
import styles from "./Menu.module.css";
import {
  DpElement,
  DpElementCombined,
  DpElementIdentity,
  DpElementResource,
  DpElementType,
} from "src/app/types/dialplans";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-regular-svg-icons/faList";
import { useDrag } from "react-dnd";
import { DRAGGABLE_TYPE } from "./consts";
import { ElementDragItem } from "./Element";
import { faLongArrowLeft } from "@fortawesome/pro-regular-svg-icons/faLongArrowLeft";
import { faFilter } from "@fortawesome/pro-regular-svg-icons/faFilter";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import DropdownSelect from "src/components/DropdownSelect";
import { pushDialPlanElement, selectDialPlanState } from "./dpEditorSlice";
import { getDpElementIcon, getDpElementsByType } from "src/utils/dialPlan";
import { nanoid } from "nanoid";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { useTranslation } from "react-i18next";
import { useLoadDialplanElements } from "src/utils/hooks";

type MenuItemParams = {
  id: string;
  title: string;
  subTitle?: string;
  icon?: IconProp;
  draggable?: boolean;
  dpElement?: DpElementCombined;
  subPage?: {
    title: string;
    items: MenuItemParams[];
    resourcesFilter?: {
      show: boolean;
      type: DpElementType;
    };
  };
};

enum ResourcesFilterOption {
  all = "all",
  used = "used",
  unused = "unused",
}

const getUsedResourceIds = (
  dialPlan: DpElementCombined[],
  type: DpElementType
) => {
  if (!dialPlan || !dialPlan.length) {
    return [];
  }
  const ids: number[] = [];
  getDpElementsByType(dialPlan, type).forEach((item) => {
    const id =
      item.type === DpElementType.user
        ? (item as DpElementIdentity).userId
        : (item as DpElementResource).id;
    if (ids.includes(id)) {
      return;
    }
    ids.push(id);
  });
  return ids;
};

const filterMenuItems = (menuItems: MenuItemParams[], query: string) => {
  let filteredMenuItems: MenuItemParams[] = [];
  const lowerQuery = query.toLowerCase();
  menuItems.forEach((item) => {
    if (
      !item.subPage &&
      (item.title.toLowerCase().includes(lowerQuery) ||
        item.subTitle?.toLowerCase().includes(lowerQuery))
    ) {
      filteredMenuItems.push(item);
    }
    if (item.subPage) {
      filteredMenuItems = [
        ...filteredMenuItems,
        ...filterMenuItems(item.subPage.items, query),
      ];
    }
  });
  return filteredMenuItems;
};

const MenuItem: React.FC<MenuItemParams & { onClick?: () => void }> = ({
  icon,
  title,
  subTitle,
  onClick,
  subPage,
  draggable,
  dpElement,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: DRAGGABLE_TYPE,
    item: {
      dpElement,
    } as ElementDragItem,
    canDrag: !!draggable,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      className={styles.menuItem}
      onClick={onClick}
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {icon ? (
        <div className={styles.menuItemIcon}>
          <FontAwesomeIcon icon={icon} />
        </div>
      ) : null}
      <div className={styles.menuItemContent}>
        <div className={styles.menuItemTitle}>
          <div className={styles.menuItemTitleText}>{title}</div>
          {subPage ? (
            <FontAwesomeIcon
              icon={faList}
              className={styles.menuItemListIcon}
            />
          ) : null}{" "}
        </div>
        <div className={styles.menuItemSubTitle}>{subTitle}</div>
      </div>
    </div>
  );
};

const Menu: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [openedSubMenu, setOpenedSubMenu] = useState<MenuItemParams>();
  const [resourcesFilterValue, setResourcesFilterValue] =
    useState<ResourcesFilterOption>(ResourcesFilterOption.all);
  const { dialPlan } = useSelector(selectDialPlanState);
  const [searchQuery, setSearchQuery] = useState("");
  const searchInputChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value);
    },
    [setSearchQuery]
  );
  const { users, queues, prompts, conferences, voicemails, forwards } =
    useLoadDialplanElements();
  const usedResources = useMemo(() => {
    const result: { [key in DpElementType]?: number[] } = {};
    [
      DpElementType.user,
      DpElementType.queue,
      DpElementType.prompt,
      DpElementType.conference,
      DpElementType.voicemail,
      DpElementType.forward,
    ].forEach((type) => {
      result[type] = getUsedResourceIds(dialPlan, type);
    });
    return result;
  }, [dialPlan]);

  const menuItems: MenuItemParams[] = useMemo(() => {
    return [
      {
        id: nanoid(),
        title: `${t("dp-editor.menu.persons")} (${users.length})`,
        icon: getDpElementIcon(DpElementType.user),
        subPage: {
          title: `${t("dp-editor.menu.persons")} (${users.length})`,
          items: users.map((user) => ({
            id: nanoid(),
            title: user.name,
            subTitle: user.username,
            icon: getDpElementIcon(DpElementType.user),
            draggable: true,
            dpElement: {
              type: DpElementType.user,
              name: user.name,
              userId: user.entityId,
            },
          })),
          resourcesFilter: {
            show: true,
            type: DpElementType.user,
          },
        },
      },
      {
        id: nanoid(),
        title: `${t("dp-editor.menu.prompts")} (${prompts.length})`,
        icon: getDpElementIcon(DpElementType.prompt),
        subPage: {
          title: `${t("dp-editor.menu.prompts")} (${prompts.length})`,
          items: prompts.map((prompt) => ({
            id: nanoid(),
            title: prompt.name,
            icon: getDpElementIcon(DpElementType.prompt),
            draggable: true,
            dpElement: {
              type: DpElementType.prompt,
              id: prompt.resourceId,
              name: prompt.name,
            },
          })),
          resourcesFilter: {
            show: true,
            type: DpElementType.prompt,
          },
        },
      },
      {
        id: nanoid(),
        title: `${t("dp-editor.menu.voicemails")} (${voicemails.length})`,
        icon: getDpElementIcon(DpElementType.voicemail),
        subPage: {
          title: `${t("dp-editor.menu.voicemails")} (${voicemails.length})`,
          items: voicemails.map((voicemail) => ({
            id: nanoid(),
            title: voicemail.name,
            icon: getDpElementIcon(DpElementType.voicemail),
            draggable: true,
            dpElement: {
              type: DpElementType.voicemail,
              id: voicemail.resourceId,
              name: voicemail.name,
            },
          })),
          resourcesFilter: {
            show: true,
            type: DpElementType.voicemail,
          },
        },
      },
      {
        id: nanoid(),
        title: `${t("dp-editor.menu.queues")} (${queues.length})`,
        icon: getDpElementIcon(DpElementType.queue),
        subPage: {
          title: `${t("dp-editor.menu.queues")} (${queues.length})`,
          items: queues.map((queue) => ({
            id: nanoid(),
            title: queue.name,
            icon: getDpElementIcon(DpElementType.queue),
            draggable: true,
            dpElement: {
              type: DpElementType.queue,
              id: queue.resourceId,
              name: queue.name,
            },
          })),
          resourcesFilter: {
            show: true,
            type: DpElementType.queue,
          },
        },
      },
      {
        id: nanoid(),
        title: `${t("dp-editor.menu.conferences")} (${conferences.length})`,
        icon: getDpElementIcon(DpElementType.conference),
        subPage: {
          title: `${t("dp-editor.menu.conferences")} (${conferences.length})`,
          items: conferences.map((conference) => ({
            id: nanoid(),
            title: conference.name,
            icon: getDpElementIcon(DpElementType.conference),
            draggable: true,
            dpElement: {
              type: DpElementType.conference,
              id: conference.resourceId,
              name: conference.name,
            },
          })),
          resourcesFilter: {
            show: true,
            type: DpElementType.conference,
          },
        },
      },
      {
        id: nanoid(),
        title: `${t("dp-editor.menu.forwards")} (${forwards.length})`,
        icon: getDpElementIcon(DpElementType.forward),
        subPage: {
          title: `${t("dp-editor.menu.forwards")} (${forwards.length})`,
          items: forwards.map((forward) => ({
            id: nanoid(),
            title: forward.name,
            icon: getDpElementIcon(DpElementType.forward),
            draggable: true,
            dpElement: {
              type: DpElementType.forward,
              id: forward.resourceId,
              name: forward.name,
            },
          })),
          resourcesFilter: {
            show: true,
            type: DpElementType.forward,
          },
        },
      },
      {
        id: nanoid(),
        title: t("dp-editor.dp-element.number-recognition"),
        icon: getDpElementIcon(DpElementType.numberRecognition),
        draggable: true,
        dpElement: {
          type: DpElementType.numberRecognition,
          branches: [],
          defaultSteps: [],
        },
      },
      {
        id: nanoid(),
        title: t("dp-editor.dp-element.time-switch"),
        icon: getDpElementIcon(DpElementType.timeSwitch),
        draggable: true,
        dpElement: {
          type: DpElementType.timeSwitch,
        },
      },
      {
        id: nanoid(),
        title: t("dp-editor.dp-element.ivr-menu"),
        icon: getDpElementIcon(DpElementType.ivr),
        draggable: true,
        dpElement: {
          type: DpElementType.ivr,
        },
      },
      {
        id: nanoid(),
        title: t("dp-editor.dp-element.dp-switch"),
        icon: getDpElementIcon(DpElementType.dpSwitch),
        draggable: true,
        dpElement: {
          type: DpElementType.dpSwitch,
        },
      },
      {
        id: nanoid(),
        title: t("dp-editor.dp-element.busy"),
        icon: getDpElementIcon(DpElementType.busy),
        draggable: true,
        dpElement: {
          type: DpElementType.busy,
        },
      },
      {
        id: nanoid(),
        title: t("dp-editor.dp-element.hangup"),
        icon: getDpElementIcon(DpElementType.hangup),
        draggable: true,
        dpElement: {
          type: DpElementType.hangup,
        },
      },
      {
        id: nanoid(),
        title: t("dp-editor.dp-element.prefix"),
        icon: getDpElementIcon(DpElementType.prefix),
        draggable: true,
        dpElement: {
          type: DpElementType.prefix,
        },
      },
      {
        id: nanoid(),
        title: t("dp-editor.dp-element.dtmf-input"),
        icon: getDpElementIcon(DpElementType.dtmfInput),
        draggable: true,
        dpElement: {
          type: DpElementType.dtmfInput,
        },
      },
    ];
  }, [users, queues, prompts, conferences, voicemails, forwards, t]);

  const visibleMenuItems: MenuItemParams[] = useMemo(() => {
    let visibleItems = menuItems;
    if (!openedSubMenu) {
      return searchQuery
        ? filterMenuItems(visibleItems, searchQuery)
        : visibleItems;
    }
    visibleItems = openedSubMenu?.subPage?.items || [];
    if (
      resourcesFilterValue !== ResourcesFilterOption.all &&
      openedSubMenu.subPage?.resourcesFilter
    ) {
      visibleItems = visibleItems.filter((item) => {
        const resourceType = openedSubMenu.subPage?.resourcesFilter?.type;
        if (!resourceType) {
          return true;
        }
        let usedIds = usedResources[resourceType];
        if (!usedIds) {
          return true;
        }
        const id =
          resourceType === DpElementType.user
            ? (item.dpElement as DpElementIdentity)?.userId
            : (item.dpElement as DpElementResource)?.id;
        return resourcesFilterValue === ResourcesFilterOption.used
          ? usedIds.includes(id)
          : !usedIds.includes(id);
      });
    }
    return searchQuery
      ? filterMenuItems(visibleItems, searchQuery)
      : visibleItems;
  }, [
    openedSubMenu,
    menuItems,
    resourcesFilterValue,
    searchQuery,
    usedResources,
  ]);

  const subPageClickHandler = useCallback((item: MenuItemParams) => {
    setOpenedSubMenu(item);
  }, []);
  const dpElementItemClick = useCallback(
    (dpElement: DpElement) => {
      dispatch(
        pushDialPlanElement({ dpElement, path: [], openConfigModal: true })
      );
    },
    [dispatch]
  );
  const backButtonClickHandler = useCallback(() => {
    setOpenedSubMenu(undefined);
    setResourcesFilterValue(ResourcesFilterOption.all);
  }, []);
  const resourcesFilterSelectHandler = useCallback((selectedValue: string) => {
    setResourcesFilterValue(selectedValue as ResourcesFilterOption);
  }, []);
  const clearSearchInput = useCallback(() => {
    setSearchQuery("");
  }, [setSearchQuery]);
  const searchEscListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        clearSearchInput();
      }
    },
    [clearSearchInput]
  );
  const searchFocusHandler = useCallback(() => {
    document.addEventListener("keydown", searchEscListener);
  }, [searchEscListener]);
  const searchBlurHandler = useCallback(() => {
    document.removeEventListener("keydown", searchEscListener);
  }, [searchEscListener]);
  return (
    <div className={styles.menu}>
      <div className={styles.heading}>
        {!openedSubMenu ? (
          <div className={styles.searchInputWrap}>
            <input
              placeholder={t("dp-editor.menu.search-input-placeholder")}
              className={styles.searchInput}
              onChange={searchInputChangeHandler}
              onFocus={searchFocusHandler}
              onBlur={searchBlurHandler}
              value={searchQuery}
            />
            <FontAwesomeIcon
              onClick={searchQuery ? clearSearchInput : undefined}
              icon={searchQuery ? faTimes : faSearch}
              className={styles.searchInputIcon}
            />
          </div>
        ) : null}
        {openedSubMenu ? (
          <>
            <button
              className={styles.backButton}
              onClick={backButtonClickHandler}
            >
              <FontAwesomeIcon
                className={styles.backButtonIcon}
                icon={faLongArrowLeft}
              />
              {t("dp-editor.menu.back-to-all-elements")}
            </button>
            <div className={styles.subMenuTitle}>
              {openedSubMenu.subPage?.title}
            </div>
            {openedSubMenu.subPage?.resourcesFilter ? (
              <div className={styles.resourcesFilter}>
                <DropdownSelect
                  items={[
                    {
                      value: ResourcesFilterOption.all,
                      title: t("dp-editor.menu.filter-show-all"),
                    },
                    {
                      value: ResourcesFilterOption.used,
                      title: t("dp-editor.menu.filter-show-used"),
                    },
                    {
                      value: ResourcesFilterOption.unused,
                      title: t("dp-editor.menu.filter-show-unused"),
                    },
                  ]}
                  icon={faFilter}
                  value={resourcesFilterValue}
                  onSelect={resourcesFilterSelectHandler}
                  active={resourcesFilterValue !== ResourcesFilterOption.all}
                />
              </div>
            ) : null}
          </>
        ) : null}
      </div>
      {visibleMenuItems.length ? (
        visibleMenuItems.map((item) => (
          <MenuItem
            {...item}
            key={item.id}
            onClick={
              item.subPage
                ? subPageClickHandler.bind(null, item)
                : item.dpElement
                ? dpElementItemClick.bind(null, item.dpElement)
                : undefined
            }
          />
        ))
      ) : (
        <div className="p-10 text-center text-xs text-gs-700">
          {searchQuery
            ? t("dp-editor.menu.no-elements-found-msg")
            : t("dp-editor.menu.no-elements")}
        </div>
      )}
    </div>
  );
};

export default Menu;
