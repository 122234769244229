import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect } from "react";
import Button, {
  ButtonColor,
  ButtonFill,
  ButtonProps,
  ButtonSize,
} from "src/components/Button";
import { useWizard, Wizard } from "react-use-wizard";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import styles from "./Wizard.module.css";
import { useTranslation } from "react-i18next";
import classNamesBind from "classnames/bind";
import { usePrevious } from "src/utils/hooks";

const classNames = classNamesBind.bind(styles);

const WizardBreadcrumb: React.FC<{
  items: { label: string; active?: boolean }[];
  blockGoBack?: boolean;
}> = ({ items, blockGoBack }) => {
  const { activeStep, goToStep } = useWizard();
  const onBreadcrumbClick = useCallback(
    (idx) => {
      goToStep(idx);
    },
    [goToStep]
  );
  const onBreadcrumbKeypress = (
    idx: number,
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.key === "Enter") {
      onBreadcrumbClick(idx);
    }
  };
  return (
    <div className="flex items-center cursor-default mb-6">
      {items.map((item, idx) => {
        const isItemClickable =
          item.active || (!blockGoBack && idx < activeStep);
        return (
          <div
            className={classNames("text-xs text-gs-700 mr-2", {
              "studio-focusable": isItemClickable,
            })}
            key={`${item.label}-${idx}`}
          >
            <span
              className={classNames("mr-2", {
                "text-gs-400": idx === activeStep,
                "cursor-pointer hover:text-gs-400": isItemClickable,
                underline: isItemClickable && activeStep !== idx,
                "breadcrumb-item--focusable": isItemClickable,
              })}
              tabIndex={isItemClickable && idx !== activeStep ? 0 : undefined}
              onClick={
                isItemClickable ? onBreadcrumbClick.bind(null, idx) : undefined
              }
              onKeyPress={onBreadcrumbKeypress.bind(null, idx)}
            >
              {item.label}
            </span>
            {idx < items.length - 1 ? (
              <FontAwesomeIcon className="text-xs" icon={faChevronRight} />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export const WizardStep: React.FC<{
  buttons?: {
    text: string;
    props?: ButtonProps;
  }[];
  hideBackButton?: boolean;
}> = ({ children, buttons, hideBackButton }) => {
  const { t } = useTranslation();
  const { previousStep, isFirstStep } = useWizard();
  return (
    <>
      <div
        className={classNames("body", "h-full overflow-y-auto mb-12 pb-8")}
        tabIndex={-1}
      >
        {children}
      </div>
      <div className="absolute bottom-0 left-0 w-full">
        {buttons?.map((button, idx) => (
          <Button
            key={idx}
            {...{
              size: ButtonSize.large,
              fill: ButtonFill.solid,
              ...button.props,
              className: classNames(button.props?.className, "mr-2"),
            }}
          >
            {button.text}
          </Button>
        ))}
        {!isFirstStep && !hideBackButton ? (
          <Button
            size={ButtonSize.large}
            fill={ButtonFill.clear}
            color={ButtonColor.grey}
            onClick={previousStep}
            className="mr-2"
          >
            {t("controls.back")}
          </Button>
        ) : null}
      </div>
    </>
  );
};

const WizardStepChangeHandler: React.FC<{
  onChange?: (activeStep: number, previousStep: number) => void;
}> = ({ onChange }) => {
  const { activeStep } = useWizard();
  const previousActiveStep = usePrevious(activeStep);
  useEffect(() => {
    if (
      !onChange ||
      previousActiveStep === undefined ||
      previousActiveStep === activeStep
    ) {
      return;
    }
    onChange(activeStep, previousActiveStep);
  }, [activeStep, previousActiveStep, onChange]);
  return null;
};

export type WizardContentProps = {
  breadcrumbItems?: { label: string; active?: boolean }[];
  onActiveStepChange?: (activeStep: number, previousStep: number) => void;
  breadcrumbBlockGoBack?: boolean;
};

export const WizardContent: React.FC<WizardContentProps> = ({
  breadcrumbItems,
  onActiveStepChange,
  breadcrumbBlockGoBack,
  children,
}) => {
  return (
    <Wizard
      header={
        <>
          {breadcrumbItems && breadcrumbItems.length ? (
            <WizardBreadcrumb
              items={breadcrumbItems}
              blockGoBack={breadcrumbBlockGoBack}
            />
          ) : null}
          <WizardStepChangeHandler onChange={onActiveStepChange} />
        </>
      }
    >
      {children}
    </Wizard>
  );
};

export default WizardContent;
