import i18n from "i18next";
import { AxiosError } from "axios";

export const handleApiError = (defaultMessage?: string, error?: AxiosError) => {
  let message: string = defaultMessage || i18n.t("error-messages.general");
  if (error?.response) {
    if (error.response.status === 401) {
      message = i18n.t("error-messages.session-expired");
    } else if (error.response.data?.message) {
      message = error.response.data?.message;
    }
  }
  if (!navigator.onLine) {
    message = i18n.t("error-messages.no-connection");
  }
  alert(message);
};
