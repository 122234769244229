import React, { useCallback } from "react";
import styles from "./Canvas.module.css";
import { useSelector } from "react-redux";
import {
  moveDialPlanElement,
  pushDialPlanElement,
  removeDialPlanElementBranch,
  selectDialPlanState,
  selectDialPlanPermission,
  collapseToggleElement,
  openConfigModal,
  openSideBar,
} from "./dpEditorSlice";
import { removeDialPlanElement } from "./dpEditorSlice";
import Branch from "./Branch";
import {
  DpElementCombined,
  DpElementNumberRecognition,
  DpElementType,
} from "src/app/types/dialplans";
import { ModalFormAction } from "./types";
import { useAppDispatch } from "src/app/store";
import { ElementExtraProps } from "./Element";
import { DpModalType } from "src/app/types";
import { CompassUserPermission } from "src/app/types/compass";
import {
  getModalForDpElement,
  getSideBarForDpElement,
} from "src/utils/dialPlan";
import withScrolling from "react-dnd-scrolling";

const ScrollingComponent = withScrolling("div");

const Canvas: React.FC = () => {
  const { dialPlan, numberDescription } = useSelector(selectDialPlanState);
  const permission = useSelector(selectDialPlanPermission);
  const dispatch = useAppDispatch();
  const elementAddHandler = useCallback(
    (dpElement: DpElementCombined, path: number[][], idx?: number) => {
      dispatch(
        pushDialPlanElement({ dpElement, path, idx, openConfigModal: true })
      );
    },
    [dispatch]
  );
  const elementRemoveHandler = useCallback(
    (dpElement: DpElementCombined, extra?: ElementExtraProps) => {
      if (!extra?.dialPlanPosition) {
        return;
      }
      if (extra.branch !== undefined && dpElement?._temp?.id) {
        return dispatch(
          removeDialPlanElementBranch({
            elementId: dpElement._temp.id,
            branchIdx: extra.branch,
          })
        );
      }
      if (dpElement?._temp?.id) {
        dispatch(
          removeDialPlanElement({
            id: dpElement._temp.id,
          })
        );
      }
    },
    [dispatch]
  );
  const elementMoveHandler = useCallback(
    (
      origin: {
        path: number[][];
        idx: number;
      },
      destination: {
        path: number[][];
        idx?: number;
      }
    ) => {
      dispatch(moveDialPlanElement({ origin, destination }));
    },
    [dispatch]
  );
  const elementClickHandler = useCallback(
    (dpElement: DpElementCombined, extra?: ElementExtraProps) => {
      // NOTE: don't handle click on default branch
      if (
        extra?.branch !== undefined &&
        ((dpElement.type === DpElementType.numberRecognition &&
          (dpElement as DpElementNumberRecognition).branches.length <=
            extra?.branch) ||
          !extra.dialPlanPosition)
      ) {
        return;
      }
      let modalType: DpModalType | null = getModalForDpElement(
        dpElement,
        extra?.branch
      );
      const sideBarType = getSideBarForDpElement(dpElement, extra?.branch);
      if (sideBarType) {
        dispatch(
          openSideBar({
            type: sideBarType,
            id: dpElement._temp!.id!,
            branch: extra?.branch,
          })
        );
      } else if (modalType) {
        if (dpElement?._temp?.id) {
          dispatch(
            openConfigModal({
              type: modalType,
              id: dpElement?._temp?.id,
              action: ModalFormAction.update,
              branch: extra?.branch,
            })
          );
        }
      }
    },
    [dispatch]
  );
  const toggleCollapseElementHandler = useCallback(
    (id: string) => {
      dispatch(collapseToggleElement(id));
    },
    [dispatch]
  );
  return (
    <ScrollingComponent className={styles.canvas}>
      <div className={styles.callBox}>{numberDescription}</div>
      <div className={styles.branchWrap}>
        <Branch
          dpElements={dialPlan}
          onElementAdd={elementAddHandler}
          onElementClick={elementClickHandler}
          onElementRemove={elementRemoveHandler}
          onElementMove={elementMoveHandler}
          onToggleCollapse={toggleCollapseElementHandler}
          disabled={permission !== CompassUserPermission.permWrite}
          path={[]}
        />
      </div>
    </ScrollingComponent>
  );
};

export default Canvas;
