import React, { ReactNode } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthStatus } from "src/features/Auth/authSlice";

type Props = {
  children: ReactNode;
} & RouteProps;

const PrivateRoute = ({ children, ...rest }: Props) => {
  const authStatus = useSelector(selectAuthStatus);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        switch (authStatus.isAuthenticated) {
          case true:
            return children;
          case false:
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location },
                }}
              />
            );
          default:
            return <div>Loading...</div>;
        }
      }}
    />
  );
};

export default PrivateRoute;
