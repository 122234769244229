import { restApi } from "src/app/api";
import { CompassResourceType } from "src/app/types/compass";
import { sortByProperty, sortIgnoreCaseComparator } from ".";

export const getOrderedResources = async <T>(
  companyId: number,
  type: CompassResourceType
) => {
  let getUrl: string = "";
  switch (type) {
    case CompassResourceType.conference:
    case CompassResourceType.prompt:
    case CompassResourceType.voicemail:
      getUrl = `company/${companyId}/resourcesFiltered?filter=${type}`;
      break;
    case CompassResourceType.forward:
      getUrl = `company/${companyId}/forwards`;
      break;
    case CompassResourceType.externalNumber:
      getUrl = `company/${companyId}/externalNumbers`;
      break;
    case CompassResourceType.extension:
      getUrl = `company/${companyId}/extensions`;
      break;
    case CompassResourceType.queue:
      getUrl = `company/${companyId}/queues`;
      break;
    case CompassResourceType.user:
      getUrl = `company/${companyId}/users`;
      break;
    case CompassResourceType.dpswitch:
      getUrl = `company/${companyId}/dpSwitches`;
      break;
  }
  if (getUrl) {
    const items = (await restApi.get<T[]>(getUrl)).data;
    return sortByProperty(items, "name", sortIgnoreCaseComparator);
  } else {
    throw new Error(`"${type}" not supported`);
  }
};
