import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nanoid } from "nanoid";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./Checkbox.module.css";
import classNamesBind from "classnames/bind";
import { emulateTab } from "emulate-tab";

const classNames = classNamesBind.bind(styles);

const Checkbox = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    parentClassNames?: string;
  }
>((props, ref) => {
  const checkboxId = useMemo(() => nanoid().replace(/[^a-zA-Z]+/g, ""), []);
  const [checked, setChecked] = useState<boolean>();
  const onCheckboxChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(e);
      }
      setChecked(e.target.checked);
    },
    [props]
  );
  const $wrapRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const $checkbox: HTMLInputElement | null = document.querySelector(
      `#${checkboxId}`
    );
    if ($checkbox) {
      setChecked($checkbox.checked);
    }
    if (props.autoFocus) {
      $wrapRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const nativeProps = useMemo(() => {
    const out = { ...props };
    delete out.parentClassNames;
    delete out.autoFocus;
    return out;
  }, [props]);
  const toggleCheckboxHandler = useCallback(() => {
    const $checkbox: HTMLInputElement | null = document.querySelector(
      `#${checkboxId}`
    );
    if ($checkbox) {
      $checkbox.checked = !$checkbox.checked;
      // Trigger change event
      $checkbox.click();
    }
  }, [checkboxId]);
  const keypressHandler: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === "Enter") {
      toggleCheckboxHandler();
      emulateTab();
    }
  };
  return (
    <div
      ref={$wrapRef}
      tabIndex={0}
      className={classNames(
        props.parentClassNames,
        "studio-focusable",
        "p-4",
        "cursor-pointer",
        "border",
        "border-solid",
        "rounded-lg",
        "flex",
        "items-center",
        checked ? "border-primary-400" : "border-outline-500",
        checked ? "bg-primary-700" : "bg-gs-900"
      )}
      onClick={toggleCheckboxHandler}
      onKeyPress={keypressHandler}
    >
      <input
        type="checkbox"
        ref={ref}
        {...{
          ...nativeProps,
          className: classNames(props.className, "hidden"),
          id: checkboxId,
          onChange: onCheckboxChangeHandler,
        }}
      />
      <div
        className={classNames(
          "mr-2 border border-solid rounded w-3.5 h-3.5 flex align-middle justify-center",
          checked ? "border-primary-300" : "border-gs-400"
        )}
      >
        {checked ? (
          <FontAwesomeIcon
            className={`text-primary-200 text-xs ${styles.icon}`}
            icon={faCheck}
          />
        ) : null}
      </div>
      <span className="text-s font-bold text-gs-100">{props.label}</span>
    </div>
  );
});

export default Checkbox;
