import {
  loadDpSwitches,
  selectDpSwitchesState,
} from "src/features/DpSwitches/dpSwitchesSlice";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/app/store";
import {
  loadConferences,
  selectConferencesState,
} from "src/features/Conferences/conferencesSlice";
import {
  loadForwards,
  selectForwardsState,
} from "src/features/Forwards/forwardsSlice";
import {
  loadPrompts,
  selectPromptsState,
} from "src/features/Prompts/promptsSlice";
import { loadQueues, selectQueuesState } from "src/features/Queues/queuesSlice";
import { loadUsers, selectUsersState } from "src/features/Users/usersSlice";
import {
  loadVoicemails,
  selectVoicemailsState,
} from "src/features/Voicemails/voicemailsSlice";
import {
  selectDialPlanState,
  selectSavedDialPlan,
} from "src/features/DpEditor/dpEditorSlice";
import { DpElementDpSwitch, DpElementType } from "src/app/types/dialplans";
import { getDpElementsByType } from "./dialPlan";

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useLastDefinded = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  if (value) {
    ref.current = value;
  }
  return ref.current;
};

export const useLoadDialplanElements = () => {
  const dispatch = useAppDispatch();
  const {
    isLoading: usersIsLoading,
    loaded: usersLoaded,
    items: users,
  } = useSelector(selectUsersState);
  const {
    isLoading: queuesIsLoading,
    loaded: queuesLoaded,
    items: queues,
  } = useSelector(selectQueuesState);
  const {
    isLoading: promptsIsLoading,
    loaded: promptsLoaded,
    items: prompts,
  } = useSelector(selectPromptsState);
  const {
    isLoading: conferencesIsLoading,
    loaded: conferencesLoaded,
    items: conferences,
  } = useSelector(selectConferencesState);
  const {
    isLoading: voicemailsIsLoading,
    loaded: voicemailsLoaded,
    items: voicemails,
  } = useSelector(selectVoicemailsState);
  const {
    isLoading: forwardsIsLoading,
    loaded: forwardsLoaded,
    items: forwards,
  } = useSelector(selectForwardsState);
  const {
    isLoading: dpSwitchesIsLoading,
    loaded: dpSwitchesLoaded,
    items: dpSwitches,
  } = useSelector(selectDpSwitchesState);
  useEffect(() => {
    if (!usersIsLoading && !usersLoaded) {
      dispatch(loadUsers());
    }
    if (!queuesIsLoading && !queuesLoaded) {
      dispatch(loadQueues());
    }
    if (!promptsIsLoading && !promptsLoaded) {
      dispatch(loadPrompts());
    }
    if (!conferencesIsLoading && !conferencesLoaded) {
      dispatch(loadConferences());
    }
    if (!voicemailsIsLoading && !voicemailsLoaded) {
      dispatch(loadVoicemails());
    }
    if (!forwardsIsLoading && !forwardsLoaded) {
      dispatch(loadForwards());
    }
    if (!dpSwitchesIsLoading && !dpSwitchesLoaded) {
      dispatch(loadDpSwitches());
    }
  }, [
    usersIsLoading,
    usersLoaded,
    queuesIsLoading,
    queuesLoaded,
    promptsIsLoading,
    promptsLoaded,
    conferencesIsLoading,
    conferencesLoaded,
    voicemailsIsLoading,
    voicemailsLoaded,
    forwardsIsLoading,
    forwardsLoaded,
    dpSwitchesIsLoading,
    dpSwitchesLoaded,
    dispatch,
  ]);
  return {
    users,
    queues,
    prompts,
    conferences,
    voicemails,
    forwards,
    dpSwitches,
  };
};

export const useUsedShortCodes = (skipShortCode?: number) => {
  const { dpSwitches } = useLoadDialplanElements();
  let usedShortCodes = dpSwitches.map((item) => item.shortcode);

  const { dialPlan } = useSelector(selectDialPlanState);
  const savedDialPlan = useSelector(selectSavedDialPlan);

  const savedDialPlanUsedShortCodes = getDpElementsByType(
    savedDialPlan,
    DpElementType.dpSwitch
  ).map((item) => (item as DpElementDpSwitch).shortcode);
  const currentDialPlanUsedShortCodes = getDpElementsByType(
    dialPlan,
    DpElementType.dpSwitch
  ).map((item) => (item as DpElementDpSwitch).shortcode);

  // filter out shortcodes that was used in dialplan
  // but removed from it before it's saved
  usedShortCodes = usedShortCodes.filter(
    (item) =>
      !(
        savedDialPlanUsedShortCodes.includes(item) &&
        !currentDialPlanUsedShortCodes.includes(item)
      )
  );

  // add possible new shortcodes from the dialplan
  // that are not saved yet
  currentDialPlanUsedShortCodes.forEach((item) => {
    if (!usedShortCodes.includes(item)) {
      usedShortCodes.push(item);
    }
  });

  if (skipShortCode === undefined) {
    return usedShortCodes;
  }
  return usedShortCodes.filter((item) => item !== skipShortCode);
};
