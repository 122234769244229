import React from "react";
import { useDrop } from "react-dnd";
import { DRAGGABLE_TYPE } from "./consts";
import { ElementDragItem } from "./Element";
import { useTranslation } from "react-i18next";
import styles from "./ElementDropBox.module.css";

const ElementDropBox: React.FC<{
  onDropped?: (props: ElementDragItem) => void;
  className?: string;
}> = ({ onDropped, className }) => {
  const { t } = useTranslation();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: DRAGGABLE_TYPE,
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item: ElementDragItem) => {
      if (!onDropped) {
        return;
      }
      onDropped(item);
    },
  }));
  return (
    <div ref={drop} className={className}>
      <div className={styles.box} data-over={isOver}>
        {t("dp-editor.element-drop-box-component.next-action")}
      </div>
    </div>
  );
};

export default ElementDropBox;
