import {
  ElementDialPlanPosition,
  ElementExtraProps,
} from "src/features/DpEditor/Element";
import {
  DpElementCombined,
  DpElementDpSwitch,
  DpElementDtmf,
  DpElementIdentity,
  DpElementIvr,
  DpElementNumberRecognition,
  DpElementPrefix,
  DpElementResource,
  DpElementTimeSwitch,
  DpElementType,
  DpElementBranch,
  DpElementNumberRecognitionBranch,
} from "src/app/types/dialplans";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faPhoneSlash } from "@fortawesome/pro-regular-svg-icons/faPhoneSlash";
import { faShare } from "@fortawesome/pro-regular-svg-icons/faShare";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { faUserClock } from "@fortawesome/pro-regular-svg-icons/faUserClock";
import { faUserFriends } from "@fortawesome/pro-regular-svg-icons/faUserFriends";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { copyObject } from ".";
import i18n from "i18next";
import { nanoid } from "nanoid";
import { faWaveform } from "@fortawesome/pro-regular-svg-icons/faWaveform";
import { faVoicemail } from "@fortawesome/pro-regular-svg-icons/faVoicemail";
import { faPhoneRotary } from "@fortawesome/pro-regular-svg-icons/faPhoneRotary";
import { faCodeBranch } from "@fortawesome/pro-regular-svg-icons/faCodeBranch";
import { faKeyboard } from "@fortawesome/pro-regular-svg-icons/faKeyboard";
import { faListOl } from "@fortawesome/pro-regular-svg-icons/faListOl";
import { faTag } from "@fortawesome/pro-regular-svg-icons/faTag";
import { DpModalType, DpSideBarType } from "src/app/types";
import { BranchCollapseState } from "src/features/DpEditor/dpEditorSlice";

export const getSideBarForDpElement = (
  dpElement: DpElementCombined,
  branch?: number
) => {
  switch (dpElement.type) {
    case DpElementType.ivr:
      return DpSideBarType.ivr;
    case DpElementType.timeSwitch:
      if (branch === undefined) {
        return DpSideBarType.timeSwitch;
      }
      return null;
    case DpElementType.dpSwitch:
      if (branch === undefined) {
        return DpSideBarType.callFlow;
      }
      return null;
    case DpElementType.dtmfInput:
      return DpSideBarType.dtmf;
    case DpElementType.prefix:
      return DpSideBarType.label;
    case DpElementType.numberRecognition:
      if (branch === undefined) {
        return DpSideBarType.numberRecognition;
      }
      return null;
    default:
      return null;
  }
};

export const getModalForDpElement = (
  dpElement: DpElementCombined,
  branch?: number
) => {
  let modalType: DpModalType | null = null;
  switch (dpElement.type) {
    case DpElementType.timeSwitch:
      if (branch === undefined) {
        modalType = DpModalType.timeSwitchBranchesForm;
      } else {
        modalType = DpModalType.timeRoutingForm;
      }
      break;
    case DpElementType.numberRecognition:
      if (branch === undefined) {
        modalType = DpModalType.numberRecognitionBranchesForm;
      } else if (
        (dpElement as DpElementNumberRecognition).branches[branch] !== undefined
      ) {
        modalType = DpModalType.numberRecognitionForm;
      }
      break;
    case DpElementType.ivr:
      if (branch === undefined) {
        modalType = DpModalType.ivrMenuForm;
      }
      break;
    case DpElementType.dpSwitch:
      if (branch === undefined) {
        modalType = DpModalType.dpSwitchForm;
      } else {
        modalType = DpModalType.dpSwitchNameForm;
      }
      break;
    case DpElementType.prefix:
      modalType = DpModalType.labelForm;
      break;
    case DpElementType.dtmfInput:
      modalType = DpModalType.dtmfForm;
      break;
  }
  return modalType;
};

export const isDpElementClickable = (
  dpElement: DpElementCombined,
  branch?: number
): boolean => getModalForDpElement(dpElement, branch) !== null;

export const getDpElementTitle = (
  dpElement: DpElementCombined,
  dpElementExtra?: ElementExtraProps
): string => {
  switch (dpElement.type) {
    case DpElementType.busy:
      return i18n.t("dp-editor.dp-element.busy");
    case DpElementType.dtmfInput:
      return (
        (dpElement as DpElementDtmf).name ||
        i18n.t("dp-editor.dp-element.dtmf-input")
      );
    case DpElementType.hangup:
      return i18n.t("dp-editor.dp-element.hangup");
    case DpElementType.prefix:
      return (
        (dpElement as DpElementPrefix).prefix ||
        i18n.t("dp-editor.dp-element.prefix")
      );
    case DpElementType.ivr:
      const branch = dpElementExtra?.branch;
      if (branch !== undefined) {
        return (dpElement as DpElementIvr).branches[branch].key;
      }
      return (
        (dpElement as DpElementIvr).name ||
        i18n.t("dp-editor.dp-element.ivr-menu")
      );
    case DpElementType.numberRecognition: {
      const branch = dpElementExtra?.branch;
      const numberRecognitionDpElement =
        dpElement as DpElementNumberRecognition;
      if (branch !== undefined) {
        if (!numberRecognitionDpElement.branches[branch]) {
          return i18n.t("dp-editor.dp-element.default-branch");
        }
        return (
          (dpElement as DpElementNumberRecognition).branches[branch]?.name ||
          i18n.t("dp-editor.dp-element.condition-default-name", {
            index: branch + 1,
          })
        );
      }
      return i18n.t("dp-editor.dp-element.number-recognition");
    }
    case DpElementType.timeSwitch: {
      const branch = dpElementExtra?.branch;
      if (branch !== undefined) {
        return (
          (dpElement as DpElementTimeSwitch).branches[branch]?.name ||
          i18n.t("dp-editor.dp-element.condition-default-name", {
            index: branch + 1,
          })
        );
      }
      // NOTE: https://gitlab.iperitydev.com/compass/dialplan-editor/-/issues/5
      if ((dpElement as DpElementTimeSwitch).branches?.length === 1) {
        return (
          (dpElement as DpElementTimeSwitch).branches[0].name ||
          i18n.t("dp-editor.dp-element.time-switch")
        );
      }
      return i18n.t("dp-editor.dp-element.time-switch");
    }
    case DpElementType.dpSwitch: {
      const branch = dpElementExtra?.branch;
      if (branch !== undefined) {
        return `${branch + 1}: ${
          (dpElement as DpElementDpSwitch).settings[branch]?.name ||
          i18n.t("dp-editor.dp-element.unnamed-setting")
        }`;
      }
      return (
        (dpElement as DpElementDpSwitch).name ||
        i18n.t("dp-editor.dp-element.dp-switch")
      );
    }
    case DpElementType.conference:
      return (dpElement as DpElementResource).name;
    case DpElementType.forward:
      return (dpElement as DpElementResource).name;
    case DpElementType.prompt:
      return (dpElement as DpElementResource).name;
    case DpElementType.queue:
      return (dpElement as DpElementResource).name;
    case DpElementType.user:
      return (dpElement as DpElementIdentity).name;
    case DpElementType.voicemail:
      return (dpElement as DpElementResource).name;
  }
};

export const getDpElementIcon = (type: DpElementType): IconDefinition => {
  switch (type) {
    case DpElementType.busy:
      return faUserClock;
    case DpElementType.dtmfInput:
      return faKeyboard;
    case DpElementType.hangup:
      return faPhoneSlash;
    case DpElementType.prefix:
      return faTag;
    case DpElementType.ivr:
      return faListOl;
    case DpElementType.numberRecognition:
      return faPhoneRotary;
    case DpElementType.timeSwitch:
      return faClock;
    case DpElementType.dpSwitch:
      return faCodeBranch;
    case DpElementType.conference:
      return faUsers;
    case DpElementType.forward:
      return faShare;
    case DpElementType.prompt:
      return faWaveform;
    case DpElementType.queue:
      return faUserFriends;
    case DpElementType.user:
      return faUser;
    case DpElementType.voicemail:
      return faVoicemail;
  }
};

export const getDpBranchByPath = (
  dialPlan: DpElementCombined[],
  originalPath: number[][]
) => {
  let branch = dialPlan;
  const copiedPath: number[][] = copyObject(originalPath);
  while (copiedPath.length) {
    const pathItem = copiedPath.shift();
    if (pathItem) {
      switch (branch[pathItem[0]].type) {
        case DpElementType.timeSwitch:
          branch = (branch[pathItem[0]] as DpElementTimeSwitch).branches[
            pathItem[1]
          ].steps;
          break;
        case DpElementType.numberRecognition: {
          const numberRecognitionElement = branch[
            pathItem[0]
          ] as DpElementNumberRecognition;
          if (numberRecognitionElement.branches.length <= pathItem[1]) {
            branch = numberRecognitionElement.defaultSteps;
          } else {
            branch = (branch[pathItem[0]] as DpElementNumberRecognition)
              .branches[pathItem[1]].steps;
          }
          break;
        }
        case DpElementType.ivr:
          branch = (branch[pathItem[0]] as DpElementIvr).branches[pathItem[1]]
            .steps;
          break;
        case DpElementType.dpSwitch:
          branch = (branch[pathItem[0]] as DpElementDpSwitch).settings[
            pathItem[1]
          ].steps;
          break;
      }
    }
  }
  return branch;
};

export const getDpElementBranches = (
  dpElement: DpElementCombined
): DpElementBranch[] => {
  let branches: DpElementBranch[] = [];
  switch (dpElement.type) {
    case DpElementType.timeSwitch:
      branches = (dpElement as DpElementTimeSwitch).branches || [];
      break;
    case DpElementType.numberRecognition:
      branches = (dpElement as DpElementNumberRecognition).branches || [];
      break;
    case DpElementType.ivr:
      branches = (dpElement as DpElementIvr).branches || [];
      break;
    case DpElementType.dpSwitch:
      branches = (dpElement as DpElementDpSwitch).settings || [];
      break;
  }
  return branches;
};

export const getDpElementBranch = (
  dpElement: DpElementCombined,
  idx: number
) => {
  return getDpElementBranches(dpElement)[idx];
};

export const getDpElementBranchName = (
  dpElement: DpElementCombined,
  idx: number
): string => {
  switch (dpElement.type) {
    case DpElementType.timeSwitch:
      return (dpElement as DpElementTimeSwitch).branches[idx].name;
    case DpElementType.numberRecognition:
      return (dpElement as DpElementNumberRecognition).branches[idx].name;
    case DpElementType.ivr:
      return (dpElement as DpElementIvr).branches[idx].key;
    case DpElementType.dpSwitch:
      return (dpElement as DpElementDpSwitch).settings[idx].name;
  }
  return "";
};

export const removeDpElementBranch = (
  id: string,
  idx: number,
  dialPlan: DpElementCombined[]
) => {
  const element = getDpElementById(id, dialPlan);
  if (!element) {
    return;
  }
  getDpElementBranches(element).splice(idx, 1);
  // NOTE: for dp switch numbers should be increasing without gaps
  if (element.type === DpElementType.dpSwitch) {
    let currentSettingLeft: boolean = false;
    const dpSwitchElement = element as DpElementDpSwitch;
    dpSwitchElement.settings.forEach((setting, idx) => {
      const updatedNumber = idx === 9 ? 0 : idx + 1;
      if (setting.number === dpSwitchElement.currentSetting) {
        currentSettingLeft = true;
        dpSwitchElement.currentSetting = updatedNumber;
      }
      setting.number = updatedNumber;
    });
    if (!currentSettingLeft) {
      dpSwitchElement.currentSetting = 1;
    }
  }
};

export const getDpElementById = (
  id: string,
  dialPlan: DpElementCombined[]
): DpElementCombined | null => {
  let idx = 0;
  while (idx < dialPlan.length) {
    if (dialPlan[idx]?._temp?.id === id) {
      return dialPlan[idx];
    }
    const dpElement = dialPlan[idx];
    let branches: DpElementCombined[][] | null = getDpElementBranches(
      dpElement
    ).map(({ steps }) => steps);
    if (branches) {
      for (const branch of branches) {
        const foundDpElement = getDpElementById(id, branch);
        if (foundDpElement) {
          return foundDpElement;
        }
      }
      if (dpElement.type === DpElementType.numberRecognition) {
        const foundDpElement = getDpElementById(
          id,
          (dpElement as DpElementNumberRecognition).defaultSteps
        );
        if (foundDpElement) {
          return foundDpElement;
        }
      }
    }
    idx += 1;
  }
  return null;
};

export const removeDpElementById = (
  id: string,
  dialPlan: DpElementCombined[]
): boolean => {
  let idx = 0;
  while (idx < dialPlan.length) {
    if (dialPlan[idx]?._temp?.id === id) {
      dialPlan.splice(idx, 1);
      return true;
    }
    const dpElement = dialPlan[idx];
    let branches: DpElementCombined[][] | null = getDpElementBranches(
      dpElement
    ).map(({ steps }) => steps);
    if (branches) {
      for (const branch of branches) {
        if (removeDpElementById(id, branch)) {
          return true;
        }
      }

      if (
        dpElement.type === DpElementType.numberRecognition &&
        removeDpElementById(
          id,
          (dpElement as DpElementNumberRecognition).defaultSteps
        )
      ) {
        return true;
      }
    }
    idx += 1;
  }
  return false;
};

export const elementDialPlanPositionToId = (
  position: ElementDialPlanPosition
) => btoa(JSON.stringify(position));

export const getDpElementsByType = (
  dialPlan: DpElementCombined[],
  type: DpElementType
): DpElementCombined[] => {
  let out: DpElementCombined[] = [];
  dialPlan.forEach((dpElement) => {
    if (dpElement.type === type) {
      out.push(dpElement);
    }
    getDpElementBranches(dpElement).forEach(({ steps }) => {
      out = [...out, ...getDpElementsByType(steps, type)];
    });
    if (dpElement.type === DpElementType.numberRecognition) {
      out = [
        ...out,
        ...getDpElementsByType(
          (dpElement as DpElementNumberRecognition).defaultSteps,
          type
        ),
      ];
    }
  });
  return out;
};

export const assignDialPlanIds = (dialPlan: DpElementCombined[]) => {
  dialPlan.forEach((element) => {
    element._temp = { id: nanoid() };
    getDpElementBranches(element).forEach((branch) => {
      branch._temp = { id: nanoid() };
      assignDialPlanIds(branch.steps);
      if (element.type === DpElementType.numberRecognition) {
        assignDialPlanIds((element as DpElementNumberRecognition).defaultSteps);
      }
    });
  });
  return dialPlan;
};

export const cleanupDialPlanIds = (dialPlan: DpElementCombined[]) => {
  dialPlan.forEach((element) => {
    delete element._temp;
    getDpElementBranches(element).forEach((branch) => {
      delete branch._temp;
      cleanupDialPlanIds(branch.steps);
      if (element.type === DpElementType.numberRecognition) {
        cleanupDialPlanIds(
          (element as DpElementNumberRecognition).defaultSteps
        );
      }
    });
  });
  return dialPlan;
};

export const getBranchElementId = (
  dpElement: DpElementCombined,
  idx: number
) => {
  return `${dpElement._temp?.id}-${idx}`;
};

export const isDialPlanCollapseStateChangedManually = (
  dialPlan: DpElementCombined[],
  collapseState: BranchCollapseState
): boolean | undefined => {
  let dpHasCollapsableElements = false;
  const changedState = !collapseState.allOpened;
  for (let element of dialPlan) {
    if (
      ![
        DpElementType.timeSwitch,
        DpElementType.numberRecognition,
        DpElementType.ivr,
        DpElementType.dpSwitch,
      ].includes(element.type)
    ) {
      continue;
    }
    if (!dpHasCollapsableElements) {
      dpHasCollapsableElements = true;
    }
    if (
      !element._temp?.id ||
      collapseState.elements[element._temp.id] !== changedState
    ) {
      return false;
    }
    if (changedState === false) {
      continue;
    }
    const branches = getDpElementBranches(element);
    for (let idx in branches) {
      const elementCollapseState =
        collapseState.elements[getBranchElementId(element, parseInt(idx))];
      if (
        elementCollapseState === undefined ||
        elementCollapseState !== changedState
      ) {
        return false;
      }
      if (
        isDialPlanCollapseStateChangedManually(
          branches[idx].steps,
          collapseState
        ) === false
      ) {
        return false;
      }
    }
    if (element.type === DpElementType.numberRecognition) {
      const elementCollapseState =
        collapseState.elements[getBranchElementId(element, branches.length)];
      if (
        elementCollapseState === undefined ||
        elementCollapseState !== changedState
      ) {
        return false;
      }
      if (
        isDialPlanCollapseStateChangedManually(
          (element as DpElementNumberRecognition).defaultSteps,
          collapseState
        ) === false
      ) {
        return false;
      }
    }
  }
  if (!dpHasCollapsableElements) {
    return undefined;
  }
  return true;
};

export const HIDDEN_CALLER_ID_INPUT_VALUE = "hidden_caller_id";

export const humanizeNumberRecognitionPrefix = (prefix: string): string => {
  switch (prefix) {
    case "316":
      return i18n.t("controls.all-prefix-number", {
        prefix: "06",
      });
    case "324":
      return i18n.t("controls.all-prefix-number", {
        prefix: "04",
      });
    case "31":
    case "32":
      return i18n.t("controls.all-prefix-number", {
        prefix: `+${prefix}`,
      });
    case HIDDEN_CALLER_ID_INPUT_VALUE:
      return i18n.t("controls.hidden-caller-id");
  }
  return prefix;
};

export const numberRecognitionConditionsDisplay = (
  branch: DpElementNumberRecognitionBranch
) => {
  let strings = [];
  if (branch.anonymous) {
    strings.push(i18n.t("controls.hidden-caller-id"));
  }
  strings = [
    ...strings,
    ...branch.numberPrefixes.map(humanizeNumberRecognitionPrefix),
  ];
  return strings.length ? strings.join(", ") : "-";
};

const getBranchIds = (branchSteps: DpElementCombined[]): string[] => {
  let out: string[] = [];
  branchSteps.forEach((step) => {
    if (step._temp?.id) {
      out.push(step._temp?.id);
    }
    getDpElementBranches(step).forEach((branch) => {
      out = [...out, ...getBranchIds(branch.steps)];
    });
    if (step.type === DpElementType.numberRecognition) {
      out = [
        ...out,
        ...getBranchIds((step as DpElementNumberRecognition).defaultSteps),
      ];
    }
  });
  return out;
};

export const getDpElementChildrenIds = (
  id: string,
  dialPlan: DpElementCombined[]
): string[] => {
  const element = getDpElementById(id, dialPlan);
  if (!element) {
    return [];
  }
  let out: string[] = [];
  getDpElementBranches(element).forEach((branch) => {
    out = [...out, ...getBranchIds(branch.steps)];
  });
  if (element.type === DpElementType.numberRecognition) {
    out = [
      ...out,
      ...getBranchIds((element as DpElementNumberRecognition).defaultSteps),
    ];
  }
  return out;
};

export const getDpElementBranchChildrenIds = (
  id: string,
  branchIdx: number,
  dialPlan: DpElementCombined[]
): string[] => {
  const element = getDpElementById(id, dialPlan);
  if (!element) {
    return [];
  }
  return getBranchIds(getDpElementBranches(element)[branchIdx].steps);
};
