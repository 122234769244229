import { RootState } from "src/app/rootReducer";
import { CompassPrompt, CompassResourceType } from "src/app/types/compass";
import { genericDataSliceFactory } from "src/utils/genericDataSliceFactory";
import { getOrderedResources } from "src/utils/resources";

const { slice, loadItemsThunk } = genericDataSliceFactory<CompassPrompt>(
  "prompts",
  (companyId) => getOrderedResources(companyId, CompassResourceType.prompt)
);

export const loadPrompts = loadItemsThunk;

export const selectPromptsState = ({ prompts }: RootState) => prompts;

export default slice.reducer;
