import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import styles from "./Login.module.css";
import { login, selectAuthStatus } from "./authSlice";
import { useAppDispatch } from "src/app/store";
import Button from "src/components/Button";
import FormGroup from "src/components/FormGroup";

type LoginFormValues = {
  username: string;
  password: string;
};

const Login: React.FC = () => {
  const { register, handleSubmit, formState } = useForm<LoginFormValues>();
  const dispatch = useAppDispatch();
  const submitHandler = handleSubmit(async (formData) => {
    await dispatch(login(formData));
  });
  const { loginErrorMessage, loginInProgress } = useSelector(selectAuthStatus);
  return (
    <div className={styles.formWrap}>
      {loginErrorMessage ? (
        <div className="studio-form-error">{loginErrorMessage}</div>
      ) : null}
      <FormGroup
        header={"Username"}
        error={formState.errors.username}
        htmlFor="username"
      >
        <input
          type="text"
          className="studio-form__control"
          {...register("username", { required: "Username is required" })}
        />
      </FormGroup>
      <FormGroup
        header={"Password"}
        error={formState.errors.password}
        htmlFor="password"
      >
        <input
          type="password"
          className="studio-form__control"
          {...register("password", { required: "Password is required" })}
        />
      </FormGroup>
      <Button disabled={loginInProgress} onClick={submitHandler}>
        Login
      </Button>
    </div>
  );
};

export default Login;
