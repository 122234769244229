import classNames from "classnames";
import React from "react";

const PropertyDisplay: React.FC<{
  title: React.ReactElement | string;
  value: string | number;
  className?: string;
}> = ({ className, title, value }) => {
  return (
    <div className={classNames(className, "font-semibold", "text-s")}>
      <div className="text-gs-700 mb-1 text-base leading-normal">{title}</div>
      <div className="text-base leading-normal">{value}</div>
    </div>
  );
};

export default PropertyDisplay;
