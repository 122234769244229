import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";
import { DpElementDtmf } from "src/app/types/dialplans";
import BaseModal from "src/components/BaseModal";
import FormGroup from "src/components/FormGroup";
import PropertyDisplay from "src/components/PropertyDisplay";
import Radio from "src/components/Radio";
import Wizard, { WizardStep } from "src/components/Wizard";
import { copyObject } from "src/utils";
import {
  nameFieldValidator,
  requiredValidator,
  dtmfDigitsValidator,
} from "src/utils/form";
import { useLoadDialplanElements, usePrevious } from "src/utils/hooks";
import PromptsSelect from "src/features/Prompts/PromptsSelect";

const DtmfNameStep: React.FC<{
  onUpdate?: (data: Partial<DpElementDtmf>, isValid: boolean) => void;
  dpElement: DpElementDtmf;
}> = ({ onUpdate, dpElement }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    getValues,
  } = useForm<{
    name: string;
  }>({
    mode: "all",
    defaultValues: {
      name: dpElement.name,
    },
  });
  const { nextStep } = useWizard();
  const handleNextClick = handleSubmit(() => {
    nextStep();
  });
  const previousIsValid = usePrevious(isValid);
  useEffect(() => {
    if (!onUpdate) {
      return;
    }
    if (previousIsValid !== isValid) {
      onUpdate(getValues(), isValid);
    }
    const subscription = watch((data) => onUpdate(data, isValid));
    return () => subscription.unsubscribe();
  }, [watch, onUpdate, isValid, previousIsValid, getValues]);
  return (
    <WizardStep
      buttons={[
        {
          text: t("controls.next-step"),
          props: {
            onClick: handleNextClick,
            disabled: !isValid,
          },
        },
      ]}
    >
      <FormGroup
        header={t("form-labels.name")}
        description={t("form-labels.dtmf-name-info")}
        error={errors.name}
        htmlFor="id_name"
      >
        <input
          id="id_name"
          type="text"
          className="studio-form__control"
          autoFocus={true}
          placeholder={t("form-labels.name-placeholder")}
          {...register("name", {
            ...requiredValidator(
              t("form-validation-errors.name-field-required")
            ),
            ...nameFieldValidator(true),
          })}
        />
      </FormGroup>
    </WizardStep>
  );
};

const DtmfTypeStep: React.FC<{
  onUpdate?: (data: Partial<DpElementDtmf>, isValid: boolean) => void;
  dpElement: DpElementDtmf;
}> = ({ onUpdate, dpElement }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { isValid, errors },
    register,
    watch,
    unregister,
    getValues,
  } = useForm<{
    digits: string;
    variableLength: string;
  }>({
    mode: "all",
    defaultValues: {
      variableLength: dpElement.variableLength
        ? String(dpElement.variableLength)
        : "false",
      digits: dpElement.digits ? dpElement.digits.toString() : "",
    },
  });
  const previousIsValid = usePrevious(isValid);
  useEffect(() => {
    if (!onUpdate) {
      return;
    }
    if (previousIsValid !== isValid) {
      onUpdate(
        {
          digits: getValues().digits ? parseInt(getValues().digits) : undefined,
          variableLength: getValues().variableLength === "true",
        },
        isValid
      );
    }
    const subscription = watch((data) =>
      onUpdate(
        {
          digits: data.digits ? parseInt(data.digits) : undefined,
          variableLength: data.variableLength === "true",
        },
        isValid
      )
    );
    return () => subscription.unsubscribe();
  }, [watch, onUpdate, isValid, previousIsValid, getValues]);

  const { nextStep } = useWizard();
  const watchVariableLength = watch("variableLength");
  const previousVariableLength = usePrevious(watchVariableLength);
  useEffect(() => {
    if (
      watchVariableLength !== previousVariableLength &&
      watchVariableLength === "true"
    ) {
      unregister("digits");
    }
  }, [watchVariableLength, previousVariableLength, unregister]);
  const handleNextClick = handleSubmit(() => {
    nextStep();
  });
  return (
    <WizardStep
      buttons={[
        {
          text: t("controls.next-step"),
          props: {
            onClick: handleNextClick,
            disabled: !isValid,
          },
        },
      ]}
    >
      <FormGroup
        header={t("form-labels.type-of-input")}
        error={errors.variableLength}
      >
        <Radio
          label={t("form-labels.dtmf-input-length-option-fixed")}
          value="false"
          {...register("variableLength")}
        />
        <Radio
          label={t("form-labels.dtmf-input-length-option-variable")}
          value="true"
          {...register("variableLength")}
        />
      </FormGroup>
      {watchVariableLength === "false" ? (
        <FormGroup
          header={t("form-labels.dtmf-digits-number")}
          error={errors.digits}
          htmlFor="id_digits"
        >
          <input
            type="text"
            className="studio-form__control"
            placeholder={t("form-labels.number-placeholder")}
            id="id_digits"
            {...register("digits", {
              ...requiredValidator(),
              ...dtmfDigitsValidator(),
            })}
          />
        </FormGroup>
      ) : (
        <div className="text-xs text-gs-600 font-semibold">
          {t("dp-editor.dtmf-create-wizard.digits-warning")}
        </div>
      )}
    </WizardStep>
  );
};

const DtmfPromptStep: React.FC<{
  onUpdate?: (data: Partial<DpElementDtmf>, isValid: boolean) => void;
  dpElement: DpElementDtmf;
}> = ({ onUpdate, dpElement }) => {
  const { t } = useTranslation();
  const form = useForm<{
    promptId: string;
  }>({
    mode: "all",
    defaultValues: {
      promptId: dpElement.promptId ? dpElement.promptId.toString() : undefined,
    },
  });
  const {
    formState: { errors, isValid },
    handleSubmit,
    watch,
    getValues,
  } = form;
  const { nextStep } = useWizard();
  const handleNextClick = handleSubmit((data) => {
    nextStep();
  });
  const convertData = useCallback((data: { promptId: string }) => {
    return {
      promptId: data.promptId ? parseInt(data.promptId) : undefined,
    };
  }, []);
  const previousIsValid = usePrevious(isValid);
  useEffect(() => {
    if (!onUpdate) {
      return;
    }
    if (previousIsValid !== isValid) {
      onUpdate(convertData(getValues()), isValid);
    }
    const subscription = watch((data) =>
      onUpdate(convertData(data as { promptId: string }), isValid)
    );
    return () => subscription.unsubscribe();
  }, [watch, onUpdate, isValid, getValues, previousIsValid, convertData]);
  return (
    <WizardStep
      buttons={[
        {
          text: t("controls.next-step"),
          props: {
            onClick: handleNextClick,
            disabled: !isValid,
          },
        },
      ]}
    >
      <FormGroup
        header={t("form-labels.voice-prompt-select")}
        description={t("form-labels.voice-prompt-dtmf-select-info")}
        error={errors.promptId}
      >
        <PromptsSelect name="promptId" form={form} />
      </FormGroup>
    </WizardStep>
  );
};

const DtmfFinishStep: React.FC<{
  onNext?: () => void;
  dpElement: DpElementDtmf;
}> = ({ onNext, dpElement }) => {
  const { t } = useTranslation();
  const { prompts } = useLoadDialplanElements();
  const promptName = useMemo(() => {
    return (
      prompts.find((item) => item.resourceId === dpElement.promptId)?.name || ""
    );
  }, [prompts, dpElement]);
  return (
    <WizardStep
      buttons={[
        {
          text: t("controls.create-element"),
          props: {
            onClick: onNext,
            autoFocus: true,
          },
        },
      ]}
    >
      <PropertyDisplay
        className="mb-5"
        title={t("form-labels.name")}
        value={dpElement.name}
      />
      <PropertyDisplay
        className="mb-5"
        title={t("form-labels.voice-prompt-select")}
        value={promptName || t("controls.not-selected").toString()}
      />
      {dpElement.digits ? (
        <PropertyDisplay
          className="mb-5"
          title={t("form-labels.dtmf-digits-number")}
          value={dpElement.digits}
        />
      ) : null}
    </WizardStep>
  );
};

const DtmfCreateWizard: React.FC<{
  isOpen: boolean;
  dpElement: DpElementDtmf;
  onRequestClose: (data?: { dpElement: DpElementDtmf }) => void;
}> = ({ onRequestClose, isOpen, dpElement }) => {
  const { t } = useTranslation();
  const [createdDpElement, setCreatedDpElement] = useState({ ...dpElement });
  const [stepsState, setStepsState] = useState<{
    steps: {
      [key: string]: { visited: boolean };
    };
    activeStep: number;
  }>({ steps: { 0: { visited: true } }, activeStep: 0 });
  const [stepsValidState, setStepsValidState] = useState<{
    [key: string]: boolean;
  }>({});
  const { steps, activeStep } = stepsState;
  const activeStepChangeHandler = useCallback(
    (newActiveStep: number) => {
      setStepsState({
        activeStep: newActiveStep,
        steps: {
          ...steps,
          [newActiveStep]: {
            ...(steps[newActiveStep] || {}),
            visited: true,
          },
        },
      });
    },
    [steps, setStepsState]
  );
  const onStepUpdateHandler = useCallback(
    (stepNumber: number, data: Partial<DpElementDtmf>, isValid: boolean) => {
      setStepsValidState({
        [stepNumber]: isValid,
      });
      setCreatedDpElement({
        ...createdDpElement,
        ...data,
      });
    },
    [createdDpElement]
  );
  const onFinishStepNextHandler = useCallback(() => {
    const dtmfElement = copyObject(createdDpElement);
    // Default values
    // https://gitlab.iperitydev.com/compass/design-documents/-/tree/master/compass/dialplan/dtmf-input
    dtmfElement.timeout = 10;
    dtmfElement.repeat = 2;
    onRequestClose({
      dpElement: dtmfElement,
    });
  }, [createdDpElement, onRequestClose]);
  const breadcrumbItems: { label: string; active?: boolean }[] = useMemo(() => {
    return [
      {
        label: t("controls.name"),
        active: stepsValidState[activeStep] && steps[0]?.visited,
      },
      {
        label: t("controls.type"),
        active: stepsValidState[activeStep] && steps[1]?.visited,
      },
      {
        label: t("controls.prompt"),
        active: stepsValidState[activeStep] && steps[2]?.visited,
      },
      {
        label: t("controls.finish"),
        active: stepsValidState[activeStep] && steps[3]?.visited,
      },
    ];
  }, [steps, activeStep, t, stepsValidState]);
  return (
    <BaseModal
      title={t("dp-editor.dtmf-create-wizard.title")}
      onRequestClose={onRequestClose.bind(null, undefined)}
      isOpen={isOpen}
      isWizard={true}
      hideFooterButtons={true}
    >
      <Wizard
        breadcrumbItems={breadcrumbItems}
        onActiveStepChange={activeStepChangeHandler}
      >
        <DtmfNameStep
          onUpdate={onStepUpdateHandler.bind(null, 0)}
          dpElement={createdDpElement}
        />
        <DtmfTypeStep
          onUpdate={onStepUpdateHandler.bind(null, 1)}
          dpElement={createdDpElement}
        />
        <DtmfPromptStep
          onUpdate={onStepUpdateHandler.bind(null, 2)}
          dpElement={createdDpElement}
        />
        <DtmfFinishStep
          dpElement={createdDpElement}
          onNext={onFinishStepNextHandler}
        />
      </Wizard>
    </BaseModal>
  );
};

export default DtmfCreateWizard;
