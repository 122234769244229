import React, { useMemo } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { getDpElementBranch, getDpElementTitle } from "src/utils/dialPlan";
import {
  DpElementNumberRecognition,
  DpElementNumberRecognitionBranch,
} from "src/app/types/dialplans";
import BaseModal, { BaseModalProps } from "src/components/BaseModal";
import { nameFieldValidator, numberPrefixesValidator } from "src/utils/form";
import { useTranslation } from "react-i18next";
import { copyObject } from "src/utils";
import NumberRecognitionInput from "../NumberRecognitionInput";
import FormGroup from "src/components/FormGroup";

const NumberRecognitionFormModal: React.FC<
  BaseModalProps & {
    dpElement: DpElementNumberRecognition;
    onRequestClose: (data?: { dpElement: DpElementNumberRecognition }) => void;
    branch: number;
  }
> = ({ isOpen, onRequestClose, dpElement, branch }) => {
  const { t } = useTranslation();
  const title = useMemo(
    () => getDpElementTitle(dpElement, { branch }),
    [dpElement, branch]
  );
  const branchElement = useMemo(
    () =>
      getDpElementBranch(dpElement, branch) as DpElementNumberRecognitionBranch,
    [dpElement, branch]
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<{
    name: string;
    numberPrefixes: string[];
    anonymous: boolean;
  }>({
    defaultValues: {
      name: branchElement.name,
      numberPrefixes: branchElement.numberPrefixes,
      anonymous: branchElement.anonymous,
    },
  });
  const submitHandler = handleSubmit((formData) => {
    const numberRecognitionElement: DpElementNumberRecognition =
      copyObject(dpElement);
    const branchElement = getDpElementBranch(
      numberRecognitionElement,
      branch
    ) as DpElementNumberRecognitionBranch;
    branchElement.name = formData.name;
    branchElement.numberPrefixes = formData.numberPrefixes.filter(
      (item) => !!item
    );
    branchElement.anonymous = formData.anonymous;
    onRequestClose({
      dpElement: numberRecognitionElement,
    });
  });
  return (
    <BaseModal
      title={t("dp-editor.number-recognition-form-modal.title", {
        title,
      })}
      onRequestClose={onRequestClose.bind(null, undefined)}
      isOpen={isOpen}
      buttons={[
        { text: t("controls.save"), props: { onClick: submitHandler } },
      ]}
    >
      <FormGroup
        header={t("form-labels.name")}
        error={errors.name}
        htmlFor="id_name"
      >
        <input
          type="text"
          id="id_name"
          className="studio-form__control"
          {...register("name", {
            ...nameFieldValidator(),
          })}
        />
      </FormGroup>
      <FormGroup
        header={t("form-labels.recognition-number-list")}
        error={errors.numberPrefixes as FieldError | undefined}
        description={t(
          "dp-editor.number-recognition-form-modal.number-list-tooltip"
        )}
      >
        <Controller
          control={control}
          name="numberPrefixes"
          rules={{
            ...numberPrefixesValidator(),
          }}
          render={() => (
            <NumberRecognitionInput
              branch={branchElement}
              onChange={(data) => {
                setValue("anonymous", data.anonymous);
                setValue("numberPrefixes", data.numberPrefixes, {
                  shouldValidate: true,
                });
              }}
            />
          )}
        ></Controller>
      </FormGroup>
    </BaseModal>
  );
};

export default NumberRecognitionFormModal;
