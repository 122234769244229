export type CompassCompany = {
  contact: string;
  domain: string;
  entityId: number;
  musicOnHold: number;
  name: string;
  self: string;
  shortname: string;
  sipDomain: string;
  theLanguage: string;
  theType: string;
  xmppDomain: string;
};

export enum CompassResourceType {
  conference = "conference",
  queue = "queue",
  forward = "forward",
  prompt = "prompt",
  voicemail = "voicemail",
  extension = "extension",
  externalNumber = "externalNumber",
  dpswitch = "dpswitch",
  user = "user",
  company = "company",
  phone = "phone",
}

export type CompassResource<Type = unknown> = {
  self: string;
  theType: Type;
  resourceId: number;
  owner: number;
  name: string;
};

export type CompassForward = CompassResource<CompassResourceType.forward> & {
  destination: string;
  keepCli: boolean;
  ringtime: number;
};

export type CompassConference = CompassResource<CompassResourceType.conference>;

export type CompassVoicemail = CompassResource<CompassResourceType.voicemail>;

export type CompassPrompt = CompassResource<CompassResourceType.prompt>;

export type CompassPhone = CompassResource<CompassResourceType.phone> & {
  model: string;
  macAddress: number[];
  loggedInUser: string;
  forceEncryption: boolean;
}

export type CompassQueue = CompassResource<CompassResourceType.queue> & {
  ignoreUserStatus: boolean;
  joinEmpty: string;
  leaveWhenEmpty: string;
  maxLen: number;
  maxWaittime: number;
  reportHoldTime: boolean;
  retry: number;
  ringInUse: boolean;
  shortcode: number;
  strategy: string;
  timeout: number;
  timeoutRestart: boolean;
  // TODO: type
  wrapupTime: any;
};

export type CompassExtension =
  CompassResource<CompassResourceType.extension> & {
    number: string;
  };

export type CompassExternalNumber =
  CompassResource<CompassResourceType.externalNumber> & {
    useAsCli: boolean;
    useForInbound: boolean;
    recording: boolean;
    cli: string;
    number: string;
  };

export type CompassIdentity = {
  self: string;
  identityId: number;
  order: number;
  description: string;
  idName: string;
  extension: string;
  cfim: string;
  cfor: string;
  cfbs: string;
  cfna: string;
  ringtime: number;
  cliTransFwd: boolean;
  voicemail: string;
  cli: string;
  outboundAnonymousCli: boolean;
  recording: {
    type: "email";
  };
  voicemailDetection: boolean;
};

export type CompassUser = {
  contact: string;
  entityId: number;
  name: string;
  pin: string;
  self: string;
  theLanguage: string;
  theType: CompassResourceType.user;
  username: string;
};

export type CompassDpSwitch = {
  settings: {
    number: number;
    name: string;
  }[];
  currentSetting: number;
  shortcode: number;
  self: string;
  resourceId: number;
  owner: number;
  name: string;
  theType: CompassResourceType.dpswitch;
};

export enum CompassUserPermission {
  permWrite = "permWrite",
  permRead = "permRead",
  permNone = "permNone",
}

export type CompassPhoneModel = {
  self: string;
  phoneModelId: number;
  manufacturer: string;
  name: string;
  shortname: string;
  hasmac: boolean;
  supportsEncryption: boolean
}

export type CompassSipAccount = {
  username: string;
  domain: string;
  secret: string;
}
