import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { nameFieldValidator } from "src/utils/form";
import { usePrevious } from "src/utils/hooks";
import { DpElementNumberRecognition } from "src/app/types/dialplans";
import BaseModal, { BaseModalProps } from "src/components/BaseModal";
import styles from "./BranchesFormModal.module.css";
import { useTranslation } from "react-i18next";
import { nanoid } from "nanoid";
import Button, { ButtonColor, ButtonSize } from "src/components/Button";
import FormGroup from "src/components/FormGroup";

type BranchParams<T = any> = {
  id: string;
  name: string;
  savedIndex?: number;
  config?: T;
  isValid?: boolean;
};

type BranchConfigComponentProps = BranchParams & {
  onChange: (branch: BranchParams) => void;
  onRemove?: () => void;
  index: number;
  formSubmitted?: boolean;
};

const BranchConfigForm: React.FC<BranchConfigComponentProps> = ({
  id,
  name,
  onChange,
  savedIndex,
  formSubmitted,
  index,
  onRemove,
}) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, isValid },
    getValues,
    watch,
    trigger,
  } = useForm<{
    name: string;
    savedIndex?: number;
    id: string;
  }>({
    mode: "all",
    defaultValues: {
      name,
      savedIndex,
      id,
    },
  });
  const wasFormSubmitted = usePrevious(formSubmitted);
  useEffect(() => {
    if (formSubmitted && !wasFormSubmitted) {
      trigger();
    }
  }, [formSubmitted, wasFormSubmitted, trigger]);
  const wasValid = usePrevious(isValid);
  useEffect(() => {
    if (wasValid !== isValid) {
      onChange({ ...getValues(), isValid });
    }
    const unsubscribe = watch((formData) => {
      onChange({ ...formData, isValid } as BranchParams);
    });
    return () => {
      unsubscribe.unsubscribe();
    };
  }, [watch, isValid, getValues, onChange, wasValid]);
  return (
    <div className={styles.branch}>
      <div className="studio-form">
        <FormGroup
          header={
            <div className={styles.branchInputLabel}>
              {t("dp-editor.branches-form-modal.setting-label", {
                index,
              })}
              {onRemove ? (
                <div className={styles.branchRemoveBtn} onClick={onRemove}>
                  {t("controls.delete")}
                </div>
              ) : null}
            </div>
          }
          htmlFor={`id_name_${index}`}
          error={errors?.name}
        >
          <input
            type="text"
            className="studio-form__control"
            placeholder={t(
              "dp-editor.branches-form-modal.name-input-placeholder"
            )}
            id={`id_name_${index}`}
            {...register("name", {
              ...nameFieldValidator(),
            })}
          />
        </FormGroup>
      </div>
    </div>
  );
};

const BranchesFormModal: React.FC<
  BaseModalProps & {
    title?: string;
    branches: BranchParams[];
    onBranchesChange: (branches: BranchParams[]) => void;
    onRequestClose: (data?: { submitted: boolean }) => void;
    CustomBranchComponent?: React.FC<BranchConfigComponentProps>;
  }
> = ({
  title,
  onRequestClose,
  isOpen,
  branches,
  onBranchesChange,
  CustomBranchComponent,
}) => {
  const { t } = useTranslation();
  const branchUpdateHandler = useCallback(
    (idx: number, branch: BranchParams) => {
      const updatedBranches = [...branches];
      updatedBranches[idx] = branch;
      onBranchesChange(updatedBranches);
    },
    [branches, onBranchesChange]
  );
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const addBranchHandler = useCallback(() => {
    onBranchesChange([...branches, { name: "", id: nanoid() }]);
  }, [branches, onBranchesChange]);
  const removeBranchHandler = useCallback(
    (idx: number) => {
      const updatedBranches = [...branches];
      updatedBranches.splice(idx, 1);
      onBranchesChange(updatedBranches);
    },
    [branches, onBranchesChange]
  );
  const formIsValid = useMemo(
    () => !branches.find((item) => item.isValid === false),
    [branches]
  );
  const submitHandler = useCallback(() => {
    if (!formSubmitted) {
      setFormSubmitted(true);
    }
    if (!formIsValid) {
      return;
    }
    onRequestClose({ submitted: true });
  }, [onRequestClose, formIsValid, formSubmitted, setFormSubmitted]);
  const BranchComponent = useMemo(
    () => CustomBranchComponent || BranchConfigForm,
    [CustomBranchComponent]
  );
  return (
    <BaseModal
      title={title}
      isOpen={isOpen}
      onRequestClose={onRequestClose.bind(null, undefined)}
      buttons={[
        { text: t("controls.save"), props: { onClick: submitHandler } },
      ]}
    >
      <div className={styles.branches}>
        {branches.map((branch, idx) => (
          <BranchComponent
            key={branch.id}
            index={idx + 1}
            formSubmitted={formSubmitted}
            {...branch}
            onChange={branchUpdateHandler.bind(null, idx)}
            onRemove={
              branches.length > 1
                ? removeBranchHandler.bind(null, idx)
                : undefined
            }
          />
        ))}
        <div className={styles.branchAddBtn}>
          <Button
            onClick={addBranchHandler}
            color={ButtonColor.dark}
            size={ButtonSize.small}
          >
            <FontAwesomeIcon icon={faPlus} />
            {t("controls.add-condition")}
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

export const NumberRecognitionBranchesFormModal: React.FC<
  BaseModalProps & {
    dpElement: DpElementNumberRecognition;
    title?: string;
    onRequestClose: (data?: { dpElement: DpElementNumberRecognition }) => void;
  }
> = (props) => {
  const { t } = useTranslation();
  const [branches, setBranches] = useState<BranchParams[]>([
    { name: "", id: nanoid() },
  ]);
  const { dpElement, onRequestClose } = props;
  useEffect(() => {
    setBranches(
      dpElement.branches.map((item, idx) => ({
        name: item.name,
        savedIndex: idx,
        id: nanoid(),
      }))
    );
  }, [dpElement]);
  const requestCloseHandler = useCallback(
    (data?: { submitted: boolean }) => {
      if (!data?.submitted || !dpElement) {
        onRequestClose();
        return;
      }
      const numberRecognitionElement = {
        ...dpElement,
      } as DpElementNumberRecognition;
      numberRecognitionElement.defaultSteps =
        numberRecognitionElement.defaultSteps || [];
      numberRecognitionElement.branches = branches.map((item, idx) => {
        if (
          numberRecognitionElement.branches &&
          item.savedIndex !== undefined &&
          numberRecognitionElement.branches[item.savedIndex]
        ) {
          return {
            ...numberRecognitionElement.branches[item.savedIndex],
            name: item.name,
          };
        }
        return {
          steps: [],
          name: item.name,
          numberPrefixes: [],
          anonymous: false,
        };
      });
      onRequestClose({ dpElement: numberRecognitionElement });
    },
    [branches, dpElement, onRequestClose]
  );
  return (
    <BranchesFormModal
      {...{
        ...props,
        onRequestClose: requestCloseHandler,
        branches,
        onBranchesChange: setBranches,
        title: t("dp-editor.branches-form-modal.number-recognition-title"),
      }}
    />
  );
};
