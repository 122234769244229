import { RootState } from "src/app/rootReducer";
import { CompassForward, CompassResourceType } from "src/app/types/compass";
import { genericDataSliceFactory } from "src/utils/genericDataSliceFactory";
import { getOrderedResources } from "src/utils/resources";

const { slice, loadItemsThunk } = genericDataSliceFactory<CompassForward>(
  "forwards",
  (companyId) => getOrderedResources(companyId, CompassResourceType.forward)
);

export const loadForwards = loadItemsThunk;

export const selectForwardsState = ({ forwards }: RootState) => forwards;

export default slice.reducer;
