import classNames from "classnames";
import React, { useMemo } from "react";
import styles from "./Button.module.css";
import ReactTooltip from "react-tooltip";
import { TooltipPortal } from "src/index";

export enum ButtonFill {
  solid = "solid",
  clear = "clear",
}
export enum ButtonSize {
  small = "small",
  medium = "medium",
  large = "large",
}
export enum ButtonColor {
  prim = "prim",
  grey = "grey",
  dark = "dark",
}
export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  fill?: ButtonFill;
  size?: ButtonSize;
  color?: ButtonColor;
  iconOnly?: boolean;
  className?: string;
  tooltip?: string;
};

const defaultSize = ButtonSize.medium;
const defaultColor = ButtonColor.prim;
const defaultFill = ButtonFill.solid;

const Button: React.FC<ButtonProps> = (props) => {
  const { fill, size, color, iconOnly, children, className } = props;
  const nativeProps = useMemo(() => {
    const out = { ...props };
    delete out.children;
    delete out.fill;
    delete out.size;
    delete out.iconOnly;
    delete out.className;
    delete out.iconOnly;
    delete out.tooltip;
    return out;
  }, [props]);
  const $button = (
    <button
      {...nativeProps}
      className={classNames([styles.button, className])}
      data-size={size || defaultSize}
      data-color={color || defaultColor}
      data-fill={fill || defaultFill}
      data-icon-only={iconOnly}
    >
      {children}
    </button>
  );
  return props.tooltip ? (
    <>
      <span data-tip={props.tooltip}>{$button}</span>
      <TooltipPortal>
        <ReactTooltip
          border={true}
          borderColor="#FFFFFF0D"
          backgroundColor="#15181f"
          className="studio-tooltip"
          effect="solid"
          multiline={true}
          place="top"
        />
      </TooltipPortal>
    </>
  ) : (
    $button
  );
};

export default Button;
