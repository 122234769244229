import React from "react";
import { useForm } from "react-hook-form";
import { DpElementCombined, DpElementPrefix } from "src/app/types/dialplans";
import { prefixMaxLengthValidator, requiredValidator } from "src/utils/form";
import BaseModal, { BaseModalProps } from "src/components/BaseModal";
import { useTranslation } from "react-i18next";
import { copyObject } from "src/utils";
import { ModalFormAction } from "../types";
import FormGroup from "src/components/FormGroup";

const LabelFormModal: React.FC<
  BaseModalProps & {
    dpElement: DpElementPrefix;
    onRequestClose: (data?: { dpElement: DpElementCombined }) => void;
    action: ModalFormAction;
  }
> = ({ onRequestClose, isOpen, dpElement, action }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{
    prefix: string;
  }>({
    mode: "all",
    defaultValues: {
      prefix: dpElement.prefix,
    },
  });
  const submitHandler = handleSubmit((formData) => {
    if (!dpElement) {
      return onRequestClose();
    }
    const prefixElement: DpElementPrefix = copyObject(dpElement);
    prefixElement.prefix = formData.prefix;
    onRequestClose({
      dpElement: prefixElement,
    });
  });
  return (
    <BaseModal
      title={
        action === ModalFormAction.create
          ? t("dp-editor.label-form-modal.create-title")
          : t("dp-editor.label-form-modal.update-title")
      }
      onRequestClose={onRequestClose.bind(null, undefined)}
      isOpen={isOpen}
      buttons={[
        {
          text: t("controls.save"),
          props: { onClick: submitHandler, disabled: !isValid },
        },
      ]}
      modalStyles={{
        content: {
          maxWidth: "600px",
        },
      }}
    >
      <FormGroup
        header={t("form-labels.label-value")}
        description={t("form-labels.label-value-info")}
        error={errors.prefix}
        htmlFor="id_prefix"
      >
        <input
          type="text"
          autoFocus={true}
          className="studio-form__control"
          placeholder={t("form-labels.label-value-placeholder")}
          id="id_prefix"
          {...register("prefix", {
            ...requiredValidator(),
            ...prefixMaxLengthValidator(),
          })}
        />
      </FormGroup>
    </BaseModal>
  );
};

export default LabelFormModal;
