import i18next from "i18next";
import { DpElementTimeSwitchCondition } from "src/app/types/dialplans";

export const zPadTime = (str: number | string) => `0${str}`.slice(-2);

export const minutesToTimeString = (minutes: number) => {
  return `${zPadTime(Math.floor(minutes / 60))}:${zPadTime(minutes % 60)}`;
};

export const timeStringToMinutes = (str: string) => {
  return str
    .split(":")
    .map((n, idx) => (idx === 0 ? parseInt(n) * 60 : parseInt(n)))
    .reduce((a, b) => a + b);
};

export const timeSwitchTimeDisplay = (str?: string) => {
  if (!str || str === "00:00-23:59") {
    return i18next.t("dp-editor.time-routing-form-modal.whole-day");
  }
  return i18next.t("dp-editor.time-routing-form-modal.hours-range", {
    from: str.split("-")[0],
    // NOTE: add 1 minutes to "to" since in API we have reduced it by 1
    // reference: https://gitlab.iperitydev.com/compass/dialplan-editor/-/merge_requests/1#note_84027
    to: minutesToTimeString(timeStringToMinutes(str.split("-")[1]) + 1),
  });
};

export const timeSwitchWeekDaysDisplay = (str?: string) => {
  if (!str || str === "mon-sun") {
    return i18next.t("dp-editor.time-routing-form-modal.whole-week");
  }
  if (!str.includes("-")) {
    // NOTE: hint for the i18n extract functionality, do not remove
    /*
    t('datetime.weekdays.mon')
    t('datetime.weekdays.tue')
    t('datetime.weekdays.wed')
    t('datetime.weekdays.thu')
    t('datetime.weekdays.fri')
    t('datetime.weekdays.sat')
    t('datetime.weekdays.sun')
    */
    return i18next.t(`datetime.weekdays.${str}`);
  }
  return i18next.t("dp-editor.time-routing-form-modal.weekdays-range", {
    from: i18next.t(`datetime.weekdays.${str.split("-")[0]}`),
    to: i18next.t(`datetime.weekdays.${str.split("-")[1]}`),
  });
};
export const timeSwitchMonthDaysDisplay = (str?: string) => {
  if (!str || str === "1-31") {
    return i18next.t("dp-editor.time-routing-form-modal.whole-month");
  }
  if (!str.includes("-")) {
    return str;
  }
  return i18next.t("dp-editor.time-routing-form-modal.month-days-range", {
    from: str.split("-")[0],
    to: str.split("-")[1],
  });
};
export const timeSwitchMonthsDisplay = (str?: string) => {
  if (!str || str === "jan-dec") {
    return i18next.t("dp-editor.time-routing-form-modal.whole-year");
  }
  if (!str.includes("-")) {
    // NOTE: hint for the i18n extract functionality, do not remove
    /*
    t('datetime.months.jan')
    t('datetime.months.feb')
    t('datetime.months.mar')
    t('datetime.months.apr')
    t('datetime.months.may')
    t('datetime.months.jun')
    t('datetime.months.jul')
    t('datetime.months.aug')
    t('datetime.months.sep')
    t('datetime.months.oct')
    t('datetime.months.nov')
    t('datetime.months.dec')
    */
    return i18next.t(`datetime.months.${str}`);
  }
  return i18next.t("dp-editor.time-routing-form-modal.months-range", {
    from: i18next.t(`datetime.months.${str.split("-")[0]}`),
    to: i18next.t(`datetime.months.${str.split("-")[1]}`),
  });
};

export const timeSwitchConditionDisplay = (
  condition: DpElementTimeSwitchCondition
) => {
  if (
    (!condition?.timeRange || condition.timeRange === "00:00-23:59") &&
    (!condition?.weekDayRange || condition.weekDayRange === "mon-sun") &&
    (!condition?.monthDayRange || condition.monthDayRange === "1-31") &&
    (!condition?.monthRange || condition.monthRange === "jan-dec")
  ) {
    return i18next
      .t("dp-editor.time-routing-form-modal.all-the-time")
      .toString();
  }
  return `${timeSwitchTimeDisplay(
    condition.timeRange
  )}, ${timeSwitchWeekDaysDisplay(
    condition.weekDayRange
  )}, ${timeSwitchMonthDaysDisplay(
    condition.monthDayRange
  )}, ${timeSwitchMonthsDisplay(condition.monthRange)}`;
};
