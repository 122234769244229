import classNames from "classnames";
import { nanoid } from "nanoid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useForwardedRef from "@bedrock-layout/use-forwarded-ref";

const Radio = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & { label?: string }
>((props, ref) => {
  const innerRef = useForwardedRef(ref);
  const generatedRadioId = useMemo(
    () => nanoid().replace(/[^a-zA-Z]+/g, ""),
    []
  );
  const radioId = useMemo(
    () => props.id || generatedRadioId,
    [props.id, generatedRadioId]
  );
  const [checked, setChecked] = useState<boolean>();
  const onRadioChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(e);
      }
      setChecked(e.target.checked);
      if (e.target.checked) {
        document.querySelectorAll(`[name="${props.name}"]`).forEach(($el) => {
          if ($el.id !== radioId) {
            $el.dispatchEvent(new Event("custom:uncheck"));
          }
        });
      }
    },
    [props, radioId]
  );
  useEffect(() => {
    innerRef.current.addEventListener("custom:uncheck", () => {
      setChecked(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const $radio: HTMLInputElement | null = document.querySelector(
      `#${radioId}`
    );
    if ($radio) {
      setChecked($radio.checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const keypressHandler: React.KeyboardEventHandler<HTMLLabelElement> = (e) => {
    if (e.key === "Enter") {
      (e.target as HTMLLabelElement).click();
    }
  };
  return (
    <label
      className={classNames(
        "studio-focusable",
        "p-4",
        "m-1",
        "cursor-pointer",
        "border",
        "border-solid",
        "rounded-lg",
        "inline-flex",
        "items-center",
        checked ? "border-primary-400" : "border-outline-500",
        checked ? "bg-primary-700" : "bg-gs-900"
      )}
      htmlFor={radioId}
      tabIndex={0}
      onKeyPress={keypressHandler}
    >
      <input
        type="radio"
        ref={innerRef}
        {...{
          ...props,
          className: classNames(props.className, "hidden"),
          id: radioId,
          onChange: onRadioChangeHandler,
        }}
      />
      <div
        className={classNames(
          "mr-2 border border-solid rounded-full w-3.5 h-3.5 relative",
          checked ? "border-primary-300" : "border-gs-400"
        )}
      >
        {checked ? (
          <div className="w-2 h-2 top-0.5 left-0.5 bg-primary-200 rounded-full absolute" />
        ) : null}
      </div>
      <span className="text-s font-bold text-gs-100">{props.label}</span>
    </label>
  );
});

export default Radio;
