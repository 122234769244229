import React, { useEffect, useState } from "react";
import BaseModal from "src/components/BaseModal";
import { DemoAccountUserData, findCloudsoftPhone, PhoneData } from "../api";
import { getLocalAccount } from "../localStorage";
import { Ring } from "@uiball/loaders";
import QRCode from "react-qr-code";

const CloudSoftphonePopup: React.FC<{
  opened: boolean;
  closeHandler: () => void;
  data: DemoAccountUserData;
}> = ({ opened, data, closeHandler }) => {
  const { username, password } = getLocalAccount();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [phoneData, setPhoneData] = useState<PhoneData | undefined>(undefined);

  const load = async () => {
    const phoneData = await findCloudsoftPhone(username, password);
    setPhoneData(phoneData);
    setIsLoading(false);
  };
  useEffect(() => {
    load();
  }, []);

  let qrText = "";
  if (phoneData) {
    // our acrobits cloud id for the demodemo reseller
    const cloudId = "DEMODEMO";
    qrText = `csc:${phoneData.sipAccount.username}:${phoneData.sipAccount.secret}@${cloudId}`;
  }

  return (
    <BaseModal
      title={"Set up a phone with Cloud Softphone"}
      onRequestClose={closeHandler}
      hideFooterButtons={true}
      isOpen={opened}
    >
      <p className="text-s font-medium">
        To fully experience the demo, you will benefit from connecting a phone
        to the demo environment.
      </p>
      <p className="text-s font-medium">To do that, follow these easy steps:</p>
      <ul className="list-none pl-0 mt-6 mb-6 text-s font-medium">
        <li className="mb-2">
          1. Download Cloud Softphone from{" "}
          <a
            href="https://apps.apple.com/us/app/cloud-softphone/id567475545"
            className="text-white text-opacity-90"
            target="_blank"
            rel="noreferrer"
          >
            App store
          </a>{" "}
          or{" "}
          <a
            href="https://play.google.com/store/apps/details?id=cz.acrobits.softphone.cloudphone"
            className="text-white text-opacity-90"
            target="_blank"
            rel="noreferrer"
          >
            Play Store
          </a>
        </li>
        <li className="mb-2">2. Open the app and select “Scan QR”</li>
        <li className="mb-2">3. Scan the following QR code.</li>
        <li className="mb-2">4. You’re all set!</li>
      </ul>
      {isLoading && (
        <div className={"mb-8"}>
          <Ring size={40} lineWeight={5} speed={2} color="white" />
        </div>
      )}
      {!isLoading && (
        <div className={"mb-8"}>
          <QRCode value={qrText} />
        </div>
      )}
    </BaseModal>
  );
};

export default CloudSoftphonePopup;
