import React from "react";
import { useForm } from "react-hook-form";
import { DpElementDpSwitch } from "src/app/types/dialplans";
import BaseModal, { BaseModalProps } from "src/components/BaseModal";
import { useTranslation } from "react-i18next";
import { copyObject } from "src/utils";
import { nameFieldValidator } from "src/utils/form";
import FormGroup from "src/components/FormGroup";

const DpSwitchNameFormModal: React.FC<
  BaseModalProps & {
    dpElement: DpElementDpSwitch;
    branch: number;
    onRequestClose: (data?: { dpElement: DpElementDpSwitch }) => void;
  }
> = ({ dpElement, branch, onRequestClose, isOpen }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ name: string }>({
    defaultValues: {
      name: dpElement.settings[branch].name,
    },
  });
  const submitHandler = handleSubmit((formData) => {
    if (!dpElement) {
      return onRequestClose();
    }
    const updatedDpElement: DpElementDpSwitch = copyObject(dpElement);
    updatedDpElement.settings[branch].name = formData.name;
    onRequestClose({ dpElement: updatedDpElement });
  });
  return (
    <BaseModal
      title={t("dp-editor.dp-switch-name-form-modal.title")}
      onRequestClose={onRequestClose.bind(null, undefined)}
      isOpen={isOpen}
      buttons={[
        { text: t("controls.save"), props: { onClick: submitHandler } },
      ]}
    >
      <FormGroup
        header={`${t("form-labels.dp-switch-settings-name")} *`}
        error={errors.name}
        htmlFor="id_name"
      >
        <input
          type="text"
          id="id_name"
          className="studio-form__control"
          {...register("name", { ...nameFieldValidator() })}
        />
      </FormGroup>
    </BaseModal>
  );
};

export default DpSwitchNameFormModal;
