import {
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  SerializedError,
  Slice,
} from "@reduxjs/toolkit";
import { RootState } from "src/app/rootReducer";

type GenericDataSliceState<ItemType> = {
  items: ItemType[];
  isLoading: boolean;
  loaded: boolean;
  loadError?: SerializedError;
};

export const genericDataSliceFactory = <ItemType>(
  type: string,
  loadFunction: (companyId: number) => Promise<ItemType[]>
): {
  slice: Slice<GenericDataSliceState<ItemType>>;
  loadItemsThunk: AsyncThunk<
    ItemType[],
    void,
    {
      state: any;
    }
  >;
} => {
  const initialState: GenericDataSliceState<ItemType> = {
    items: [],
    isLoading: false,
    loaded: false,
    loadError: undefined,
  };
  const loadItemsThunk = createAsyncThunk<
    ItemType[],
    void,
    { state: RootState }
  >(`${type}/load`, async (_, { getState, rejectWithValue }) => {
    const companyId = getState().auth.companyId;
    if (!companyId) {
      return rejectWithValue(`auth.companyId is required`);
    }
    try {
      return loadFunction(companyId);
    } catch (error) {
      return rejectWithValue(error);
    }
  });
  const slice = createSlice({
    name: type,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(loadItemsThunk.pending, (state) => {
        state.loadError = undefined;
        state.isLoading = true;
      });
      builder.addCase(loadItemsThunk.fulfilled, (state, { payload: items }) => {
        return {
          ...state,
          isLoading: false,
          loaded: true,
          items,
        };
      });
      builder.addCase(loadItemsThunk.rejected, (state, { error }) => {
        state.loadError = error;
        state.isLoading = false;
      });
    },
  });
  return {
    loadItemsThunk,
    slice,
  };
};
