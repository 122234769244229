import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/app/rootReducer";
import aes from "crypto-js/aes";
import encUtf8 from "crypto-js/enc-utf8";
import { restApi } from "src/app/api";
import { CompassUser } from "src/app/types/compass";
import i18n, { defaultLanguage } from "src/i18n";
import { getAppElementId } from "src/utils";

type AuthState = {
  isAuthenticated?: boolean;
  loginInProgress: boolean;
  loginErrorMessage?: string;
  initializationInProgress: boolean;

  companyId?: number;
  user?: CompassUser;
  loadUserInProgress: boolean;
  loadUserError: boolean;
};

const initialState: AuthState = {
  loginInProgress: false,
  initializationInProgress: false,

  loadUserInProgress: false,
  loadUserError: false,
};

type LoginCredentials = {
  username: string;
  password: string;
};

const AUTH_STATE_KEY = "studio:auth:state";
const STORE_SECRET_PHRASE = "studio:phrase";
const authStorage = localStorage;

const saveLoginCredentials = (username: string, password: string) => {
  authStorage.setItem(
    AUTH_STATE_KEY,
    aes
      .encrypt(JSON.stringify({ username, password }), STORE_SECRET_PHRASE)
      .toString()
  );
};

const getLoginCredentials = (): LoginCredentials | null => {
  try {
    const authState = authStorage.getItem(AUTH_STATE_KEY);
    if (!authState) {
      throw new Error();
    }
    return JSON.parse(
      aes.decrypt(authState, STORE_SECRET_PHRASE).toString(encUtf8)
    );
  } catch {
    return null;
  }
};

const clearLoginCredentials = () => {
  authStorage.removeItem(AUTH_STATE_KEY);
};

const proceedLogin = createAsyncThunk<
  number,
  LoginCredentials,
  { state: RootState }
>(
  "auth/proceedLogin",
  async ({ username, password }, { rejectWithValue, dispatch, getState }) => {
    try {
      const { companyId } = await restApi.authorize(username, password);
      const loadUserResponse = await dispatch(loadUser());
      if (loadUserResponse.type === loadUser.rejected.type) {
        throw loadUserResponse.payload;
      }
      const user = getState().auth.user;
      if (user) {
        i18n.changeLanguage(user.theLanguage);
      }
      await saveLoginCredentials(username, password);
      return companyId;
    } catch (error) {
      restApi.reset();
      rejectWithValue(error);
      throw error;
    }
  }
);

const loadUser = createAsyncThunk<CompassUser, void, { state: RootState }>(
  "auth/loadUser",
  async (_, { rejectWithValue }) => {
    try {
      return (await restApi.get<CompassUser>("user")).data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const login = createAsyncThunk<
  void,
  LoginCredentials,
  { state: RootState }
>("auth/login", async (credentials, { dispatch, rejectWithValue }) => {
  const response = await dispatch(proceedLogin(credentials));
  if (response.type === proceedLogin.rejected.type) {
    return rejectWithValue(`We don't recognize your username and/or password.`);
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  clearLoginCredentials();
  i18n.changeLanguage(defaultLanguage);
});

export const authInitialize = createAsyncThunk<
  boolean,
  void,
  { state: RootState }
>("auth/authInitialize", async (_, { dispatch }) => {
  const loginCredentials = getLoginCredentials();
  if (!loginCredentials) {
    return false;
  }
  const { username, password } = loginCredentials;
  const response = await dispatch(proceedLogin({ username, password }));
  if (response.type === proceedLogin.rejected.type) {
    clearLoginCredentials();
    return false;
  }
  return true;
});

export const embeddedAuthInitialize = createAsyncThunk<
  { isAuthenticated: boolean; companyId: number },
  void,
  { state: RootState }
>("auth/embeddedAuthInitialize", async (_, { dispatch, getState }) => {
  const resp = await dispatch(loadUser());
  const user = getState().auth.user;
  if (user) {
    i18n.changeLanguage(user.theLanguage);
  }
  return {
    isAuthenticated: resp.type === loadUser.fulfilled.type,
    companyId: parseInt(
      document
        .querySelector(`#${getAppElementId()}`)!
        .getAttribute("data-company-id")!
    ),
  };
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loginInProgress = true;
      state.loginErrorMessage = undefined;
    });
    builder.addCase(login.fulfilled, (state) => {
      state.loginInProgress = false;
    });
    builder.addCase(login.rejected, (state, { payload: errorMessage }) => {
      state.loginInProgress = false;
      state.loginErrorMessage = errorMessage as string;
    });
    builder.addCase(authInitialize.pending, (state) => {
      state.initializationInProgress = true;
    });
    builder.addCase(
      authInitialize.fulfilled,
      (state, { payload: isAuthenticated }) => {
        state.initializationInProgress = false;
        state.isAuthenticated = isAuthenticated;
      }
    );
    builder.addCase(authInitialize.rejected, (state) => {
      state.initializationInProgress = false;
      state.isAuthenticated = false;
    });

    builder.addCase(embeddedAuthInitialize.pending, (state) => {
      state.initializationInProgress = true;
    });
    builder.addCase(
      embeddedAuthInitialize.fulfilled,
      (state, { payload: { isAuthenticated, companyId } }) => {
        state.initializationInProgress = false;
        state.isAuthenticated = isAuthenticated;
        state.companyId = companyId;
      }
    );
    builder.addCase(embeddedAuthInitialize.rejected, (state) => {
      state.initializationInProgress = false;
      state.isAuthenticated = false;
    });

    builder.addCase(proceedLogin.fulfilled, (state, { payload }) => {
      state.isAuthenticated = true;
      state.companyId = payload;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isAuthenticated = false;
      state.user = undefined;
      state.companyId = undefined;
      state.loadUserInProgress = false;
      state.loadUserError = false;
    });

    builder.addCase(loadUser.pending, (state) => {
      state.loadUserInProgress = true;
      state.loadUserError = false;
    });
    builder.addCase(loadUser.fulfilled, (state, { payload: user }) => {
      state.user = user;
      state.loadUserInProgress = false;
    });
    builder.addCase(loadUser.rejected, (state) => {
      state.loadUserInProgress = false;
      state.loadUserError = true;
    });
  },
});

export const selectAuthStatus = ({
  auth: {
    isAuthenticated,
    loginInProgress,
    initializationInProgress,
    loginErrorMessage,
  },
}: RootState) => ({
  isAuthenticated,
  loginInProgress,
  initializationInProgress,
  loginErrorMessage,
});

export const selectUser = ({ auth: { user } }: RootState) => user;

export default authSlice.reducer;
