import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/app/store";
import { DpModalType } from "src/app/types";
import {
  DpElementCombined,
  DpElementDpSwitch,
  DpElementDtmf,
  DpElementIvr,
  DpElementNumberRecognition,
  DpElementPrefix,
  DpElementTimeSwitch,
} from "src/app/types/dialplans";
import { getDpElementById } from "src/utils/dialPlan";
import {
  closeConfigModal,
  removeDialPlanElement,
  selectDialPlanState,
  selectOpenedConfigModalState,
  updateDialPlanElement,
} from "./dpEditorSlice";
import { NumberRecognitionBranchesFormModal } from "./Modals/BranchesFormModal";
import DpSwitchFormModal from "./Modals/CallFlowFormModal";
import DpSwitchNameFormModal from "./Modals/DpSwitchNameFormModal";
import DtmfInputFormModal from "./Modals/DtmfInputFormModal";
import NumberRecognitionCreateWizard from "./Wizards/NumberRecognitionCreateWizard";
import IvrMenuCreateWizard from "./Wizards/IvrMenuCreateWizard";
import IvrMenuFormModal from "./Modals/IvrMenuFormModal";
import NumberRecognitionFormModal from "./Modals/NumberRecognitionFormModal";
import LabelFormModal from "./Modals/LabelFormModal";
import TimeRoutingFormModal from "./Modals/TimeRoutingFormModal";
import { ModalFormAction } from "./types";
import TimeSwitchWizard from "./Wizards/TimeSwitchWizard";
import CallFlowCreateWizard from "./Wizards/CallFlowCreateWizard";
import DtmfCreateWizard from "./Wizards/DtmfCreateWizard";

const ConfigModals: React.FC = () => {
  const openedConfigModal = useSelector(selectOpenedConfigModalState);
  const dispatch = useAppDispatch();
  const { dialPlan } = useSelector(selectDialPlanState);
  const dpElement = useMemo(() => {
    if (openedConfigModal?.id) {
      return getDpElementById(openedConfigModal?.id, dialPlan);
    }
  }, [openedConfigModal, dialPlan]);
  const closeModalHandler = useCallback(
    (data?: { dpElement: DpElementCombined }) => {
      if (!openedConfigModal) {
        return;
      }
      if (data) {
        const { dpElement } = data;
        if (dpElement._temp?.id)
          dispatch(
            updateDialPlanElement({
              id: dpElement._temp.id,
              dpElement: dpElement,
              isNew: openedConfigModal.action === ModalFormAction.create,
            })
          );
      } else {
        if (openedConfigModal.action === ModalFormAction.create) {
          dispatch(
            removeDialPlanElement({
              id: openedConfigModal.id,
            })
          );
        }
      }
      dispatch(closeConfigModal());
    },
    [openedConfigModal, dispatch]
  );
  const modalBaseProps = useMemo(
    () => ({
      isOpen: true,
      onRequestClose: closeModalHandler,
    }),
    [closeModalHandler]
  );
  switch (openedConfigModal?.type) {
    case DpModalType.numberRecognitionBranchesForm:
      return openedConfigModal.action === ModalFormAction.create ? (
        <NumberRecognitionCreateWizard
          {...modalBaseProps}
          dpElement={dpElement as DpElementNumberRecognition}
        />
      ) : (
        <NumberRecognitionBranchesFormModal
          {...modalBaseProps}
          dpElement={dpElement as DpElementNumberRecognition}
        />
      );
    case DpModalType.timeSwitchBranchesForm:
      return (
        <TimeSwitchWizard
          {...modalBaseProps}
          action={openedConfigModal.action}
          dpElement={dpElement as DpElementTimeSwitch}
        />
      );
    case DpModalType.ivrMenuForm:
      return openedConfigModal.action === ModalFormAction.create ? (
        <IvrMenuCreateWizard
          {...modalBaseProps}
          onRequestClose={closeModalHandler}
          dpElement={dpElement as DpElementIvr}
        />
      ) : (
        <IvrMenuFormModal
          {...modalBaseProps}
          onRequestClose={closeModalHandler}
          dpElement={dpElement as DpElementIvr}
        />
      );
    case DpModalType.dpSwitchForm:
      return openedConfigModal.action === ModalFormAction.create ? (
        <CallFlowCreateWizard
          {...modalBaseProps}
          onRequestClose={closeModalHandler}
          dpElement={dpElement as DpElementDpSwitch}
        />
      ) : (
        <DpSwitchFormModal
          {...modalBaseProps}
          dpElement={dpElement as DpElementDpSwitch}
        />
      );
    case DpModalType.labelForm:
      return (
        <LabelFormModal
          {...modalBaseProps}
          dpElement={dpElement as DpElementPrefix}
          action={openedConfigModal.action}
        />
      );
    case DpModalType.dtmfForm:
      return openedConfigModal.action === ModalFormAction.create ? (
        <DtmfCreateWizard
          {...modalBaseProps}
          onRequestClose={closeModalHandler}
          dpElement={dpElement as DpElementDtmf}
        />
      ) : (
        <DtmfInputFormModal
          {...modalBaseProps}
          dpElement={dpElement as DpElementDtmf}
        />
      );
    case DpModalType.dpSwitchNameForm:
      if (openedConfigModal.branch !== undefined) {
        return (
          <DpSwitchNameFormModal
            {...modalBaseProps}
            dpElement={dpElement as DpElementDpSwitch}
            branch={openedConfigModal.branch}
          />
        );
      }
      return null;
    case DpModalType.timeRoutingForm:
      if (openedConfigModal.branch !== undefined) {
        return (
          <TimeRoutingFormModal
            {...modalBaseProps}
            dpElement={dpElement as DpElementTimeSwitch}
            branch={openedConfigModal.branch}
          />
        );
      }
      return null;
    case DpModalType.numberRecognitionForm:
      if (openedConfigModal.branch !== undefined) {
        return (
          <NumberRecognitionFormModal
            {...modalBaseProps}
            dpElement={dpElement as DpElementNumberRecognition}
            branch={openedConfigModal.branch}
          />
        );
      }
      return null;
    default:
      return null;
  }
};

export default ConfigModals;
