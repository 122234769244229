import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/app/store";
import { DpSideBarType } from "src/app/types";
import {
  DpElementDpSwitch,
  DpElementDtmf,
  DpElementIvr,
  DpElementNumberRecognition,
  DpElementPrefix,
  DpElementTimeSwitch,
} from "src/app/types/dialplans";
import DtmfSideBar from "./SideBars/DtmfSideBar";
import { getDpElementById } from "src/utils/dialPlan";
import {
  closeSideBar,
  selectOpenedSideBarState,
  selectDialPlanState,
} from "./dpEditorSlice";
import CallFlowSideBar from "./SideBars/CallFlowSideBar";
import IvrSideBar from "./SideBars/IvrSideBar";
import NumberRecognitionSideBar from "./SideBars/NumberRecognitionSideBar";
import LabelSideBar from "./SideBars/LabelSideBar";
import TimeSwitchSideBar from "./SideBars/TimeSwitchSideBar";
import { useLastDefinded } from "src/utils/hooks";
import { SideBarProps } from "src/components/SideBar";

const DpEditorSideBar: React.FC = () => {
  const sideBarsState = useSelector(selectOpenedSideBarState);
  const dispatch = useAppDispatch();
  const handleSideBarRequestClose = useCallback(() => {
    dispatch(closeSideBar());
  }, [dispatch]);
  const { dialPlan } = useSelector(selectDialPlanState);
  const dpElementCurrent = useMemo(() => {
    if (sideBarsState?.id) {
      return getDpElementById(sideBarsState?.id, dialPlan);
    }
  }, [sideBarsState, dialPlan]);
  const dpElement = useLastDefinded(dpElementCurrent);
  const defaultSideBarProps: Partial<SideBarProps> = useMemo(
    () => ({
      isElementRemoved: !dpElementCurrent,
      onRequestClose: handleSideBarRequestClose,
    }),
    [handleSideBarRequestClose, dpElementCurrent]
  );
  const $sideBar = useMemo(() => {
    if (!dpElement) {
      return null;
    }
    switch (sideBarsState?.type) {
      case DpSideBarType.ivr:
        return (
          <IvrSideBar
            {...{
              ...defaultSideBarProps,
              dpElement: dpElement as DpElementIvr,
            }}
          />
        );
      case DpSideBarType.numberRecognition:
        return (
          <NumberRecognitionSideBar
            {...{
              ...defaultSideBarProps,
              dpElement: dpElement as DpElementNumberRecognition,
            }}
          />
        );
      case DpSideBarType.timeSwitch:
        return (
          <TimeSwitchSideBar
            {...{
              ...defaultSideBarProps,
              dpElement: dpElement as DpElementTimeSwitch,
            }}
          />
        );
      case DpSideBarType.callFlow:
        return (
          <CallFlowSideBar
            {...{
              ...defaultSideBarProps,
              dpElement: dpElement as DpElementDpSwitch,
            }}
          />
        );
      case DpSideBarType.dtmf:
        return (
          <DtmfSideBar
            {...{
              ...defaultSideBarProps,
              dpElement: dpElement as DpElementDtmf,
            }}
          />
        );
      case DpSideBarType.label:
        return (
          <LabelSideBar
            {...{
              ...defaultSideBarProps,
              dpElement: dpElement as DpElementPrefix,
            }}
          />
        );
      default:
        return null;
    }
  }, [sideBarsState, dpElement, defaultSideBarProps]);
  return $sideBar ? <div className="h-full w-80">{$sideBar}</div> : null;
};

export default DpEditorSideBar;
