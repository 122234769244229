import i18n from "i18next";
import { HIDDEN_CALLER_ID_INPUT_VALUE } from "./dialPlan";
import { RegisterOptions, Validate } from "react-hook-form";

export const nameMaxLength = 60;

export const nameFieldValidator = (required?: boolean): RegisterOptions => ({
  validate: (value: string) => {
    // #27: don't allow names with just spaces (even for non-required inputs)
    if (
      (required && !value.length) ||
      (value.length > 0 && !value.replace(/\s/g, "").length)
    ) {
      return i18n.t("form-validation-errors.field-required") as string;
    }
    if (value.length > nameMaxLength) {
      return i18n.t("form-validation-errors.name-field-max-length", {
        maxLength: nameMaxLength,
        value,
      }) as string;
    }
    return true;
  },
});

export const prefixMaxLengthValidator = (): RegisterOptions => ({
  maxLength: {
    value: nameMaxLength,
    message: i18n.t("form-validation-errors.prefix-field-max-length", {
      maxLength: nameMaxLength,
    }),
  },
});

export const requiredValidator = (message?: string): RegisterOptions => ({
  required: {
    value: true,
    message: message || i18n.t("form-validation-errors.field-required"),
  },
});

export const isNumberPrefixValid = (value: string) => {
  return /^[1-9]\d*$/.test(value) || value === HIDDEN_CALLER_ID_INPUT_VALUE;
};

export const numberPrefixesValidator = (): RegisterOptions => ({
  validate: (value?: string[]) => {
    const badValue = value?.find((item) => !isNumberPrefixValid(item));
    if (badValue) {
      return i18n.t("form-validation-errors.number-prefix-field-format", {
        badValue,
      }) as string;
    }
    return true;
  },
});

export const numberBetweenValidator = (
  min: number,
  max: number
): RegisterOptions => ({
  validate: (value: string) => {
    if (!value) {
      return true;
    }
    if (!/^\d+$/.test(value)) {
      return i18n.t("form-validation-errors.number-field-invalid", {
        value,
      }) as string;
    }
    const valueNumber = parseInt(value);
    if (valueNumber < min || valueNumber > max) {
      return i18n.t("form-validation-errors.number-field-between", {
        min,
        max,
        value,
      }) as string;
    }
    return true;
  },
});

export const shortCodeValidator = numberBetweenValidator.bind(null, 1, 99999);

export const timeoutValidator = numberBetweenValidator.bind(null, 1, 600);

export const repeatValidator = numberBetweenValidator.bind(null, 0, 100);

export const dtmfDigitsValidator = numberBetweenValidator.bind(null, 1, 100);

export const dpShortCodeValidator = (
  usedShortCodes: number[]
): RegisterOptions => ({
  validate: (value) => {
    if (!value) {
      return i18n.t("form-validation-errors.field-required").toString();
    }
    const shortCodeValidationResponse = (
      shortCodeValidator().validate as Validate<string>
    )(value);
    if (shortCodeValidationResponse !== true) {
      return shortCodeValidationResponse;
    }
    if (usedShortCodes.includes(parseInt(value))) {
      return i18n.t("form-validation-errors.dp-switch-shortcode-field-used", {
        value,
      }) as string;
    }
    return true;
  },
});
