import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DpElementCombined,
  DpElementNumberRecognition,
  DpElementType,
} from "src/app/types/dialplans";
import styles from "./Branch.module.css";
import Element, {
  ElementDragItem,
  ElementDropDirection,
  ElementExtraProps,
} from "./Element";
import ElementDropBox from "./ElementDropBox";
import {
  elementDialPlanPositionToId,
  getBranchElementId,
  getDpElementBranches,
  isDpElementClickable,
} from "src/utils/dialPlan";
import { usePrevious } from "src/utils/hooks";
import { compareObjects } from "src/utils";
import AnimateHeight from "react-animate-height";
import { selectCollapseState } from "./dpEditorSlice";
import classNamesBind from "classnames/bind";
import { useSelector } from "react-redux";

/**
 * Utility component to handle branches collapse/expand animation
 */
const CollapsibleBranchWrap: React.FC<{
  visible: boolean;
  className?: string;
}> = ({ visible, children, className }) => {
  const [delayedVisible, setDelayedVisible] = useState(visible);
  useEffect(() => {
    if (visible === delayedVisible) {
      return;
    }
    if (!delayedVisible) {
      // NOTE: a little delay to let children
      // render before animation starts
      setTimeout(() => {
        setDelayedVisible(visible);
      }, 100);
    }
  }, [delayedVisible, visible]);
  const animationEndHandler = useCallback(() => {
    if (visible !== delayedVisible) {
      setDelayedVisible(visible);
    }
  }, [visible, delayedVisible]);
  return (
    <AnimateHeight
      height={(!visible && delayedVisible) || !delayedVisible ? 0 : "auto"}
      onAnimationEnd={animationEndHandler}
      className={className}
    >
      {(!visible && delayedVisible) || visible ? children : <></>}
    </AnimateHeight>
  );
};

const classNames = classNamesBind.bind(styles);

/**
 * Branch component props
 */
export type BranchProps = {
  /**
   * dpElements - list of dial plan elements
   */
  dpElements: DpElementCombined[];
  /**
   * disabled - turns off any interaction with branch
   */
  disabled?: boolean;
  root?: {
    /**
     * dpElement - parent element
     */
    dpElement: DpElementCombined;
    /**
     * elementIndex - index on parent branch
     */
    elementIndex: number;
    /**
     * branchIndex - branch index of parent element
     */
    branchIndex: number;
    /**
     * isDefault - used for default branch (eg. number recognition)
     */
    isDefault?: boolean;
  };
  /**
   * path - position on a canvas [parent branch place idx, branch number]
   */
  path: [number, number][];
  onToggleCollapse?: (id: string) => void;
  onElementAdd?: (
    dpElement: DpElementCombined,
    path: number[][],
    idx?: number
  ) => void;
  onElementMove?: (
    origin: {
      path: number[][];
      idx: number;
    },
    destination: {
      path: number[][];
      idx?: number;
    }
  ) => void;
  onElementRemove?: (
    dpElement: DpElementCombined,
    extra?: ElementExtraProps
  ) => void;
  onElementClick?: (
    dpElement: DpElementCombined,
    extra?: ElementExtraProps
  ) => void;
};

/**
 * Renders a list of dialplan steps.
 * More info on how it works:
 * https://gitlab.iperitydev.com/compass/dialplan-editor/-/merge_requests/9#note_85801
 */
const Branch: React.FC<BranchProps> = ({
  dpElements,
  root,
  onElementRemove,
  onElementAdd,
  onElementMove,
  onElementClick,
  path,
  disabled,
  onToggleCollapse,
}) => {
  const collapseState = useSelector(selectCollapseState);
  const isBranchOpened = useCallback(
    (id: string) =>
      collapseState.elements[id] !== undefined
        ? collapseState.elements[id]
        : collapseState.allOpened,
    [collapseState]
  );
  const elementDropHandler = useCallback(
    (
      idx: number,
      { dpElement, extra }: ElementDragItem,
      direction: ElementDropDirection
    ) => {
      const dropIdx = direction === ElementDropDirection.up ? idx : idx + 1;
      if (extra?.dialPlanPosition) {
        if (!onElementMove) {
          return;
        }
        onElementMove(
          {
            path: extra.dialPlanPosition.path || [],
            idx: extra.dialPlanPosition.idx,
          },
          {
            path,
            idx: dropIdx,
          }
        );
        return;
      }
      if (!onElementAdd) {
        return;
      }
      onElementAdd(dpElement, path, dropIdx);
    },
    [onElementAdd, onElementMove, path]
  );
  const dropBoxDropHandler = useCallback(
    ({ dpElement, extra }: ElementDragItem) => {
      if (extra?.dialPlanPosition) {
        if (!onElementMove) {
          return;
        }
        onElementMove(
          {
            path: extra.dialPlanPosition.path || [],
            idx: extra.dialPlanPosition.idx,
          },
          { path }
        );
        return;
      }
      if (!onElementAdd) {
        return;
      }
      onElementAdd(dpElement, path);
    },
    [onElementAdd, path, onElementMove]
  );
  const [draggedElement, setDraggedElement] = useState<string>();
  const onElementDragStart = useCallback(
    (_: DpElementCombined, extra?: ElementExtraProps) => {
      if (!extra?.dialPlanPosition) {
        return;
      }
      setDraggedElement(elementDialPlanPositionToId(extra.dialPlanPosition));
    },
    [setDraggedElement]
  );
  const onElementDragEnd = useCallback(
    (_: DpElementCombined, extra?: ElementExtraProps) => {
      setDraggedElement(undefined);
    },
    [setDraggedElement]
  );
  const previousDpElements = usePrevious(dpElements);
  const dpElementsChanged = useMemo(
    () => !compareObjects(dpElements, previousDpElements),
    [dpElements, previousDpElements]
  );
  const showRootCloseBtn = useMemo(() => {
    if (!root?.dpElement) {
      return null;
    }
    if (
      root.isDefault ||
      (root.dpElement.type === DpElementType.dpSwitch &&
        getDpElementBranches(root.dpElement).length <= 2)
    ) {
      return false;
    }
    return true;
  }, [root]);
  useEffect(() => {
    if (draggedElement && dpElementsChanged) {
      setDraggedElement(undefined);
    }
  }, [dpElementsChanged, draggedElement, setDraggedElement]);
  const $elements = (
    <>
      {dpElements.map((item, idx) => {
        let branches:
          | { steps: DpElementCombined[]; isDefault?: boolean }[]
          | null = null;
        const dialPlanPosition = {
          idx,
          path,
        };
        const positionId = elementDialPlanPositionToId(dialPlanPosition);
        const itemId = item._temp?.id!;
        if (draggedElement !== positionId) {
          branches = getDpElementBranches(item)?.map((branch) => ({
            steps: branch.steps,
          }));
          if (
            item.type === DpElementType.numberRecognition &&
            (item as DpElementNumberRecognition).defaultSteps
          ) {
            branches.push({
              steps: (item as DpElementNumberRecognition).defaultSteps,
              isDefault: true,
            });
          }
        }
        const childOpened = isBranchOpened(itemId);
        return (
          <React.Fragment key={idx}>
            <div
              className={classNames("elementWrap", {
                elementWrap__beforeDropBox: idx === dpElements.length - 1,
              })}
            >
              <Element
                dpElement={item}
                draggable={true}
                onDrop={elementDropHandler.bind(null, idx)}
                onRemove={onElementRemove}
                onClick={
                  isDpElementClickable(item) ? onElementClick : undefined
                }
                onDragStart={onElementDragStart}
                onDragEnd={onElementDragEnd}
                extra={{
                  dialPlanPosition,
                }}
                disabled={disabled}
                showUnderlay={!childOpened && !!branches?.length}
                onChildrenToggleClick={
                  branches?.length
                    ? onToggleCollapse?.bind(null, itemId)
                    : undefined
                }
                className={styles.element}
                childrenOpened={childOpened}
                disableDrop={
                  childOpened || idx === dpElements.length - 1
                    ? [ElementDropDirection.down]
                    : undefined
                }
              />
              {branches?.length ? (
                <CollapsibleBranchWrap
                  visible={!!childOpened}
                  className={styles.elementChild}
                >
                  {branches.map((branch, branchIdx) => {
                    const branchPath: [number, number][] = [
                      ...path,
                      [idx, branchIdx],
                    ];
                    return (
                      <Branch
                        key={branchIdx}
                        path={branchPath}
                        dpElements={branch.steps}
                        root={
                          // NOTE: don't show time switch branch root level
                          // in case there is only one branch
                          // https://gitlab.iperitydev.com/compass/dialplan-editor/-/issues/5
                          item.type === DpElementType.timeSwitch &&
                          branches?.length === 1
                            ? undefined
                            : {
                                dpElement: item,
                                branchIndex: branchIdx,
                                elementIndex: idx,
                                isDefault: branch.isDefault,
                              }
                        }
                        onElementAdd={onElementAdd}
                        onElementRemove={onElementRemove}
                        onElementClick={onElementClick}
                        onElementMove={onElementMove}
                        disabled={disabled}
                        onToggleCollapse={onToggleCollapse}
                      />
                    );
                  })}
                </CollapsibleBranchWrap>
              ) : null}
            </div>
          </React.Fragment>
        );
      })}
      <div className={classNames("elementWrap", "elementWrap__dropBox")}>
        <ElementDropBox
          onDropped={dropBoxDropHandler}
          className={styles.dropBox}
        />
      </div>
    </>
  );
  const rootExtra: ElementExtraProps | undefined = useMemo(() => {
    return root
      ? {
          branch: root.branchIndex,
          dialPlanPosition: {
            idx: root.elementIndex,
            path: path?.slice(0, path.length - 1),
          },
        }
      : undefined;
  }, [root, path]);
  const { rootBranchId, rootBranchOpened, rootBranchesCount } = useMemo<{
    rootBranchId: string | undefined;
    rootBranchOpened: boolean | undefined;
    rootBranchesCount: number | undefined;
  }>(() => {
    if (!root) {
      return {
        rootBranchId: undefined,
        rootBranchOpened: undefined,
        rootBranchesCount: undefined,
      };
    }
    const branchId = getBranchElementId(root.dpElement, root.branchIndex);
    return {
      rootBranchId: branchId,
      rootBranchOpened: isBranchOpened(branchId),
      rootBranchesCount: getDpElementBranches(root.dpElement).length,
    };
  }, [root, isBranchOpened]);
  return root ? (
    <div
      className={classNames("elementWrap", {
        elementWrap__lastBranch:
          (root.dpElement.type !== DpElementType.numberRecognition &&
            rootBranchesCount &&
            rootBranchesCount - 1 === root.branchIndex) ||
          (root.dpElement.type === DpElementType.numberRecognition &&
            root.isDefault),
      })}
    >
      <Element
        dpElement={root.dpElement}
        draggable={false}
        extra={rootExtra}
        onClick={
          isDpElementClickable(root.dpElement, root.branchIndex)
            ? onElementClick
            : undefined
        }
        onRemove={showRootCloseBtn ? onElementRemove : undefined}
        disabled={disabled}
        onChildrenToggleClick={
          rootBranchId ? onToggleCollapse?.bind(null, rootBranchId) : undefined
        }
        childrenOpened={rootBranchOpened}
        showUnderlay={!rootBranchOpened && dpElements.length > 0}
        className={styles.element}
      />
      <CollapsibleBranchWrap
        visible={!!rootBranchOpened}
        className={styles.elementChild}
      >
        {$elements}
      </CollapsibleBranchWrap>
    </div>
  ) : (
    $elements
  );
};

export default Branch;
