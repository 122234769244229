import { RootState } from "src/app/rootReducer";
import { CompassResourceType, CompassUser } from "src/app/types/compass";
import { genericDataSliceFactory } from "src/utils/genericDataSliceFactory";
import { getOrderedResources } from "src/utils/resources";

const { slice, loadItemsThunk } = genericDataSliceFactory<CompassUser>(
  "users",
  (companyId) => getOrderedResources(companyId, CompassResourceType.user)
);

export const loadUsers = loadItemsThunk;

export const selectUsersState = ({ users }: RootState) => users;

export default slice.reducer;
