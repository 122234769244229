import React from "react";
import { FieldError } from "react-hook-form";
import { escape, unescape } from "html-escaper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import styles from "./FormError.module.css";

const FormError: React.FC<{ error?: FieldError }> = ({ error }) => {
  return !error || !error.message ? null : (
    <div className="flex items-center mt-2 border border-outline-300 border-solid bg-red-200 px-4 py-2 rounded-lg font-medium">
      <div className="w-8 h-8 flex items-center justify-center rounded bg-red-500 bg-opacity-10 mr-2">
        <FontAwesomeIcon
          className={`text-red-400 ${styles.icon}`}
          icon={faExclamationCircle}
        />
      </div>
      <div
        className="text-s tracking-wide text-gs-100 text-opacity-90 flex-1 break-all"
        // NOTE: i18next plugin escapes strings in some cases
        // make sure we support both escaped and unescaped strings
        dangerouslySetInnerHTML={{ __html: escape(unescape(error.message)) }}
      ></div>
    </div>
  );
};

export default FormError;
