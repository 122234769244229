import { restApi } from "src/app/api";
import { AppMode, getAppMode, reactIsInDevMode } from "./utils/index";
import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import en from "src/locales/en.json";
import nl from "src/locales/nl.json";

export enum AppLanguage {
  en = "en",
  nl = "nl",
}

export const defaultLanguage = reactIsInDevMode()
  ? AppLanguage.en
  : AppLanguage.nl;

const i18nOptions: InitOptions = {
  react: {
    useSuspense: false,
  },
  resources: {
    en: { translation: { ...en } },
    nl: { translation: { ...nl } },
  },
  fallbackLng: defaultLanguage,
};

i18n.use(initReactI18next).init(i18nOptions);

if (getAppMode() === AppMode.compassDpEditor) {
  (async () => {
    i18n.changeLanguage((await restApi.get("user")).data.theLanguage);
  })();
}

export default i18n;
