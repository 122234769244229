import React from "react";
import { FieldError } from "react-hook-form";
import FormError from "./FormError";
import styles from "./FormGroup.module.css";
import classNamesBind from "classnames/bind";

const classNames = classNamesBind.bind(styles);

const FormGroup: React.FC<{
  className?: string;
  headerClassName?: string;
  header: React.ReactElement | string;
  htmlFor?: string;
  error?: FieldError;
  description?: string;
  bottomLabel?: string;
  size?: "m" | "xs";
}> = ({
  children,
  header,
  htmlFor,
  error,
  description,
  bottomLabel,
  size,
  className,
  headerClassName,
}) => {
  return (
    <div
      className={classNames(
        "group",
        size ? `group--${size}` : undefined,
        "studio-form__group",
        {
          "studio-form__group--has-error": !!error && !!error.message,
        },
        className
      )}
    >
      <label
        className={classNames("studio-form__label", headerClassName)}
        htmlFor={htmlFor}
      >
        {header}
        {description ? (
          <div className="studio-form__label-info-description">
            {description}
          </div>
        ) : null}
      </label>
      <div className={classNames("control")}>{children}</div>
      {error ? <FormError error={error} /> : null}
      {bottomLabel ? (
        <div className="studio-form__label-info-description">{bottomLabel}</div>
      ) : null}
    </div>
  );
};

export default FormGroup;
