import axios, { AxiosInstance } from "axios";
import {
  b64EncodeUnicode,
  getAppMode,
  AppMode,
  isEmbeddedMode,
} from "src/utils";
import { CompassCompany, CompassResourceType } from "src/app/types/compass";

const defaultDomain = "apollo.compass-env.com";
const contentType = "application/vnd.iperity.compass.v3+json";

class RestApi {
  private api: AxiosInstance;
  private domain: string = defaultDomain;
  private accessToken: string | null = null;

  constructor() {
    this.api = axios.create({
      headers: {
        Accept: contentType,
        "X-No-Redirect": "true",
        "Content-Type": contentType,
      },
    });
    this.api.interceptors.request.use(async (config) => {
      if (getAppMode() === AppMode.compassDpEditor) {
        config.baseURL = `/rest/`;
      } else {
        config.baseURL = `${this.restApiUrl}/`;
        if (this.accessToken) {
          config.headers.Authorization = `Basic ${this.accessToken}`;
        }
      }
      return config;
    });
  }

  async authorize(
    fullUsername: string,
    password: string
  ): Promise<{ companyId: number }> {
    // for development: username can be username?mystack.compass.com
    // to use a non-apollo stack
    const [username, connectDomain] = fullUsername.split("?");
    this.accessToken = b64EncodeUnicode(`${username}:${password}`);

    // NOTE: If user did not specify a domain, assume it's on the Apollo stack.
    if (connectDomain) {
      this.domain = connectDomain;
    }
    try {
      const company = (await this.get<CompassCompany>("company")).data;
      this.domain = company.sipDomain.split(".").slice(1).join(".");
      return { companyId: company.entityId };
    } catch (error) {
      this.reset();
      throw error;
    }
  }

  reset() {
    this.domain = defaultDomain;
    this.accessToken = null;
  }

  get post() {
    return this.api.post;
  }

  get get() {
    return this.api.get;
  }

  get patch() {
    return this.api.patch;
  }

  get put() {
    return this.api.put;
  }

  getUrlForObject(type: CompassResourceType, id: number) {
    if (isEmbeddedMode()) {
      // eslint-disable-next-line no-restricted-globals
      let restApiDomain = location.host;
      if (restApiDomain.startsWith("www.")) {
        restApiDomain = restApiDomain.replace("www.", "rest.");
      } else {
        restApiDomain = `rest.${restApiDomain}`;
      }
      return `https://${restApiDomain}/${type}/${id}`;
    }
    return `${this.restApiUrl}/${type}/${id}`;
  }

  private get restApiUrl() {
    return `https://rest.${this.domain}`;
  }
}

export const restApi = new RestApi();
