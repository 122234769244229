import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DpElementCombined } from "src/app/types/dialplans";
import {
  openConfigModal,
  removeDialPlanElement,
  selectOpenedSideBarState,
} from "src/features/DpEditor/dpEditorSlice";
import Button, { ButtonColor, ButtonFill, ButtonSize } from "./Button";
import classNames from "classnames";
import { getModalForDpElement } from "src/utils/dialPlan";
import { ModalFormAction } from "src/features/DpEditor/types";
import { usePrevious } from "src/utils/hooks";

export type SideBarProps = {
  onRequestClose?: () => void;
  dpElement: DpElementCombined;
  isElementRemoved?: boolean;
};

const SideBar: React.FC<SideBarProps> = ({
  children,
  onRequestClose,
  dpElement,
  isElementRemoved,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openedSideBarState = useSelector(selectOpenedSideBarState);
  const [addAnimationClass, setAddAnimationClass] = useState<boolean>();
  const previousElementId = usePrevious(dpElement._temp?.id);
  useEffect(() => {
    // Add animation on dp element inside sidebar change
    if (previousElementId && dpElement._temp?.id !== previousElementId) {
      setAddAnimationClass(true);
      setTimeout(() => {
        setAddAnimationClass(false);
      }, 400);
    }
  }, [dpElement._temp?.id, previousElementId]);
  const onDeleteClick = useCallback(() => {
    if (!openedSideBarState) {
      return;
    }
    if (dpElement._temp?.id) {
      dispatch(
        removeDialPlanElement({
          id: dpElement._temp?.id,
        })
      );
    }
    if (onRequestClose) {
      onRequestClose();
    }
  }, [openedSideBarState, dispatch, onRequestClose, dpElement._temp?.id]);
  const onEditClick = useCallback(() => {
    if (!openedSideBarState) {
      return;
    }
    let modalType = getModalForDpElement(dpElement);
    if (modalType) {
      dispatch(
        openConfigModal({
          type: modalType,
          action: ModalFormAction.update,
          id: openedSideBarState.id,
          branch: openedSideBarState?.branch,
        })
      );
    }
  }, [openedSideBarState, dispatch, dpElement]);
  return (
    <div
      className={classNames(
        "w-full h-full flex flex-col border border-solid border-outline-500 border-r-0 rounded bg-gs-900 text-gs-100",
        { "studio-animate-bounce": addAnimationClass }
      )}
    >
      <div className="flex justify-between items-center p-2 sticky w-full">
        <Button
          size={ButtonSize.small}
          onClick={onRequestClose}
          fill={ButtonFill.clear}
          color={ButtonColor.grey}
          className="text-xs"
        >
          <FontAwesomeIcon icon={faTimes} />
          {t("controls.close-details")}
        </Button>
        <Button
          size={ButtonSize.small}
          onClick={onEditClick}
          fill={ButtonFill.clear}
          color={ButtonColor.grey}
          disabled={isElementRemoved}
          className="text-xs"
        >
          {t("controls.edit-element")}
        </Button>
      </div>
      {isElementRemoved ? (
        <div className="bg-amber-300 bg-opacity-20 mx-3 my-2 text-gs-100 flex justify-between items-center rounded border border-solid border-outline-500 p-2 text-s font-semibold">
          <div>{t("dp-editor.dp-editor-side-bar.deleted-message")}</div>
        </div>
      ) : null}
      <div
        className={classNames("flex-1 w-full overflow-y-auto", {
          "opacity-50": isElementRemoved,
        })}
      >
        {children}
      </div>
      <div className="p-2">
        <button
          onClick={onDeleteClick}
          className={classNames(
            "flex",
            "items-center",
            "justify-center",
            "bg-red-600",
            "text-white",
            "p-2",
            "font-semibold",
            "text-s",
            "border",
            "border-solid",
            "border-outline-500",
            "w-full",
            "cursor-pointer",
            "hover:bg-red-700",
            "rounded",
            "text-base",
            "leading-normal",
            { "opacity-50": isElementRemoved }
          )}
        >
          <FontAwesomeIcon icon={faTrash} className="mr-2" />
          {t("controls.remove-element")}
        </button>
      </div>
    </div>
  );
};

export default SideBar;
