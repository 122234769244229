import React, { useState } from "react";
import BaseModal from "src/components/BaseModal";
import { DemoAccountUserData, doApiCallback, findExternalNumber } from "../api";
import { getLocalAccount } from "../localStorage";
import { useForm } from "react-hook-form";
import FormGroup from "../../../components/FormGroup";
import { phoneNumberValidator } from "../RegistrationForm";

const CallbackPopup: React.FC<{
  opened: boolean;
  closeHandler: () => void;
  data: DemoAccountUserData;
}> = ({ opened, data, closeHandler }) => {
  const { username, password } = getLocalAccount();
  const [progress, setProgress] = useState<string|undefined>(undefined);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    phoneNumber: string;
  }>({
    defaultValues: {
      phoneNumber: '',
    },
  });

  const submitHandler = handleSubmit(async (formData) => {
    const phoneNumber = formData.phoneNumber.replaceAll(/[^0-9]+/g, "");

    if (!data || !data.externalNumber)
      return; //TODO

    if (!data.isRealExternalNumber) {
      alert('Your phone number is not a real number. Cannot do callback.');
      return;
    }

    // find id of the external number
    const extNumber = await findExternalNumber(
      username,
      password,
      data.externalNumber
    );
    console.log("Found external number", extNumber);

    setProgress('Starting your call...');

    await doApiCallback(username, password, extNumber.resourceId, phoneNumber);

    setProgress('Your call was queued.');
  });

  if (progress) {
    return (<BaseModal
      title={"Try out a real call with Compass"}
      onRequestClose={closeHandler}
      isOpen={opened}
      hideFooterButtons={true}
      buttons={[]}
    >
      {progress}
    </BaseModal>);
  }

  return (
    <BaseModal
      title={"Try out a real call with Compass"}
      onRequestClose={closeHandler}
      isOpen={opened}
      buttons={[
        { text: 'Call!', props: { onClick: submitHandler } },
      ]}
    >
      <div>
        <FormGroup
          header={'Phone number *'}
          error={errors.phoneNumber}
          htmlFor="id_phonenumber"
        >
          <input
            type="text"
            className="studio-form__control"
            id="id_phonenumber"
            {...register("phoneNumber", {
              ...phoneNumberValidator(true),
            })}
            placeholder={"+31 6 123456789"}
          />
        </FormGroup>
      </div>
    </BaseModal>
  );
};

export default CallbackPopup;
