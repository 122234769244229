import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DpElementDtmf } from "src/app/types/dialplans";
import BaseModal, { BaseModalProps } from "src/components/BaseModal";
import {
  dtmfDigitsValidator,
  nameFieldValidator,
  repeatValidator,
  requiredValidator,
  timeoutValidator,
} from "src/utils/form";
import styles from "./DtmfInputFormModal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { copyObject } from "src/utils";
import { usePrevious } from "src/utils/hooks";
import { Trans, useTranslation } from "react-i18next";
import Select from "src/components/Select";
import FormGroup from "src/components/FormGroup";
import PromptsSelect from "src/features/Prompts/PromptsSelect";

const DtmfInputFormModal: React.FC<
  BaseModalProps & {
    dpElement: DpElementDtmf;
    onRequestClose: (data?: { dpElement: DpElementDtmf }) => void;
  }
> = ({ onRequestClose, isOpen, dpElement }) => {
  const { t } = useTranslation();
  const [advancedSettingsOpened, setAdvancedSettingsOpened] =
    useState<boolean>(false);
  const toggleAdvancedSettings = useCallback(() => {
    setAdvancedSettingsOpened(!advancedSettingsOpened);
  }, [advancedSettingsOpened, setAdvancedSettingsOpened]);
  const form = useForm<{
    name: string;
    promptId: string;
    timeout: string;
    repeat: string;
    digits: string;
    variableLength: string;
  }>({
    defaultValues: {
      name: dpElement.name || "DTMF input",
      variableLength:
        dpElement.variableLength !== undefined
          ? String(dpElement.variableLength)
          : "false",
      digits: dpElement.digits ? dpElement.digits.toString() : "",
      repeat: dpElement.repeat?.toString() || "2",
      timeout: dpElement.timeout?.toString() || "10",
      promptId: dpElement.promptId?.toString(),
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    unregister,
  } = form;
  const watchVariableLength = watch("variableLength");
  const previousVariableLength = usePrevious(watchVariableLength);
  useEffect(() => {
    if (
      watchVariableLength !== previousVariableLength &&
      watchVariableLength === "true"
    ) {
      unregister("digits");
    }
  }, [watchVariableLength, previousVariableLength, unregister]);
  const submitHandler = handleSubmit((formData) => {
    const dtmfElement: DpElementDtmf = copyObject(dpElement);
    dtmfElement.name = formData.name;
    dtmfElement.promptId = formData.promptId
      ? parseInt(formData.promptId)
      : undefined;
    dtmfElement.timeout = parseInt(formData.timeout);
    dtmfElement.digits =
      formData.variableLength === "false" ? parseInt(formData.digits) : 0;
    dtmfElement.repeat = parseInt(formData.repeat);
    dtmfElement.variableLength = JSON.parse(formData.variableLength);
    onRequestClose({
      dpElement: dtmfElement,
    });
  });
  return (
    <BaseModal
      title={t("dp-editor.dtmf-input-form-modal.title")}
      onRequestClose={onRequestClose.bind(null, undefined)}
      isOpen={isOpen}
      buttons={[
        { text: t("controls.save"), props: { onClick: submitHandler } },
      ]}
    >
      <FormGroup
        header={`${t("form-labels.name")} *`}
        error={errors.name}
        htmlFor="id_name"
      >
        <input
          type="text"
          id="id_name"
          className="studio-form__control"
          {...register("name", {
            ...requiredValidator(),
            ...nameFieldValidator(true),
          })}
        />
      </FormGroup>
      <FormGroup header={`${t("form-labels.dtmf-input-length")} *`} size="m">
        <Select {...register("variableLength")}>
          <option value="false">
            {t("form-labels.dtmf-input-length-option-fixed")}
          </option>
          <option value="true">
            {t("form-labels.dtmf-input-length-option-variable")}
          </option>
        </Select>
      </FormGroup>
      {watchVariableLength === "false" ? (
        <FormGroup
          header={`${t("form-labels.dtmf-digits-number")} *`}
          error={errors.digits}
          htmlFor="id_digits"
          size="xs"
        >
          <input
            type="text"
            id="id_digits"
            step="0"
            className="studio-form__control"
            {...register("digits", {
              ...requiredValidator(),
              ...dtmfDigitsValidator(),
            })}
          />
        </FormGroup>
      ) : (
        <div className={styles.warning}>
          <Trans
            i18nKey="dp-editor.dtmf-input-form-modal.digits-warning"
            components={{ b: <b /> }}
          />
        </div>
      )}
      <FormGroup
        header={t("form-labels.voice-prompt-select")}
        error={errors.promptId}
        size="m"
      >
        <PromptsSelect name="promptId" form={form} />
      </FormGroup>
      <div
        className={styles.advancedSettings}
        data-opened={advancedSettingsOpened}
      >
        <div
          className={styles.advancedSettingsHeader}
          onClick={toggleAdvancedSettings}
        >
          {t("controls.advanced-settings")}
          <div className={styles.advancedSettingsHeaderIcon}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
        <div className={styles.advancedSettingsContent}>
          <FormGroup
            header={t("form-labels.repeats-number")}
            error={errors.repeat}
            htmlFor="id_repeat"
            size="xs"
          >
            <input
              type="text"
              className="studio-form__control"
              id="id_repeat"
              {...register("repeat", {
                ...requiredValidator(),
                ...repeatValidator(),
              })}
            />
          </FormGroup>
          <FormGroup
            header={t("form-labels.timeout-seconds")}
            error={errors.timeout}
            htmlFor="id_timeout"
            size="xs"
          >
            <input
              type="text"
              id="id_timeout"
              className="studio-form__control"
              {...register("timeout", {
                ...requiredValidator(),
                ...timeoutValidator(),
              })}
            />
          </FormGroup>
        </div>
      </div>
    </BaseModal>
  );
};

export default DtmfInputFormModal;
