export enum DpElementType {
  /* inline elements (are created inline when the dialplan is created / removed when the dialplan is removed) */
  busy = "busy",
  dtmfInput = "dtmfInput",
  hangup = "hangup",
  ivr = "ivr",
  numberRecognition = "numberRecognition",
  prefix = "prefix",
  timeSwitch = "timeSwitch",
  /* company-wide elements. should be created before creating a dialplan */
  conference = "conference",
  dpSwitch = "dpSwitch",
  forward = "forward",
  prompt = "prompt",
  queue = "queue",
  user = "user",
  voicemail = "voicemail",
}

export type DpElement = {
  type: DpElementType;
  // NOTE: for app internal use
  // filled manually
  // should not land to api post requests
  _temp?: {
    id?: string;
  };
};

export type DpElementBranch = {
  steps: DpElementCombined[];
  // NOTE: for app internal use
  // filled manually
  // should not land to api post requests
  _temp?: {
    id?: string;
  };
};

export type DpElementDtmf = DpElement & {
  name: string;
  promptId?: number;
  timeout: number;
  repeat: number;
  digits: number;
  variableLength: boolean;
};

export type DpElementIvr = DpElement & {
  name: string;
  promptId: number;
  timeout: number;
  repeat: number;
  branches: (DpElementBranch & {
    key: string;
  })[];
};

export type DpElementNumberRecognitionBranch = DpElementBranch & {
  name: string;
  numberPrefixes: string[];
  anonymous: boolean;
};

export type DpElementNumberRecognition = DpElement & {
  branches: DpElementNumberRecognitionBranch[];
  defaultSteps: DpElementCombined[];
};

export type DpElementPrefix = DpElement & {
  prefix: string;
};

export type DpElementTimeSwitchCondition = {
  timeRange?: string;
  weekDayRange?: string;
  monthDayRange?: string;
  monthRange?: string;
};

export type DpElementTimeSwitchBranch = DpElementBranch & {
  name: string;
  condition: DpElementTimeSwitchCondition;
};

export type DpElementTimeSwitch = DpElement & {
  branches: DpElementTimeSwitchBranch[];
};

export type DpElementResource = DpElement & {
  id: number;
  name: string;
};

export type DpElementIdentity = DpElement & {
  identityId?: number;
  userId: number;
  name: string;
};

export type DpElementDpSwitch = DpElement & {
  name: string;
  shortcode: number;
  currentSetting: number;
  settings: (DpElementBranch & {
    number: number;
    name: string;
  })[];
};

export type DpElementCombined =
  | DpElement
  | DpElementDtmf
  | DpElementIvr
  | DpElementNumberRecognition
  | DpElementPrefix
  | DpElementTimeSwitch
  | DpElementResource
  | DpElementIdentity
  | DpElementDpSwitch;
