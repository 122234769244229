import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DpElementDtmf } from "src/app/types/dialplans";
import PropertyDisplay from "src/components/PropertyDisplay";
import SideBar, { SideBarProps } from "src/components/SideBar";
import { useLoadDialplanElements } from "src/utils/hooks";

type DtmfSideBarProps = SideBarProps & {
  dpElement: DpElementDtmf;
};

const DtmfSideBar: React.FC<DtmfSideBarProps> = (props) => {
  const { t } = useTranslation();
  const { dpElement } = props;
  const { prompts } = useLoadDialplanElements();
  const detailsValues = useMemo(
    () => [
      {
        label: t("form-labels.type-of-input"),
        value: dpElement.variableLength
          ? t("form-labels.dtmf-input-length-option-variable")
          : t("form-labels.dtmf-input-length-option-fixed"),
      },
      {
        label: t("form-labels.voice-prompt-select"),
        value:
          prompts.find((prompt) => prompt.resourceId === dpElement.promptId)
            ?.name || t("controls.not-selected").toString(),
      },
      ...(dpElement.digits
        ? [
            {
              label: t("form-labels.dtmf-digits-number"),
              value: dpElement.digits,
            },
          ]
        : []),
      {
        label: t("form-labels.repeats-number"),
        value: dpElement.repeat,
      },
      {
        label: t("form-labels.timeout-seconds"),
        value: dpElement.timeout,
      },
    ],
    [dpElement, t, prompts]
  );
  return (
    <SideBar {...props}>
      <div className="px-3 py-2">
        <>
          <div className="text-l font-semibold mb-8">{dpElement.name}</div>
          {detailsValues.map((item, idx) => (
            <PropertyDisplay
              key={idx}
              title={item.label}
              value={item.value}
              className="mb-5"
            />
          ))}
        </>
      </div>
    </SideBar>
  );
};

export default DtmfSideBar;
