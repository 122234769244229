import classNames from "classnames";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { restApi } from "src/app/api";
import { DpElementDpSwitch } from "src/app/types/dialplans";
import Badge from "src/components/Badge";
import Button, {
  ButtonColor,
  ButtonFill,
  ButtonSize,
} from "src/components/Button";
import SideBar, { SideBarProps } from "src/components/SideBar";
import { updateDialPlanElement } from "src/features/DpEditor/dpEditorSlice";
import { copyObject } from "src/utils";
import { useLoadDialplanElements } from "src/utils/hooks";
import { handleApiError } from "src/utils/errorHandler";
import { AxiosError } from "axios";

type CallFlowSideBarProps = SideBarProps & {
  dpElement: DpElementDpSwitch;
};

const CallFlowSideBar: React.FC<CallFlowSideBarProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dpElement } = props;
  const { dpSwitches } = useLoadDialplanElements();
  const makeActiveHandler = useCallback(
    async (setting: number) => {
      if (!dpElement._temp?.id) {
        return;
      }
      const dpSwitch = dpSwitches.find(
        (item) => item.shortcode === dpElement.shortcode
      );
      if (dpSwitch && setting <= dpSwitch.settings.length) {
        try {
          await restApi.patch(`/dpSwitch/${dpSwitch.resourceId}`, {
            currentSetting: setting,
          });
        } catch (error) {
          handleApiError(undefined, error as AxiosError);
          return;
        }
      }
      // Element not saved yet, so just update dialplan
      const updatedDpElement = copyObject(dpElement);
      updatedDpElement.currentSetting = setting;
      dispatch(
        updateDialPlanElement({
          id: dpElement._temp.id,
          dpElement: updatedDpElement,
          isNew: false,
        })
      );
    },
    [dispatch, dpSwitches, dpElement]
  );
  return (
    <SideBar {...props}>
      <div className="px-3 py-2">
        <div className="text-l font-semibold mb-8">{dpElement.name}</div>
        <div className="text-lg leading-base text-gs-600 font-medium mb-2">
          {t("form-labels.shortcode")}
        </div>
        <div className="text-base leading-normal text-gs-100 font-medium mb-5">
          {dpElement.shortcode}
        </div>
        {dpElement.settings.map((item, index) => (
          <div
            key={item.number}
            className={classNames(
              "border border-r-0 border-l-0 border-solid border-gs-500 py-4",
              {
                "border-b-0": index < dpElement.settings.length - 1,
              }
            )}
          >
            <div>
              <div className="text-xs text-amber-500 flex items-center justify-between h-5">
                <div>
                  {t("controls.setting")} #{index + 1}
                </div>
                <div>
                  {dpElement.currentSetting === item.number ? (
                    <Badge color="green">{t("controls.active")}</Badge>
                  ) : (
                    <Button
                      size={ButtonSize.small}
                      fill={ButtonFill.clear}
                      color={ButtonColor.grey}
                      onClick={makeActiveHandler.bind(null, item.number)}
                    >
                      {t("controls.make-active")}
                    </Button>
                  )}
                </div>
              </div>
              <div className="font-medium text-base mt-2">
                {item.name || t("dp-editor.dp-element.unnamed-setting")}
              </div>
              <div className="mt-4">
                <div className="text-gs-700 text-s font-semibold">
                  {t("dp-editor.call-flow-side-bar.key-setting-label")}
                </div>
                <div className="mt-2 text-gs-200 text-s font-semibold">
                  {item.number}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </SideBar>
  );
};

export default CallFlowSideBar;
