export type ArrayElementType<Type> = Type extends Array<infer Item>
  ? Item
  : Type;

export enum DpModalType {
  timeSwitchBranchesForm = "timeSwitchBranchesForm",
  numberRecognitionBranchesForm = "numberRecognitionBranchesForm",
  ivrMenuForm = "ivrMenuForm",
  dpSwitchForm = "dpSwitchForm",
  dpSwitchNameForm = "dpSwitchNameForm",
  labelForm = "labelForm",
  dtmfForm = "dtmfForm",
  timeRoutingForm = "timeRoutingForm",
  numberRecognitionForm = "numberRecognitionForm",
}

export enum DpSideBarType {
  ivr = "ivr",
  numberRecognition = "numberRecognition",
  timeSwitch = "timeSwitch",
  callFlow = "callFlow",
  dtmf = "dtmf",
  label = "label",
}
