import React from "react";
import { AppMode, getAppMode } from "src/utils";
import App from "./App";
import EmbeddedDpEditorApp from "./EmbeddedDpEditorApp";
import DemoRegistrationApp from "./DemoRegistrationApp";

const EntryPoint: React.FC = () => {
  const appMode = getAppMode();
  switch (appMode) {
    case AppMode.compassDpEditor:
      return <EmbeddedDpEditorApp />;
    case AppMode.demoRegistration:
      return <DemoRegistrationApp />;
    default:
      return <App />;
  }
};

export default EntryPoint;
