import { RootState } from "src/app/rootReducer";
import {
  CompassExternalNumber,
  CompassResourceType,
} from "src/app/types/compass";
import { genericDataSliceFactory } from "src/utils/genericDataSliceFactory";
import { getOrderedResources } from "src/utils/resources";

const { slice, loadItemsThunk } =
  genericDataSliceFactory<CompassExternalNumber>(
    "externalNumbers",
    (companyId) =>
      getOrderedResources(companyId, CompassResourceType.externalNumber)
  );

export const loadExternalNumbers = loadItemsThunk;

export const selectExternalNumbersState = ({ externalNumbers }: RootState) =>
  externalNumbers;

export default slice.reducer;
