import React, { useEffect, useState } from "react";
import BaseModal from "src/components/BaseModal";
import { useHistory, useParams } from "react-router";
import { createDemoAccount } from "./api";
import { saveLocalAccount } from "./localStorage";
import { Ring } from '@uiball/loaders'
import { HELP_EMAIL } from "./utils";

const ConfirmEmailPage: React.FC = () => {

  const routeParams = useParams<{ token: string }>();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined);

  const doApiCall = async () => {
    try {
      const account = await createDemoAccount(routeParams.token);
      saveLocalAccount(account);
      history.push('/dashboard');
    } catch (e: any) {
      if (e.response && e.response.status) {
        if (e.response.status === 409) {
          // Unique value already used
          setErrorMessage("It seems your company name was already used for a demo. Please use the link in the email you received earlier.");
          return;
        }
      }
      console.warn("Could not create demo account", e);
      setErrorMessage("This link does not appear to be valid.");
    }
  }

  useEffect(() => {
    // wait a bit, let the user see our beautiful spinner
    window.setTimeout(() => doApiCall(), 5000);
  }, []);

  if (errorMessage) {
    return (
      <BaseModal
        title={'Something went wrong'}
        onRequestClose={() => {}}
        isOpen={true}
        hideCloseButton={true}
        buttons={[
        ]}
      >
        <div>{errorMessage}</div>
        <div className={"mt-4"}>If you need assistance, please contact <a href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</a></div>
      </BaseModal>
    );
  } else {
    // loading indicator
    return (
      <BaseModal
        title={'Starting demo...'}
        onRequestClose={() => {}}
        isOpen={true}
        hideCloseButton={true}
        buttons={[]}
      >
        <div className={"mb-8"}>
          <img className={"p-3"} src={"/taco.svg"} width={100} style={{
            animation: "rotatingTaco 2s infinite reverse"}} />
        </div>
        <div>We are creating your demo environment, hold on...</div>
      </BaseModal>
    );
  }
}

export default ConfirmEmailPage;
