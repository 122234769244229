import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./DropdownSelect.module.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";

const DropdownSelect: React.FC<{
  icon?: IconProp;
  value?: string;
  items: { value: string; title: string }[];
  onSelect?: (value: string) => void;
  active?: boolean;
}> = ({ icon, items, value, onSelect, active }) => {
  const selectedItem = useMemo(() => {
    return items.find((item) => item.value === value);
  }, [items, value]);
  const [dropdownOpened, setDropDownOpened] = useState<boolean>(false);
  const selectHandler = useCallback(
    (selectedValue: string) => {
      setDropDownOpened(false);
      if (onSelect) {
        onSelect(selectedValue);
      }
    },
    [onSelect]
  );
  const $wrapRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!dropdownOpened) {
      return;
    }
    const onDocumentClick = (e: MouseEvent) => {
      if (
        !$wrapRef ||
        !$wrapRef.current ||
        $wrapRef.current.contains(e.target as Node)
      ) {
        return;
      }
      setDropDownOpened(false);
    };
    document.addEventListener("click", onDocumentClick);
    return () => {
      document.removeEventListener("click", onDocumentClick);
    };
  }, [dropdownOpened]);
  return (
    <div
      className={`${styles.wrap} ${active ? styles["wrap--active"] : ""}`}
      ref={$wrapRef}
    >
      <div
        className={styles.button}
        onClick={setDropDownOpened.bind(null, !dropdownOpened)}
      >
        {icon ? (
          <span className={styles.buttonIconWrap}>
            <FontAwesomeIcon icon={icon} className={styles.buttonIcon} />
          </span>
        ) : null}
        {selectedItem ? selectedItem.title : "-"}
      </div>
      {dropdownOpened ? (
        <div className={styles.dropdown}>
          {items.map((item, idx) => (
            <div
              key={`${idx}-${value}`}
              className={styles.item}
              onClick={selectHandler.bind(null, item.value)}
            >
              <div className={styles.itemIconWrap}>
                {item.value === value ? (
                  <FontAwesomeIcon icon={faCheck} className={styles.itemIcon} />
                ) : null}
              </div>
              <div className={styles.itemTitle}>{item.title}</div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default DropdownSelect;
