import React from "react";
import { DpElementPrefix } from "src/app/types/dialplans";
import SideBar, { SideBarProps } from "src/components/SideBar";

export type LabelSideBarProps = SideBarProps & {
  dpElement: DpElementPrefix;
};

const LabelSideBar: React.FC<LabelSideBarProps> = (props) => {
  const { dpElement } = props;
  return (
    <SideBar {...props}>
      <div className="px-3 py-2">
        <div className="text-l font-semibold mb-8">{dpElement.prefix}</div>
      </div>
    </SideBar>
  );
};

export default LabelSideBar;
