import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";

const Select = React.forwardRef<
  HTMLSelectElement,
  React.InputHTMLAttributes<HTMLSelectElement> & { parentClassName?: string }
>((props, ref) => {
  return (
    <div
      className={classNames(
        "relative inline-block w-full",
        props.parentClassName
      )}
    >
      <select
        {...{
          ...props,
          className: classNames(
            props.className,
            "studio-form__control studio-form__control--no-appearance z-10 cursor-pointer w-full pr-10"
          ),
        }}
        ref={ref}
      >
        {props.children}
      </select>
      <FontAwesomeIcon
        className="absolute right-4 text-gs-100 text-opacity-90 top-1/2 -translate-y-1/2 z-20 pointer-events-none"
        style={{ fontSize: "13px" }}
        icon={faChevronDown}
      />
    </div>
  );
});

export default Select;
