import React from "react";
import {
  Switch,
  BrowserRouter as Router,
  Redirect,
  Route,
} from "react-router-dom";
import "./App.css";
import { useAppDispatch } from "./store";
import RegistrationForm from "../features/DemoRegistration/RegistrationForm";
import EmailSentForm from "../features/DemoRegistration/EmailSentForm";
import ConfirmEmailPage from "../features/DemoRegistration/ConfirmEmailPage";
import Dashboard from "../features/DemoRegistration/Dashboard";
import App from "./App";
import i18n from "../i18n";

const DemoRegistrationApp: React.FC = () => {
  const dispatch = useAppDispatch();
  // we have most texts only in english; don't translate random things (like form validation errors)
  // to dutch
  i18n.changeLanguage('en');

  return (
    <div className="App DemoRegistrationApp">
      <Router>
        <Switch>
          <Route path="/register/emailSent">
            <EmailSentForm isOpen={true} onRequestClose={() => {}} />
          </Route>
          <Route path="/register">
            <RegistrationForm isOpen={true} onRequestClose={() => {}} />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/confirmEmail/:token">
            <ConfirmEmailPage />
          </Route>
          <Route path="/studio">
            <App />
          </Route>
          <Route>
            <Redirect to="/register"></Redirect>
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default DemoRegistrationApp;
