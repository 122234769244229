import { RootState } from "src/app/rootReducer";
import { CompassResourceType, CompassVoicemail } from "src/app/types/compass";
import { genericDataSliceFactory } from "src/utils/genericDataSliceFactory";
import { getOrderedResources } from "src/utils/resources";

const { slice, loadItemsThunk } = genericDataSliceFactory<CompassVoicemail>(
  "voicemails",
  (companyId) => getOrderedResources(companyId, CompassResourceType.voicemail)
);

export const loadVoicemails = loadItemsThunk;

export const selectVoicemailsState = ({ voicemails }: RootState) => voicemails;

export default slice.reducer;
