import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import store from "./app/store";
import { I18nextProvider } from "react-i18next";
import "./theme/index.css";
import "rc-slider/assets/index.css";
import "./theme/form.css";
import "./theme/react-select.css";
import "./theme/animate.css";
import { getAppElementId, getAppMode } from "./utils";
import i18n from "./i18n";
import EntryPoint from "./app/EntryPoint";
import Modal from "react-modal";

Modal.setAppElement("#" + getAppElementId());

document.body.classList.add(`app-${getAppMode()}`);

if (!document.querySelector(".ReactSelectPortal")) {
  const $reactSelectPortal = document.createElement("div");
  $reactSelectPortal.classList.add("ReactSelectPortal");
  document.body.appendChild($reactSelectPortal);
}

const $tooltipPortalNode = document.createElement("div");
$tooltipPortalNode.classList.add("studio-tooltip-portal");
document.body.appendChild($tooltipPortalNode);

// Wrapper component to portal react-tooltips
export const TooltipPortal: React.FC = ({ children }) => {
  return ReactDOM.createPortal(children, $tooltipPortalNode);
};

ReactDOM.render(
  // NOTE: strict mode disabled to prevent findDOMNode warning from
  // rc-slider component
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <EntryPoint />
      </DndProvider>
    </Provider>
  </I18nextProvider>,
  // </React.StrictMode>,
  document.getElementById(getAppElementId())
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
