import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Modal, { Styles } from "react-modal";
import Button, {
  ButtonColor,
  ButtonFill,
  ButtonProps,
  ButtonSize,
} from "src/components/Button";
import { getAppElementId } from "src/utils";
import styles from "./BaseModal.module.css";
import { merge } from "lodash";
import classNamesBind from "classnames/bind";

const classNames = classNamesBind.bind(styles);

const customModalStyles: Styles = {
  content: {
    width: "70%",
    maxWidth: "800px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    overflow: "hidden",
    transform: "translate(-50%, -50%)",
    backgroundColor: `var(--app-color-background)`,
    borderColor: "#ffffff1a",
    padding: "32px",
    position: "relative",
    boxSizing: "border-box",
  },
  overlay: {
    background: "rgba(0, 0, 0, .5)",
  },
};

export type ModalButton = {
  text: string;
  props?: ButtonProps;
};

export type BaseModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  closeButtonText?: string;
  title?: string;
  buttons?: ModalButton[];
  hideCloseButton?: boolean;
  hideFooterButtons?: boolean;
  modalStyles?: Styles;
  isWizard?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  titleElem?: ReactNode;
};

const BaseModal: React.FC<BaseModalProps> = ({
  isOpen,
  onRequestClose,
  closeButtonText,
  title,
  children,
  buttons,
  hideCloseButton,
  hideFooterButtons,
  modalStyles,
  isWizard,
  shouldCloseOnOverlayClick,
  titleElem,
}) => {
  const { t } = useTranslation();
  const footerButtons = useMemo<BaseModalProps["buttons"]>(
    () => [
      ...(buttons || []),
      ...(!hideCloseButton
        ? [
            {
              text: closeButtonText ? closeButtonText : t("controls.close"),
              props: {
                fill: ButtonFill.clear,
                color: ButtonColor.grey,
                onClick: onRequestClose,
              },
            },
          ]
        : []),
    ],
    [buttons, t, onRequestClose, hideCloseButton, closeButtonText]
  );
  const appElementId = useMemo(() => getAppElementId(), []);
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        shouldCloseOnOverlayClick={
          // NOTE: don't hide modal on outside click by default (#79)
          shouldCloseOnOverlayClick === undefined
            ? false
            : shouldCloseOnOverlayClick
        }
        style={merge(customModalStyles, modalStyles || {})}
        data={{
          app: appElementId,
        }}
      >
        <div
          className={
            "absolute top-0 left-0 w-full flex items-center justify-between h-8 mt-8 px-8"
          }
        >
          <div className="text-l text-gs-100 text-opacity-90 font-bold mr-6">
            {titleElem || title}
          </div>
          {!hideCloseButton ? (
            <Button
              className={classNames("closeBtn")}
              onClick={onRequestClose}
              fill={ButtonFill.clear}
              color={ButtonColor.grey}
              iconOnly={true}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          ) : null}
        </div>
        <div
          className={classNames(
            "body",
            { "mb-12": !hideFooterButtons && !!footerButtons?.length },
            isWizard ? "overflow-hidden" : "overflow-y-auto pb-8",
            "pr-4 mt-16 relative"
          )}
          tabIndex={-1}
        >
          {children}
        </div>
        {!hideFooterButtons && footerButtons?.length ? (
          <div
            className={"absolute bottom-0 left-0 w-full flex px-8 mb-8 h-10"}
          >
            {footerButtons.map((button, idx) => (
              <Button
                key={idx}
                {...{ size: ButtonSize.large, ...button.props }}
                className="mr-3"
              >
                {button.text}
              </Button>
            ))}
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default BaseModal;
