import { DemoAccount } from "./api";

const LOCAL_STORAGE_DEMO_ACCOUNT = 'demoAccount';

export function saveLocalAccount(account: DemoAccount) {

  // TODO: use our standard 'encryption' to store passwords a bit obfuscation
  const username = account.username;
  const password = account.password;

  window.localStorage.setItem(LOCAL_STORAGE_DEMO_ACCOUNT, JSON.stringify(account));
}

export function getLocalAccount(): DemoAccount {
  const storedData = window.localStorage.getItem(LOCAL_STORAGE_DEMO_ACCOUNT);
  if (!storedData) {
    if (new URLSearchParams(window.location.search).get("fakeaccount") !== null) {
      return { username: 'test', password: 'test' };
    }
    throw new Error("No local account");
  }

  return JSON.parse(storedData) as DemoAccount;
}
