import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  DpElementTimeSwitch,
  DpElementTimeSwitchCondition,
} from "src/app/types/dialplans";
import BaseModal, { BaseModalProps } from "src/components/BaseModal";
import { Range, RangeProps } from "rc-slider";
import styles from "./TimeRoutingFormModal.module.css";
import { copyObject, objectFlip } from "src/utils";
import { nameFieldValidator } from "src/utils/form";
import { useTranslation } from "react-i18next";
import {
  minutesToTimeString,
  timeStringToMinutes,
  timeSwitchMonthDaysDisplay,
  timeSwitchMonthsDisplay,
  timeSwitchWeekDaysDisplay,
  timeSwitchTimeDisplay,
} from "src/utils/datetime";
import { usePrevious } from "src/utils/hooks";
import FormGroup from "src/components/FormGroup";

// number of minutes at 23:59, max value
const maxTimeRangeMinutes = 60 * 24 - 1;

const weekdaysMap: { [key: string]: string } = {
  0: "mon",
  1: "tue",
  2: "wed",
  3: "thu",
  4: "fri",
  5: "sat",
  6: "sun",
};
const monthsMap: { [key: string]: string } = {
  1: "jan",
  2: "feb",
  3: "mar",
  4: "apr",
  5: "may",
  6: "jun",
  7: "jul",
  8: "aug",
  9: "sep",
  10: "oct",
  11: "nov",
  12: "dec",
};

const arrayToTimeRangeString = (range: number[]): string | undefined => {
  if (range[0] === 0 && range[1] === maxTimeRangeMinutes) {
    return undefined;
  }
  // NOTE: reduce "to" time we send to API by 1 minute because of API behavior
  // reference: https://gitlab.iperitydev.com/compass/dialplan-editor/-/merge_requests/1#note_84027
  return `${minutesToTimeString(range[0])}-${minutesToTimeString(
    range[1] - 1
  )}`;
};
const timeRangeStringToArray = (rangeStr?: string): number[] => {
  if (!rangeStr) {
    return [0, maxTimeRangeMinutes];
  }
  const [fromStr, toStr] = rangeStr.split("-");
  // NOTE: add 1 minutes to "to" since in API we have reduced it by 1
  // reference: https://gitlab.iperitydev.com/compass/dialplan-editor/-/merge_requests/1#note_84027
  return [timeStringToMinutes(fromStr), timeStringToMinutes(toStr) + 1];
};

const arrayToWeekDaysRangeString = (range: number[]): string | undefined => {
  if (range[0] === 0 && range[1] === 6) {
    return undefined;
  }
  if (range[0] === range[1]) {
    return weekdaysMap[range[0]];
  }
  return `${weekdaysMap[range[0]]}-${weekdaysMap[range[1]]}`;
};
const weekDaysRangeStringToArray = (rangeStr?: string): number[] => {
  if (!rangeStr) {
    return [0, 6];
  }
  if (!rangeStr.includes("-")) {
    const weekday = parseInt(objectFlip(weekdaysMap)[rangeStr]);
    return [weekday, weekday];
  }
  return [
    parseInt(objectFlip(weekdaysMap)[rangeStr.split("-")[0]]),
    parseInt(objectFlip(weekdaysMap)[rangeStr.split("-")[1]]),
  ];
};

const arrayToMonthDaysRangeString = (range: number[]): string | undefined => {
  if (range[0] === 1 && range[1] === 12) {
    return undefined;
  }
  if (range[0] === range[1]) {
    return `${range[0]}`;
  }
  return `${range[0]}-${range[1]}`;
};
const monthDaysRangeStringToArray = (rangeStr?: string): number[] => {
  if (!rangeStr) {
    return [1, 31];
  }
  if (!rangeStr.includes("-")) {
    const monthDay = parseInt(rangeStr);
    return [monthDay, monthDay];
  }
  return [parseInt(rangeStr.split("-")[0]), parseInt(rangeStr.split("-")[1])];
};

const arrayToMonthsRangeString = (range: number[]): string | undefined => {
  if (range[0] === 1 && range[1] === 31) {
    return undefined;
  }
  if (range[0] === range[1]) {
    return `${monthsMap[range[0]]}`;
  }
  return `${monthsMap[range[0]]}-${monthsMap[range[1]]}`;
};
const monthsRangeStringToArray = (rangeStr?: string): number[] => {
  if (!rangeStr) {
    return [1, 12];
  }
  if (!rangeStr.includes("-")) {
    const month = objectFlip(monthsMap)[rangeStr];
    return [month, month];
  }
  return [
    parseInt(objectFlip(monthsMap)[rangeStr.split("-")[0]]),
    parseInt(objectFlip(monthsMap)[rangeStr.split("-")[1]]),
  ];
};

export const TimeRoutingForm: React.FC<{
  defaultValues?: DpElementTimeSwitchCondition;
  onChange?: (condition: DpElementTimeSwitchCondition) => void;
}> = ({ defaultValues, onChange }) => {
  const { t } = useTranslation();
  const rangeStyleProps: RangeProps = useMemo(
    () => ({
      trackStyle: [{ backgroundColor: `var(--color-main)`, height: "10px" }],
      handleStyle: [
        {
          borderColor: `var(--color-main)`,
          borderRadius: "2px",
          marginTop: "-2px",
        },
        {
          borderColor: `var(--color-main)`,
          borderRadius: "2px",
          marginTop: "-2px",
        },
      ],
      dotStyle: { borderColor: `#2a303e`, display: "none" },
      activeDotStyle: { borderColor: `var(--color-main)` },
      railStyle: { backgroundColor: `#2a303e`, height: "10px" },
      className: "studio-rc-slider",
    }),
    []
  );
  const [timeRange, setTimeRange] = useState<number[]>(
    defaultValues?.timeRange
      ? timeRangeStringToArray(defaultValues?.timeRange)
      : [0, maxTimeRangeMinutes]
  );
  const [weekDaysRange, setWeekDaysRange] = useState<number[]>(
    defaultValues?.weekDayRange
      ? weekDaysRangeStringToArray(defaultValues?.weekDayRange)
      : [0, 6]
  );
  const [monthDaysRange, setMonthDaysRange] = useState<number[]>(
    defaultValues?.monthDayRange
      ? monthDaysRangeStringToArray(defaultValues?.monthDayRange)
      : [1, 31]
  );
  const [monthsRange, setMonthsRange] = useState<number[]>(
    defaultValues?.monthRange
      ? monthsRangeStringToArray(defaultValues?.monthRange)
      : [1, 12]
  );

  const { setValue, watch } = useForm<{
    condition: DpElementTimeSwitchCondition;
  }>({
    defaultValues: {
      condition: {
        timeRange: defaultValues?.timeRange,
        weekDayRange: defaultValues?.weekDayRange,
        monthDayRange: defaultValues?.monthDayRange,
        monthRange: defaultValues?.monthRange,
      },
    },
  });

  const timeRangeValue = watch("condition.timeRange");
  const previousTimeRangeValue = usePrevious(timeRangeValue);

  const weekDayRangeValue = watch("condition.weekDayRange");
  const previousWeekDayRangeValue = usePrevious(weekDayRangeValue);

  const monthDayRangeValue = watch("condition.monthDayRange");
  const previousMonthDayRangeValue = usePrevious(monthDayRangeValue);

  const monthRangeValue = watch("condition.monthRange");
  const previousMonthRangeValue = usePrevious(monthRangeValue);

  useEffect(() => {
    if (
      !onChange ||
      (previousTimeRangeValue === undefined &&
        previousWeekDayRangeValue === undefined &&
        previousMonthDayRangeValue === undefined &&
        previousMonthRangeValue === undefined) ||
      (timeRangeValue === previousTimeRangeValue &&
        weekDayRangeValue === previousWeekDayRangeValue &&
        monthDayRangeValue === previousMonthDayRangeValue &&
        monthRangeValue === previousMonthRangeValue)
    ) {
      return;
    }
    onChange({
      timeRange: timeRangeValue,
      weekDayRange: weekDayRangeValue,
      monthDayRange: monthDayRangeValue,
      monthRange: monthRangeValue,
    });
  }, [
    onChange,
    timeRangeValue,
    weekDayRangeValue,
    monthDayRangeValue,
    monthRangeValue,
    previousTimeRangeValue,
    previousWeekDayRangeValue,
    previousMonthDayRangeValue,
    previousMonthRangeValue,
  ]);

  const localizedWeekdaysMap = useMemo(() => {
    let map: { [key: string]: string } = {};
    Object.keys(weekdaysMap).forEach((weekIdx) => {
      // NOTE: hint for the i18n extract functionality, do not remove
      /*
      t('datetime.weekdays.mon-short')
      t('datetime.weekdays.tue-short')
      t('datetime.weekdays.wed-short')
      t('datetime.weekdays.thu-short')
      t('datetime.weekdays.fri-short')
      t('datetime.weekdays.sat-short')
      t('datetime.weekdays.sun-short')
      */
      map[weekIdx] = t(`datetime.weekdays.${weekdaysMap[weekIdx]}-short`);
    });
    return map;
  }, [t]);
  const localizedMonthsMap = useMemo(() => {
    let map: { [key: string]: string } = {};
    Object.keys(monthsMap).forEach((monthIdx) => {
      // NOTE: hint for the i18n extract functionality, do not remove
      /*
      t('datetime.months.jan-short')
      t('datetime.months.feb-short')
      t('datetime.months.mar-short')
      t('datetime.months.apr-short')
      t('datetime.months.may-short')
      t('datetime.months.jun-short')
      t('datetime.months.jul-short')
      t('datetime.months.aug-short')
      t('datetime.months.sep-short')
      t('datetime.months.oct-short')
      t('datetime.months.nov-short')
      t('datetime.months.dec-short')
      */
      map[monthIdx] = t(`datetime.months.${monthsMap[monthIdx]}-short`);
    });
    return map;
  }, [t]);

  useEffect(() => {
    setValue("condition.timeRange", arrayToTimeRangeString(timeRange));
  }, [timeRange, setValue]);
  useEffect(() => {
    setValue(
      "condition.weekDayRange",
      arrayToWeekDaysRangeString(weekDaysRange)
    );
  }, [weekDaysRange, setValue]);
  useEffect(() => {
    setValue(
      "condition.monthDayRange",
      arrayToMonthDaysRangeString(monthDaysRange)
    );
  }, [monthDaysRange, setValue]);
  useEffect(() => {
    setValue("condition.monthRange", arrayToMonthsRangeString(monthsRange));
  }, [monthsRange, setValue]);

  const timeValueDisplay = useMemo(
    () => timeSwitchTimeDisplay(arrayToTimeRangeString(timeRange)),
    [timeRange]
  );
  const weekDaysValueDisplay = useMemo(
    () => timeSwitchWeekDaysDisplay(arrayToWeekDaysRangeString(weekDaysRange)),
    [weekDaysRange]
  );
  const monthDaysValueDisplay = useMemo(
    () =>
      timeSwitchMonthDaysDisplay(arrayToMonthDaysRangeString(monthDaysRange)),
    [monthDaysRange]
  );
  const monthsValueDisplay = useMemo(
    () => timeSwitchMonthsDisplay(arrayToMonthsRangeString(monthsRange)),
    [monthsRange]
  );

  return (
    <>
      <FormGroup
        className={styles.rangeGroup}
        headerClassName={styles.rangeLabelWrap}
        header={`${t("form-labels.time")}: ${timeValueDisplay}`}
      >
        <div className={styles.rangeWrap}>
          <Range
            {...rangeStyleProps}
            step={15}
            pushable={true}
            min={0}
            max={maxTimeRangeMinutes}
            marks={{
              0: "00:00",
              360: "06:00",
              720: "12:00",
              1080: "18:00",
              1440: "00:00",
            }}
            value={timeRange}
            onChange={setTimeRange}
          />
        </div>
      </FormGroup>
      <FormGroup
        className={styles.rangeGroup}
        headerClassName={styles.rangeLabelWrap}
        header={`${t("form-labels.day-of-the-week")}: ${weekDaysValueDisplay}`}
      >
        <div className={styles.rangeWrap}>
          <Range
            {...rangeStyleProps}
            allowCross={true}
            step={1}
            min={0}
            max={6}
            marks={localizedWeekdaysMap}
            value={weekDaysRange}
            onChange={setWeekDaysRange}
          />
        </div>
      </FormGroup>
      <FormGroup
        className={styles.rangeGroup}
        headerClassName={styles.rangeLabelWrap}
        header={`${t(
          "form-labels.day-of-the-month"
        )}: ${monthDaysValueDisplay}`}
      >
        <div className={styles.rangeWrap}>
          <Range
            {...rangeStyleProps}
            allowCross={true}
            step={1}
            min={1}
            max={31}
            marks={{
              1: "1",
              6: "6",
              11: "11",
              16: "16",
              21: "21",
              26: "26",
              31: "31",
            }}
            value={monthDaysRange}
            onChange={setMonthDaysRange}
          />
        </div>
      </FormGroup>
      <FormGroup
        className={styles.rangeGroup}
        headerClassName={styles.rangeLabelWrap}
        header={`${t("form-labels.month")}: ${monthsValueDisplay}`}
      >
        <div className={styles.rangeWrap}>
          <Range
            {...rangeStyleProps}
            allowCross={true}
            step={1}
            min={1}
            max={12}
            marks={localizedMonthsMap}
            value={monthsRange}
            onChange={setMonthsRange}
          />
        </div>
      </FormGroup>
    </>
  );
};

const TimeRoutingFormModal: React.FC<
  BaseModalProps & {
    dpElement: DpElementTimeSwitch;
    branch: number;
    onRequestClose: (data?: { dpElement: DpElementTimeSwitch }) => void;
  }
> = ({ onRequestClose, isOpen, dpElement, branch }) => {
  const { t } = useTranslation();
  const name = useMemo(
    () => dpElement.branches[branch].name,
    [dpElement, branch]
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{
    name: string;
    condition: DpElementTimeSwitchCondition;
  }>({
    defaultValues: {
      name,
    },
  });

  const timeRoutingChangeHandler = useCallback(
    (condition: DpElementTimeSwitchCondition) => {
      setValue("condition", condition);
    },
    [setValue]
  );

  const submitHandler = handleSubmit((formData) => {
    if (!dpElement) {
      return onRequestClose();
    }
    const timeSwitchElement: DpElementTimeSwitch = copyObject(dpElement);
    timeSwitchElement.branches[branch] = {
      ...timeSwitchElement.branches[branch],
      ...formData,
    };
    onRequestClose({
      dpElement: timeSwitchElement,
    });
  });

  return (
    <BaseModal
      title={t("dp-editor.time-routing-form-modal.title", {
        name,
      })}
      onRequestClose={onRequestClose.bind(null, undefined)}
      isOpen={isOpen}
      buttons={[
        { text: t("controls.save"), props: { onClick: submitHandler } },
      ]}
    >
      <FormGroup
        header={`${t("form-labels.name")} *`}
        error={errors.name}
        htmlFor="id_name"
      >
        <input
          type="text"
          className="studio-form__control"
          id="id_name"
          {...register("name", {
            ...nameFieldValidator(),
          })}
        />
      </FormGroup>
      <TimeRoutingForm
        defaultValues={dpElement.branches[branch]?.condition}
        onChange={timeRoutingChangeHandler}
      />
    </BaseModal>
  );
};

export default TimeRoutingFormModal;
