import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faExclamation } from "@fortawesome/pro-solid-svg-icons/faExclamation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nanoid } from "nanoid";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./TagsInput.module.css";
import classNamesBind from "classnames/bind";
import { usePrevious } from "src/utils/hooks";
import { useTranslation } from "react-i18next";

const classNames = classNamesBind.bind(styles);

type Tag = {
  value: string;
  label: string;
  id: string;
  isValid: boolean;
};

const TagsInput: React.FC<{
  placeholder?: string;
  defaultValue?: string[];
  onChange?: (e: string[]) => void;
  valueToDisplayString?: (input: string) => string;
  displayStringToValue?: (input: string) => string;
  validator?: (input: string) => boolean;
  suggestions?: string[];
  suggestionsTitle?: string;
}> = ({
  placeholder,
  defaultValue,
  onChange,
  suggestions,
  suggestionsTitle,
  valueToDisplayString,
  displayStringToValue,
  validator,
}) => {
  const strToTag = useCallback(
    (str: string): Tag => {
      const cleanValue = displayStringToValue ? displayStringToValue(str) : str;
      const isValid = validator ? validator(cleanValue) : true;
      return {
        id: nanoid(),
        value: isValid ? cleanValue : str,
        label: isValid
          ? valueToDisplayString
            ? valueToDisplayString(cleanValue)
            : cleanValue
          : str,
        isValid,
      };
    },
    [displayStringToValue, valueToDisplayString, validator]
  );
  const { t } = useTranslation();
  const [tags, setTags] = useState<Tag[]>(
    defaultValue?.filter((item) => !!item).map((item) => strToTag(item)) || []
  );
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const $inputRef = useRef<HTMLInputElement>(null);
  const [currentInputValue, setCurrentInputValue] = useState<string>("");
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentInputValue(e.currentTarget.value);
    },
    []
  );
  const previousTags = usePrevious(tags);
  const previousCurrentInputValue = usePrevious(currentInputValue);
  useEffect(() => {
    if (
      onChange &&
      (JSON.stringify(tags) !== JSON.stringify(previousTags) ||
        previousCurrentInputValue !== currentInputValue)
    ) {
      let notTaggedValue = currentInputValue;
      if (notTaggedValue[notTaggedValue.length - 1] === ",") {
        notTaggedValue = notTaggedValue.slice(0, notTaggedValue.length - 1);
      }
      onChange([
        ...tags.map((item) => item.value),
        ...(notTaggedValue ? [notTaggedValue] : []),
      ]);
    }
  }, [
    tags,
    onChange,
    previousTags,
    previousCurrentInputValue,
    currentInputValue,
  ]);
  const setInputValue = useCallback((value: string) => {
    const inputSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    )?.set;
    inputSetter?.call($inputRef?.current, value);
    const changeEvent = new Event("input", { bubbles: true });
    $inputRef?.current?.dispatchEvent(changeEvent);
  }, []);
  const [nextBackspaceValue, setNextBackspaceValue] = useState<string>("");
  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case ",":
        case "Enter":
          const value = e.currentTarget.value.split(",").join("");
          const tag = strToTag(value);
          if (tag.value && value.length >= 1) {
            setTags([...tags, tag]);
          }
          setInputValue("");
          break;
        case "Backspace":
          if (nextBackspaceValue) {
            setInputValue(nextBackspaceValue);
            setNextBackspaceValue("");
            $inputRef.current?.focus();
            $inputRef.current?.select();
          }
          break;
      }
    },
    [tags, setTags, setInputValue, nextBackspaceValue, strToTag]
  );
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Backspace" && !currentInputValue && tags.length) {
        const lastTag = tags[tags.length - 1];
        setTags(tags.slice(0, tags.length - 1));
        if ($inputRef.current) {
          $inputRef.current.value = lastTag.value;
          setNextBackspaceValue(lastTag.value);
        }
      }
    },
    [tags, setTags, currentInputValue, setNextBackspaceValue]
  );
  const removeTag = useCallback(
    (id: string) => {
      setTags(tags.filter((item) => item.id !== id));
    },
    [tags, setTags]
  );
  const wrapperClickHandler = useCallback(() => {
    $inputRef.current?.focus();
  }, []);
  const inputFocusHandler = useCallback(() => {
    setIsFocused(true);
  }, []);
  const inputBlurHandler = useCallback(() => {
    setIsFocused(false);
  }, []);
  const visibleSuggestions = useMemo(() => {
    if (!suggestions) {
      return [];
    }
    return suggestions
      .filter((suggestion) => !tags.find((tag) => tag.value === suggestion))
      .map(strToTag);
  }, [tags, suggestions, strToTag]);
  const addSuggestionHandler = useCallback(
    (suggestion: Tag) => {
      setTags([...tags, suggestion]);
    },
    [tags]
  );
  const suggestionKeyDownHandler = (
    e: React.KeyboardEvent<HTMLButtonElement>
  ) => {
    if (e.key !== "Enter") {
      return;
    }
    setTimeout(() => {
      $inputRef?.current?.focus();
    }, 100);
  };
  const handleInputPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      const accumulatedValue = `${
        e.currentTarget.value
      }${e.clipboardData.getData("Text")}`;
      if (!accumulatedValue.includes(",")) {
        return;
      }
      setTags([
        ...tags,
        ...accumulatedValue
          .split(",")
          .filter((item) => !!item)
          .map(strToTag),
      ]);
      setTimeout(() => {
        setInputValue("");
      }, 100);
    },
    [tags, setInputValue, strToTag]
  );
  const inputWrapClickHandler = useCallback(() => {
    $inputRef?.current?.focus();
  }, []);
  return (
    <div>
      <div
        onClick={inputWrapClickHandler}
        className={classNames(
          "tagsInputWrap",
          "p-2 border border-solid rounded-lg",
          isFocused ? "border-amber-300" : "border-outline-500"
        )}
      >
        <div
          className={classNames("flex flex-wrap")}
          onClick={wrapperClickHandler}
        >
          {tags.map((item) => (
            <div
              className={classNames(
                "group h-6 border border-outline-300 border-solid text-gs-100 text-opacity-90 mr-2 my-1 px-2 py-1 rounded-full text-s flex items-center cursor-pointer font-medium",
                item.isValid ? "bg-primary-700" : "bg-red-700 bg-opacity-50"
              )}
              key={item.id}
            >
              <div className="w-5">
                <FontAwesomeIcon
                  icon={item.isValid ? faCheck : faExclamation}
                  className="group-hover:hidden"
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className="hidden group-hover:block"
                  onClick={removeTag.bind(null, item.id)}
                />
              </div>
              {item.label}
            </div>
          ))}
          <input
            className={classNames(
              "input",
              "flex-1 h-6 bg-gs-1000 border-none focus:outline-none text-s py-1 my-1 min-w-0 text-gs-100"
            )}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            placeholder={placeholder}
            ref={$inputRef}
            onFocus={inputFocusHandler}
            onBlur={inputBlurHandler}
            onChange={handleInputChange}
            onPaste={handleInputPaste}
          />
        </div>
      </div>
      {visibleSuggestions.length ? (
        <div>
          <div className="text-gs-500 font-medium my-2 text-s">
            {suggestionsTitle || t("controls.suggestions")}
          </div>
          <div className="flex flex-wrap">
            {visibleSuggestions.map((suggestion, idx) => (
              <button
                key={suggestion.id}
                onClick={addSuggestionHandler.bind(null, suggestion)}
                onKeyDown={suggestionKeyDownHandler}
                className="border h-6 border-outline-300 border-solid bg-gs-900 text-gs-500 text-opacity-90 mr-2 my-1 px-2 py-1 rounded-full text-s flex items-center cursor-pointer font-medium studio-focusable"
              >
                {suggestion.label}
              </button>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TagsInput;
