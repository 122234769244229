import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getLocalAccount } from "./localStorage";
import { useAppDispatch } from "../../app/store";
import { useHistory } from "react-router";
import {
  formatPhoneNumber,
  getLocalUsername,
  HELP_EMAIL,
  HELP_PHONE_NR,
  HELP_PHONE_STR,
} from "./utils";
import cloudPhoneIcon from "src/images/icons/cloudphone-icon.png";
import contactIcon from "src/images/icons/contact-icon.png";
import studioIcon from "src/images/icons/studio-icon.png";
import navigatorIcon from "src/images/icons/navigator-icon.png";
import panelIcon from "src/images/icons/panel-icon.png";
import bridgeIcon from "src/images/icons/bridge-icon.png";
import talksomeIcon from "src/images/talksome-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import {
  COMPASS_BASEDOM,
  DemoAccountUserData,
  findExternalNumber,
  loadAccountData,
} from "./api";
import CloudSoftphonePopup from "./Popups/CloudSoftphonePopup";
import BaseModal from "src/components/BaseModal";
import CallbackPopup from "./Popups/CallbackPopup";

// proxy to https://bridge-feature-simplify-separate-envs.netlify.app
const BRIDGE_DOMAIN = "https://bridge.demo.cmpdev.com";
// proxy to https://panel-demodemo.netlify.app
const PANEL_DOMAIN = "https://panel.demo.cmpdev.com";

const openUrl = (url: string) => {
  console.log("Opening url: ", url);
  window.open(url, "_blank")?.focus();
};

enum MiddlewareModalType {
  studioCreateOrganization,
  studioDialPlan,
  softPhone,
  bridgeInteract,
  bridgeControl,
  navigator,
  panel,
  callback,
}

const Dashboard: React.FC = () => {
  const { username, password } = getLocalAccount();
  const [demoAccountData, setDemoAccountData] = useState<
    DemoAccountUserData | undefined
  >(undefined);
  const [openedModal, setOpenedModal] = useState<
    MiddlewareModalType | undefined
  >();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const fetchAccountData = async () => {
    try {
      const userData = await loadAccountData(username, password);
      console.log("Got user data: ", userData);
      setDemoAccountData(userData);
    } catch (e: any) {
      console.log("Could not get user data", e);
    }
  };

  useEffect(() => {
    fetchAccountData();
  }, [username]);

  const openDialplanEditor = async (demoAccountData?: DemoAccountUserData) => {
    let externalNumberId = undefined;

    // find id of the external number
    if (demoAccountData && demoAccountData.externalNumber) {
      const extNumber = await findExternalNumber(
        username,
        password,
        demoAccountData.externalNumber
      );
      console.log("Found external number", extNumber);
      externalNumberId = extNumber.resourceId;
    }

    if (!externalNumberId) {
      // no external number??
      // TODO: figure out company id, go to /did/index/entityid/{company_id}
      openStudio();
      return;
    }

    openStudio(`/did/dialplan/id/${externalNumberId}`);
  };

  const openStudio = (deeplink?: string) => {
    let pathParam = "";
    if (deeplink) {
      pathParam = "&goto=" + encodeURIComponent(deeplink);
    }
    const encodedPassword = encodeURIComponent(password);
    openUrl(
      `https://www.${COMPASS_BASEDOM}/sso/?username=${username}&password=${encodedPassword}${pathParam}`
    );
  };

  const openNavigator = () => {
    const encodedPassword = encodeURIComponent(password);
    const fullUsername = `${username}?${COMPASS_BASEDOM}`;
    openUrl(
      `https://navigator.demo.cmpdev.com/?username=${fullUsername}&password=${encodedPassword}`
    );
  };

  const openBridge = (openTutorial: boolean) => {
    const useLocalBridge =
      new URLSearchParams(window.location.search).get("use_local_bridge") !==
      null;
    const bridgeBase = useLocalBridge ? "http://localhost:3001" : BRIDGE_DOMAIN;

    let q;
    if (openTutorial) {
      q = "startTutorial";
    } else {
      const fullUsername = `${username}?${COMPASS_BASEDOM}`;
      q = `username=${encodeURIComponent(
        fullUsername
      )}&password=${encodeURIComponent(password)}`;
    }
    openUrl(`${bridgeBase}/?${q}`);
  };

  const openPanel = () => {
    const useLocalVersion =
      new URLSearchParams(window.location.search).get("use_local_panel") !==
      null;
    const panelBase = useLocalVersion ? "http://localhost:3001" : PANEL_DOMAIN;
    const fullUsername = `${username}?${COMPASS_BASEDOM}`;
    const q = `username=${encodeURIComponent(
      fullUsername
    )}&password=${encodeURIComponent(password)}`;
    openUrl(`${panelBase}/?${q}`);
  };

  const showPopup = (text: string) => {
    alert(text);
  };

  const blocks = useMemo<
    {
      badge: string;
      title: string;
      subTitle: string;
      warning?: string;
      image: string;
      clickHandler: () => void;
    }[]
  >(() => {
    return [
      {
        badge: "Compass Studio",
        title: "Create or edit your organization",
        subTitle: "To set up telephony platform",
        image: studioIcon,
        clickHandler: () =>
          setOpenedModal(MiddlewareModalType.studioCreateOrganization),
      },
      {
        badge: "Compass Studio",
        title: "Set up a dial plan of a phone number",
        subTitle: "To control incoming calls",
        image: studioIcon,
        clickHandler: () => setOpenedModal(MiddlewareModalType.studioDialPlan),
      },
      {
        badge: "Cloud Softphone",
        title: "Add a softphone to your account",
        subTitle: "To improve the experience",
        image: cloudPhoneIcon,
        clickHandler: () => setOpenedModal(MiddlewareModalType.softPhone),
      },
      {
        badge: "Compass Bridge",
        title: "Interact with the call centre app tutorial",
        subTitle: "To learn the basics",
        image: bridgeIcon,
        clickHandler: () => setOpenedModal(MiddlewareModalType.bridgeInteract),
      },
      {
        badge: "Compass Bridge",
        title: "Control your phone from desktop",
        subTitle: "And don’t use desk phone",
        warning: "Requires Cloud Softphone",
        image: bridgeIcon,
        clickHandler: () => setOpenedModal(MiddlewareModalType.bridgeControl),
      },
      {
        badge: "Compass Navigator",
        title: "Manage telephony from mobile phone",
        subTitle: "To easily control reachability",
        warning: "Requires Cloud Softphone",
        image: navigatorIcon,
        clickHandler: () => setOpenedModal(MiddlewareModalType.navigator),
      },
      {
        badge: "Compass Panel",
        title: "Monitor organization’s performance",
        subTitle: "To get call insights",
        image: panelIcon,
        clickHandler: () => setOpenedModal(MiddlewareModalType.panel),
      },
      {
        badge: "Telephony",
        title: "Test call",
        subTitle: "Test a call to your company",
        image: cloudPhoneIcon,
        clickHandler: () => setOpenedModal(MiddlewareModalType.callback),
      },
      {
        badge: "Get in touch",
        title: "Contact us to get full access",
        subTitle: `Via phone ${HELP_PHONE_STR}`,
        image: contactIcon,
        clickHandler: () => openUrl("https://www.talksome.com/contact"),
      },
    ];
  }, [demoAccountData]);

  return (
    <>
      <BaseModal
        isOpen={openedModal === MiddlewareModalType.studioCreateOrganization}
        title="Create an organization with Compass Studio"
        onRequestClose={setOpenedModal.bind(null, undefined)}
        modalStyles={{
          content: {
            maxWidth: "600px",
          },
        }}
        buttons={[
          { text: "Open Studio", props: { onClick: () => openStudio() } },
        ]}
      >
        <p className="text-s font-medium">
          Compass Studio is a graphical user interface for managing the entire
          telephony platform of an organization.{" "}
        </p>
        <p className="text-s font-medium">
          To enhance the experience, we have created a fictional company with
          pre-filled data.
        </p>
      </BaseModal>
      <BaseModal
        isOpen={openedModal === MiddlewareModalType.studioDialPlan}
        title="Set up a dial plan with Compass Studio"
        onRequestClose={setOpenedModal.bind(null, undefined)}
        modalStyles={{
          content: {
            maxWidth: "600px",
          },
        }}
        buttons={[
          {
            text: "Open Studio",
            props: { onClick: () => openDialplanEditor(demoAccountData) },
          },
        ]}
      >
        <p className="text-s font-medium">
          Compass Studio’s most distinctive feature is its drag-and-drop user
          interface for creating a call route.
        </p>
        <p className="text-s font-medium">
          We have created a fictional number and dial plan, you can experience
          the full functionality for yourself.
        </p>
      </BaseModal>
      <BaseModal
        isOpen={openedModal === MiddlewareModalType.bridgeInteract}
        title="Interact with Compass Bridge tutorial"
        onRequestClose={setOpenedModal.bind(null, undefined)}
        modalStyles={{
          content: {
            maxWidth: "600px",
          },
        }}
        buttons={[
          {
            text: "Open Compass Bridge",
            props: { onClick: () => openBridge(true) },
          },
        ]}
      >
        <p className="text-s font-medium">
          Compass Bridge is an application created for users who often have to
          manage their phone, queues and calls.
        </p>
        <p className="text-s font-medium">
          With our interactive tutorial, you can quickly learn the most common
          and relevant parts of Compass Bridge.
        </p>
      </BaseModal>
      <BaseModal
        isOpen={openedModal === MiddlewareModalType.bridgeControl}
        title="Make phone calls from your desktop with Bridge"
        onRequestClose={setOpenedModal.bind(null, undefined)}
        modalStyles={{
          content: {
            maxWidth: "600px",
          },
        }}
        buttons={[
          {
            text: "Open Compass Bridge",
            props: { onClick: () => openBridge(false) },
          },
        ]}
      >
        <p className="text-s font-medium">
          Compass Bridge is a contact centre app that allows the full control
          over a desk phone directly from the desktop. You can experience this
          for yourself!
        </p>
        <div className="flex items-center mt-6">
          <div
            className="rounded w-8 h-8 text-amber-400 flex justify-center items-center mr-2 text-s"
            style={{ background: "#45381D" }}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
          <div className="text-white text-opacity-70 font-medium text-s">
            Bridge requires Cloud Softphone to be set up in order to function
            properly.
          </div>
        </div>
      </BaseModal>
      <BaseModal
        isOpen={openedModal === MiddlewareModalType.navigator}
        title="Access your phone settings from anywhere, with Compass Navigator."
        onRequestClose={setOpenedModal.bind(null, undefined)}
        hideFooterButtons={false}
        buttons={[
          {
            text: "Open web version",
            props: { onClick: () => openNavigator() },
          },
        ]}
        modalStyles={{
          content: {
            maxWidth: "600px",
          },
        }}
      >
        <p className="text-s font-medium">
          Compass Navigator is a mobile companion to our desktop apps. You can
          easily adjust your availability and call route settings and from
          anywhere. The app is freely accessible from the Google Playstore and Apple App Store.
        </p>
        <p className="text-s font-medium">
          For your convenience, we have added the ability to click through the app from a browser.
        </p>
        <div className="flex items-center mt-6">
          <div
            className="rounded w-8 h-8 text-amber-400 flex justify-center items-center mr-2 text-s"
            style={{ background: "#45381D" }}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
          <div className="text-white text-opacity-70 font-medium text-s">
            Navigator requires Cloud Softphone to be set up in order to function
            properly.
          </div>
        </div>
      </BaseModal>
      <BaseModal
        isOpen={openedModal === MiddlewareModalType.panel}
        title="Monitor organization’s performance with Compass Panel"
        onRequestClose={setOpenedModal.bind(null, undefined)}
        modalStyles={{
          content: {
            maxWidth: "600px",
          },
        }}
        buttons={[
          {
            text: "Open Panel",
            props: { onClick: () => openPanel() },
          },
        ]}
      >
        <p className="text-s font-medium">
          Panel is a flexible and user-friendly web-based dashboard and wall
          display. It integrates with Compass, allowing managers and contact
          center agents to have a complete, real-time overview of the
          performance of a contact centre.
        </p>
      </BaseModal>
      {demoAccountData && (
        <CallbackPopup
          opened={openedModal === MiddlewareModalType.callback}
          closeHandler={setOpenedModal.bind(null, undefined)}
          data={demoAccountData}
        />
      )}
      {demoAccountData && (
        <CloudSoftphonePopup
          opened={openedModal === MiddlewareModalType.softPhone}
          closeHandler={setOpenedModal.bind(null, undefined)}
          data={demoAccountData}
        />
      )}
      <div
        className="fixed top-0 w-full border border-solid border-outline-500 h-11 flex justify-center items-center text-white text-opacity-90 text-s font-medium"
        style={{ backgroundColor: "#2D2A1F" }}
      >
        Contents of this demo are for demo purposes only - your data is purged
        after 14 days. Contact us for premium account.
      </div>
      <div className="mx-8 pt-20 pb-16">
        <img src={talksomeIcon} alt="Talksome" className="w-44 mb-4" />
        <div className="text-6xl font-bold mb-12">Compass Demo</div>
        <div className="flex mb-14">
          <div
            className="rounded-xl border border-solid border-outline-300 text-white text-opacity-90 font-medium px-4 py-3 mr-8"
            style={{ backgroundColor: "#182C2C" }}
          >
            <div className="mb-2">
              Compass Bridge, Navigator & panel credentials
            </div>
            <small>Username: </small>
            {username}
            <CopyToClipboard text={username}>
              <FontAwesomeIcon className="ml-2 cursor-pointer" icon={faCopy} />
            </CopyToClipboard>
            <br />
            <small>Password: </small>
            {password}
            <CopyToClipboard text={password}>
              <FontAwesomeIcon className="ml-2 cursor-pointer" icon={faCopy} />
            </CopyToClipboard>
            <br />
            {demoAccountData && (
              <div>
                <small>Your demo number:</small>{" "}
                {formatPhoneNumber(demoAccountData.externalNumber)}
              </div>
            )}
            {demoAccountData && demoAccountData.isRealExternalNumber && (
              <small>
                This is the number to reach your Compass demo account.
              </small>
            )}
            {demoAccountData && !demoAccountData.isRealExternalNumber && (
              <small>
                This number is not reachable. Contact {HELP_EMAIL} for a real
                phone number.
              </small>
            )}
          </div>
        </div>
        <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {blocks.map((item, index) => (
            <div key={index}>
              <div
                onClick={item.clickHandler}
                className="border border-solid border-outline-500 rounded px-8 py-4 w-full flex items-center bg-gs-900 cursor-pointer shadow
                  hover:bg-primary-700 hover:border-primary-400"
              >
                <img
                  src={item.image}
                  className="w-14 h-14 block mr-3"
                  alt={item.title}
                />
                <div>
                  <div className="text-xs px-2 rounded-full border border-solid border-outline-300 inline-block bg-primary-700 text-white text-opacity-70 font-medium mb-3 leading-5">
                    {item.badge}
                  </div>
                  <div className="text-s font-medium text-white text-opacity-90 mb-2">
                    {item.title}
                  </div>
                  <div className="text-xs leading-4 text-white text-opacity-70">
                    {item.subTitle}
                  </div>
                  {item.warning ? (
                    <div className="flex items-center mt-2">
                      <div
                        className="rounded w-6 h-6 text-amber-400 flex justify-center items-center mr-2 text-xs"
                        style={{ background: "#45381D" }}
                      >
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                      </div>
                      <div
                        className="text-white text-opacity-70 font-medium"
                        style={{ fontSize: "10px", letterSpacing: ".04em" }}
                      >
                        {item.warning}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
