import React, { useMemo } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SearchableSelect from "src/components/SearchableSelect";
import { requiredValidator } from "src/utils/form";
import { useLoadDialplanElements } from "src/utils/hooks";
import { Props } from "react-select";

const PromptsSelect: React.FC<{
  name: string;
  form: UseFormReturn<any>;
  selectProps?: Props;
}> = ({ name, form, selectProps }) => {
  const { t } = useTranslation();
  const { setValue, control, watch } = form;
  const { prompts } = useLoadDialplanElements();
  const promptsSelectOptions = useMemo(() => {
    return [
      {
        value: "",
        label: t("controls.not-selected"),
      },
      ...prompts.map((prompt) => ({
        value: prompt.resourceId.toString(),
        label: prompt.name,
      })),
    ];
  }, [prompts, t]);
  const selectedPromptId = watch("promptId");
  const promptsSelectValue = useMemo(
    () => promptsSelectOptions.find((item) => item.value === selectedPromptId),
    [promptsSelectOptions, selectedPromptId]
  );
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        ...requiredValidator(
          t("form-validation-errors.prompt-select-required")
        ),
      }}
      render={({ field }) => (
        <SearchableSelect
          {...{ ...field, value: promptsSelectValue }}
          {...selectProps}
          options={promptsSelectOptions}
          onChange={(option: any) => {
            setValue("promptId", option.value, {
              shouldValidate: true,
            });
          }}
        />
      )}
    />
  );
};

export default PromptsSelect;
