import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  nameFieldValidator,
  numberBetweenValidator,
  requiredValidator,
  dpShortCodeValidator,
} from "src/utils/form";
import { DpElementDpSwitch } from "src/app/types/dialplans";
import { copyObject } from "src/utils";
import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";
import Wizard, { WizardStep } from "src/components/Wizard";
import PropertyDisplay from "src/components/PropertyDisplay";
import FormGroup from "src/components/FormGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import classNames from "classnames";
import { usePrevious, useUsedShortCodes } from "src/utils/hooks";
import BaseModal from "src/components/BaseModal";

const CallFlowDetailsStep: React.FC<{
  onUpdate?: (formData: Partial<DpElementDpSwitch>, isValid: boolean) => void;
  dpElement: DpElementDpSwitch;
}> = ({ onUpdate, dpElement }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    getValues,
  } = useForm<{
    name: string;
    shortcode: string;
  }>({
    mode: "all",
    defaultValues: {
      name: dpElement.name,
      shortcode: dpElement.shortcode
        ? dpElement.shortcode.toString()
        : undefined,
    },
  });
  const previousIsValid = usePrevious(isValid);
  useEffect(() => {
    if (!onUpdate) {
      return;
    }
    if (previousIsValid !== isValid) {
      onUpdate(
        {
          name: getValues().name,
          shortcode: getValues().shortcode
            ? parseInt(getValues().shortcode)
            : undefined,
        },
        isValid
      );
    }
    const subscription = watch((data) =>
      onUpdate(
        {
          name: data.name,
          shortcode: data.shortcode ? parseInt(data.shortcode) : undefined,
        },
        isValid
      )
    );
    return () => subscription.unsubscribe();
  }, [watch, onUpdate, isValid, previousIsValid, getValues]);
  const { nextStep } = useWizard();
  const handleNextClick = handleSubmit((data) => {
    nextStep();
  });
  const usedDpShortCodes = useUsedShortCodes();
  return (
    <WizardStep
      buttons={[
        {
          text: t("controls.next-step"),
          props: {
            onClick: handleNextClick,
            disabled: !isValid,
          },
        },
      ]}
    >
      <FormGroup
        header={t("form-labels.call-flow-name")}
        error={errors.name}
        htmlFor="id_name"
      >
        <input
          type="text"
          autoFocus={true}
          id="id_name"
          className="studio-form__control"
          placeholder={t("form-labels.call-flow-name-placeholder")}
          {...register("name", {
            ...requiredValidator(
              t("form-validation-errors.name-field-required")
            ),
            ...nameFieldValidator(true),
          })}
        />
      </FormGroup>
      <FormGroup
        htmlFor="id_shortcode"
        header={t("form-labels.shortcode")}
        description={t("dp-editor.dp-switch-form-modal.shortcode-tooltip")}
        error={errors.shortcode}
        size="m"
      >
        <input
          id="id_shortcode"
          type="text"
          className="studio-form__control"
          {...register("shortcode", {
            ...dpShortCodeValidator(usedDpShortCodes),
          })}
        />
      </FormGroup>
    </WizardStep>
  );
};

const CallFlowConditionsStep: React.FC<{
  onUpdate?: (data: Partial<DpElementDpSwitch>, isValid: boolean) => void;
  dpElement: DpElementDpSwitch;
}> = ({ onUpdate, dpElement }) => {
  const { t } = useTranslation();
  const defaultBranches: { number: number; name: string }[] = dpElement.settings
    ?.length
    ? dpElement.settings
    : [
        { number: 1, name: "" },
        { number: 2, name: "" },
      ];
  const {
    register,
    formState: { errors, isValid },
    control,
    handleSubmit,
    getValues,
    watch,
  } = useForm<{
    branches: { number: number; name: string }[];
  }>({
    mode: "all",
    defaultValues: {
      branches: defaultBranches,
    },
  });
  const {
    register: registerAddSettings,
    formState: { errors: errorsAddSettings },
    watch: watchAddSettings,
  } = useForm<{
    addSettingsNumber: string;
  }>({
    mode: "all",
    defaultValues: {
      addSettingsNumber: "1",
    },
  });
  const previousIsValid = usePrevious(isValid);
  useEffect(() => {
    if (!onUpdate) {
      return;
    }
    if (previousIsValid !== isValid) {
      onUpdate(
        {
          settings:
            getValues().branches?.map((item) => ({
              ...item,
              steps: [],
            })) || [],
        },
        isValid
      );
    }
    const subscription = watch((data) => {
      onUpdate(
        {
          settings:
            data.branches?.map((item) => ({
              ...(item as {
                number: number;
                name: string;
              }),
              steps: [],
            })) || [],
        },
        isValid
      );
    });
    return () => subscription.unsubscribe();
  }, [watch, onUpdate, isValid, previousIsValid, getValues]);
  const addSettingsNumber = watchAddSettings("addSettingsNumber");
  const { nextStep } = useWizard();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "branches",
  });
  const handleNextClick = handleSubmit(() => {
    nextStep();
  });
  const addBranchHandler = useCallback(() => {
    const nextNumber = fields.length + parseInt(addSettingsNumber) - 1;
    for (let i = fields.length; i <= Math.min(nextNumber, 9); i++) {
      append({
        number: i === 9 ? 0 : i + 1,
        name: "",
      });
    }
  }, [append, fields, addSettingsNumber]);
  const previousFields = usePrevious(fields);
  useEffect(() => {
    if (previousFields && fields.length !== previousFields?.length) {
      fields.forEach((item, idx) => {
        update(idx, {
          name: item.name,
          number: idx === 9 ? 0 : idx + 1,
        });
      });
    }
  }, [fields, previousFields, update]);
  const addButtonDisabled =
    !!errorsAddSettings.addSettingsNumber || fields.length === 10;
  return (
    <WizardStep
      buttons={[
        {
          text: t("controls.next-step"),
          props: {
            autoFocus: true,
            onClick: handleNextClick,
            disabled: Object.keys(isValid).length > 0,
          },
        },
      ]}
    >
      <div
        style={
          fields?.length
            ? undefined
            : // NOTE: prevent flicker on step change
              { minHeight: `${104 * defaultBranches.length}px` }
        }
      >
        {fields.map((item, index) => (
          <FormGroup
            key={item.number}
            htmlFor={`id_name_${index}`}
            header={
              <div className="flex justify-between items-center">
                {t("form-labels.setting-name", {
                  number: index + 1,
                })}
                {fields.length > 2 ? (
                  <div
                    className="text-gs-700 cursor-pointer"
                    onClick={remove.bind(null, index)}
                  >
                    {t("controls.delete")}
                  </div>
                ) : null}
              </div>
            }
            error={
              errors.branches && errors.branches[index]
                ? errors.branches[index].name
                : undefined
            }
          >
            <input
              type="text"
              id={`id_name_${index}`}
              className="studio-form__control"
              placeholder={t("form-labels.setting-name-placeholder")}
              {...register(`branches.${index}.name`, {
                ...nameFieldValidator(false),
              })}
            />
          </FormGroup>
        ))}
      </div>
      <div className="flex items-center font-bold text-s">
        <button
          className={classNames(
            "rounded w-7 h-7 text-s border border-solid border-outline-300 outline-none bg-primary-500 text-white mr-2 studio-focusable",
            {
              "cursor-pointer": !addButtonDisabled,
              "opacity-50": addButtonDisabled,
            }
          )}
          disabled={addButtonDisabled}
          onClick={addBranchHandler}
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <span className="text-base leading-normal text-gs-100">
          {t("controls.add")}
        </span>
        <input
          className="text-white font-lg font-bold text-center border border-solid border-outline-300 rounded mx-2 h-8 w-10 bg-gs-1000 studio-focusable"
          {...registerAddSettings("addSettingsNumber", {
            ...numberBetweenValidator(1, 9),
          })}
        />
        <span className="text-base leading-normal text-gs-100">
          {t("dp-editor.call-flow-create-wizard.settings-label")}
        </span>
      </div>
    </WizardStep>
  );
};

const CallFlowFinishStep: React.FC<{
  onNext?: () => void;
  dpElement: DpElementDpSwitch;
}> = ({ onNext, dpElement }) => {
  const { t } = useTranslation();
  return (
    <WizardStep
      buttons={[
        {
          text: t("controls.save"),
          props: {
            onClick: onNext,
          },
        },
      ]}
    >
      <PropertyDisplay
        className="mb-2"
        title={t("form-labels.call-flow-name")}
        value={dpElement.name}
      />
      <PropertyDisplay
        title={t("form-labels.shortcode")}
        className="mb-2"
        value={dpElement.shortcode}
      />
      {dpElement.settings.map((item, idx) => (
        <PropertyDisplay
          key={item.number}
          className="mb-2"
          title={`${t("controls.setting")} #${idx + 1}`}
          value={
            item.name || t("dp-editor.dp-element.unnamed-setting").toString()
          }
        />
      ))}
    </WizardStep>
  );
};

const CallFlowCreateWizard: React.FC<{
  isOpen: boolean;
  dpElement: DpElementDpSwitch;
  onRequestClose: (data?: { dpElement: DpElementDpSwitch }) => void;
}> = ({ onRequestClose, isOpen, dpElement }) => {
  const { t } = useTranslation();
  const [createdDpElement, setCreatedDpElement] = useState({ ...dpElement });
  const [stepsState, setStepsState] = useState<{
    steps: {
      [key: string]: { visited: boolean };
    };
    activeStep: number;
  }>({ steps: { 0: { visited: true } }, activeStep: 0 });
  const [stepsValidState, setStepsValidState] = useState<{
    [key: string]: boolean;
  }>({});
  const { steps, activeStep } = stepsState;
  const activeStepChangeHandler = useCallback(
    (newActiveStep: number) => {
      setStepsState({
        activeStep: newActiveStep,
        steps: {
          ...steps,
          [newActiveStep]: {
            ...(steps[newActiveStep] || {}),
            visited: true,
          },
        },
      });
    },
    [steps, setStepsState]
  );
  const onStepUpdateHandler = useCallback(
    (
      stepNumber: number,
      data: Partial<DpElementDpSwitch>,
      isValid: boolean
    ) => {
      setStepsValidState({
        [stepNumber]: isValid,
      });
      setCreatedDpElement({
        ...createdDpElement,
        ...data,
      });
    },
    [createdDpElement]
  );
  const onFinishStepNextHandler = useCallback(() => {
    const dpSwitchElement = copyObject(createdDpElement);
    onRequestClose({
      dpElement: { ...dpSwitchElement, currentSetting: 1 },
    });
  }, [createdDpElement, onRequestClose]);
  const breadcrumbItems: { label: string; active?: boolean }[] = useMemo(() => {
    return [
      {
        label: t("controls.details"),
        active: stepsValidState[activeStep] && steps[0]?.visited,
      },
      {
        label: t("controls.settings"),
        active: stepsValidState[activeStep] && steps[1]?.visited,
      },
      {
        label: t("controls.finish"),
        active: stepsValidState[activeStep] && steps[2]?.visited,
      },
    ];
  }, [steps, activeStep, t, stepsValidState]);
  return (
    <BaseModal
      title={t("dp-editor.call-flow-create-wizard.title")}
      onRequestClose={onRequestClose.bind(null, undefined)}
      isOpen={isOpen}
      hideFooterButtons={true}
    >
      <Wizard
        breadcrumbItems={breadcrumbItems}
        onActiveStepChange={activeStepChangeHandler}
      >
        <CallFlowDetailsStep
          dpElement={createdDpElement}
          onUpdate={onStepUpdateHandler.bind(null, 0)}
        />
        <CallFlowConditionsStep
          dpElement={createdDpElement}
          onUpdate={onStepUpdateHandler.bind(null, 1)}
        />
        <CallFlowFinishStep
          dpElement={createdDpElement}
          onNext={onFinishStepNextHandler}
        />
      </Wizard>
    </BaseModal>
  );
};

export default CallFlowCreateWizard;
