import { RootState } from "src/app/rootReducer";
import { CompassExtension, CompassResourceType } from "src/app/types/compass";
import { genericDataSliceFactory } from "src/utils/genericDataSliceFactory";
import { getOrderedResources } from "src/utils/resources";

const { slice, loadItemsThunk } = genericDataSliceFactory<CompassExtension>(
  "extensions",
  (companyId) => getOrderedResources(companyId, CompassResourceType.extension)
);

export const loadExtensions = loadItemsThunk;

export const selectExtensionsState = ({ extensions }: RootState) => extensions;

export default slice.reducer;
