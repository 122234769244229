import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  loadExternalNumbers,
  selectExternalNumbersState,
} from "./externalNumbersSlice";
import styles from "./ExternalNumbers.module.css";
import { logout } from "src/features/Auth/authSlice";
import { loadPrompts } from "src/features/Prompts/promptsSlice";
import { useAppDispatch } from "src/app/store";
import { useHistory } from "react-router";
import { CompassExternalNumber } from "src/app/types/compass";

const ExternalNumbers: React.FC = () => {
  const dispatch = useAppDispatch();
  const { items: externalNumbers, isLoading } = useSelector(
    selectExternalNumbersState
  );
  useEffect(() => {
    dispatch(loadExternalNumbers());
    dispatch(loadPrompts());
  }, [dispatch]);
  const history = useHistory();
  const externalNumberClickHandler = useCallback(
    (externalNumber: CompassExternalNumber) => {
      history.push(`/externalNumbers/${externalNumber.resourceId}`);
    },
    [history]
  );
  const logoutClickHandler = useCallback(async () => {
    await dispatch(logout());
    history.push("/");
  }, [dispatch, history]);
  return (
    <>
      <div className={styles.header}>
        <button onClick={logoutClickHandler}>Logout</button>
      </div>
      <div className={styles.externalNumbers}>
        <div className={styles.list}>
          <div className={styles.listHeader}>ExternalNumbers</div>
          {isLoading ? (
            <div className={styles.loader}>Loading...</div>
          ) : (
            externalNumbers.map((item) => (
              <div
                key={item.resourceId}
                className={styles.listItem}
                onClick={externalNumberClickHandler.bind(null, item)}
              >
                {item.name}
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default ExternalNumbers;
