import React from "react";
import { useTranslation } from "react-i18next";
import Select, { Props } from "react-select";

const SearchableSelect = React.forwardRef<Select, Props>((props, ref) => {
  const { t } = useTranslation();
  return (
    <Select
      {...{
        menuPortalTarget: document.querySelector(
          ".ReactSelectPortal"
        ) as HTMLElement,
        menuPosition: "fixed",
        styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
        className: "studio-select",
        classNamePrefix: "studio-select",
        placeholder: t("controls.select-or-start-typing"),
        noOptionsMessage: () => t("controls.no-options-found"),
        ...props,
        innerRef: ref,
      }}
    />
  );
});

export default SearchableSelect;
