import { remove as removeDiacritics } from "diacritics";

// deep copy
export const copyObject = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

// deep compare
export const compareObjects = (obj1: any, obj2: any): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const reactIsInDevMode = () => {
  return process.env.NODE_ENV === "development";
};

export enum AppMode {
  default = "default",
  compassDpEditor = "compass-dp-editor",
  demoRegistration = "demo-registration",
}

export const getAppElementId = (): string => {
  // webinterface already uses 'container' for something else
  return getAppMode() === AppMode.compassDpEditor
    ? "dpeditor-container"
    : "studio-container";
};

export const getAppMode = (): AppMode => {
  switch (process.env.REACT_APP_MODE) {
    case "compass-dp-editor":
      return AppMode.compassDpEditor;
    case "demo-registration":
      return AppMode.demoRegistration;
    default:
      return AppMode.default;
  }
};

export const isEmbeddedMode = () => {
  return getAppMode() === AppMode.compassDpEditor;
};

export const objectFlip = (obj: any) => {
  const flipped: any = {};
  Object.keys(obj).forEach((key) => {
    flipped[obj[key]] = key;
  });
  return flipped;
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Encode unicode string to base64
 * SOURCE: https://stackoverflow.com/a/52647441
 * @param str - input string
 * @returns {string} - base64 string
 */
export const b64EncodeUnicode = (str: string) => {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode(Number(`0x${p1}`));
    })
  );
};

export const sortIgnoreCaseComparator = (a: string, b: string) => {
  a = removeDiacritics(a.toLowerCase());
  b = removeDiacritics(b.toLowerCase());
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }
  return 0;
};

/**
 * Sort list of objects by property
 * @param list list of items
 * @param prop sort property
 * @param comparator comparator function
 */
export const sortByProperty = <ListItemType, PropType>(
  list: ListItemType[],
  prop: string,
  comparator: (a: PropType, b: PropType) => number
): ListItemType[] => {
  return list.sort((a, b) => comparator((a as any)[prop], (b as any)[prop]));
};
