import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import { DRAGGABLE_TYPE } from "./consts";
import {
  DpElementCombined,
  DpElementDpSwitch,
  DpElementType,
} from "src/app/types/dialplans";
import styles from "./Element.module.css";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDpElementIcon, getDpElementTitle } from "src/utils/dialPlan";
import { usePrevious } from "src/utils/hooks";
import { compareObjects } from "src/utils";
import { useSelector } from "react-redux";
import { selectOpenedSideBarState } from "./dpEditorSlice";
import Badge from "src/components/Badge";
import { useTranslation } from "react-i18next";
import classNamesBind from "classnames/bind";

const classNames = classNamesBind.bind(styles);

export type ElementDialPlanPosition = {
  idx: number;
  path?: number[][];
};

export type ElementExtraProps = {
  branch?: number;
  dialPlanPosition?: ElementDialPlanPosition;
};

export type ElementDragItem = {
  dpElement: DpElementCombined;
  extra?: ElementExtraProps;
};

export enum ElementDropDirection {
  down = "down",
  up = "up",
}

export type ElementProps = {
  dpElement: DpElementCombined;
  className?: string;
  extra?: ElementExtraProps;
  draggable?: boolean;
  disabled?: boolean;
  onRemove?: (dpElement: DpElementCombined, extra?: ElementExtraProps) => void;
  onClick?: (dpElement: DpElementCombined, extra?: ElementExtraProps) => void;
  onDrop?: (item: ElementDragItem, direction: ElementDropDirection) => void;
  onDragStart?: (
    dpElement: DpElementCombined,
    extra?: ElementExtraProps
  ) => void;
  onDragEnd?: (dpElement: DpElementCombined, extra?: ElementExtraProps) => void;
  onChildrenToggleClick?: () => void;
  childrenOpened?: boolean;
  disableDrop?: ElementDropDirection[];
  /**
   * showUnderlay - show blue underlay under element
   * which means element has childs
   */
  showUnderlay?: boolean;
};

const Element: React.FC<ElementProps> = ({
  dpElement,
  draggable,
  disabled,
  onRemove,
  onClick,
  onDrop,
  extra,
  onDragStart,
  onDragEnd,
  onChildrenToggleClick,
  childrenOpened,
  className,
  showUnderlay,
  disableDrop,
}) => {
  const wrapRef = useRef<HTMLDivElement | null>(null);
  const [dropHoverDirection, setDropHoverDirection] =
    useState<ElementDropDirection>();
  const { t } = useTranslation();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: DRAGGABLE_TYPE,
    item: {
      dpElement,
      extra,
    } as ElementDragItem,
    canDrag: draggable === true && !disabled,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  const wasDragging = usePrevious(isDragging);
  const isDraggingRef = useRef(isDragging);
  useEffect(() => {
    isDraggingRef.current = isDragging;
    if (wasDragging === isDragging || wasDragging === undefined) {
      return;
    }
    if (onDragStart && isDragging) {
      return onDragStart(dpElement, extra);
    }
    if (onDragEnd && !isDragging) {
      return onDragEnd(dpElement, extra);
    }
  }, [dpElement, extra, isDragging, wasDragging, onDragEnd, onDragStart]);
  const openChildrenTimeout = useRef<NodeJS.Timer | null>(null);
  const [{ isCollapseDropOver }, collapseDrop] = useDrop({
    accept: DRAGGABLE_TYPE,
    collect: (monitor) => {
      return {
        isCollapseDropOver: monitor.isOver(),
      };
    },
    hover: () => {
      if (
        !openChildrenTimeout.current &&
        onChildrenToggleClick &&
        !childrenOpened
      ) {
        openChildrenTimeout.current = setTimeout(() => {
          if (onChildrenToggleClick) {
            onChildrenToggleClick();
            openChildrenTimeout.current = null;
          }
        }, 1000);
      }
    },
  });

  const [{ isOver }, drop] = useDrop({
    accept: DRAGGABLE_TYPE,
    collect: (monitor) => {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
    hover: (item: ElementDragItem, monitor) => {
      if (!wrapRef.current || isDragging) {
        return;
      }
      if (item.extra?.dialPlanPosition && extra?.dialPlanPosition) {
        // NOTE: don't allow to place object right above next one
        if (
          compareObjects(
            item.extra.dialPlanPosition.path,
            extra.dialPlanPosition.path
          ) &&
          extra.dialPlanPosition.idx - item.extra.dialPlanPosition.idx === 1
        ) {
          return;
        }
      }
      const hoverBoundingRect = wrapRef.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset() as XYCoord;
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const direction =
        hoverClientY < hoverMiddleY
          ? ElementDropDirection.up
          : ElementDropDirection.down;
      if (
        direction === dropHoverDirection ||
        disableDrop?.includes(direction)
      ) {
        return;
      }
      setDropHoverDirection(direction);
    },
    drop: (item: ElementDragItem) => {
      if (onDrop && dropHoverDirection) {
        onDrop(item, dropHoverDirection);
      }
      setDropHoverDirection(undefined);
    },
  });
  if (!!onDrop && !disabled) {
    drop(wrapRef);
  }
  useEffect(() => {
    if (!isOver && dropHoverDirection) {
      setDropHoverDirection(undefined);
    }
  }, [isOver, dropHoverDirection]);
  collapseDrop(wrapRef);
  useEffect(() => {
    if (!isCollapseDropOver && openChildrenTimeout.current) {
      clearTimeout(openChildrenTimeout.current);
      openChildrenTimeout.current = null;
    }
  }, [isCollapseDropOver]);
  const onRemoveHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      if (!onRemove) {
        return;
      }
      onRemove(dpElement, extra);
    },
    [onRemove, dpElement, extra]
  );
  const showActiveBadge = useMemo(() => {
    if (
      dpElement.type !== DpElementType.dpSwitch ||
      extra?.branch === undefined
    ) {
      return false;
    }
    const currentSetting = (dpElement as DpElementDpSwitch).currentSetting;
    // NOTE: Compass API returns currentSetting=0 for the 10th setting
    if (currentSetting === 0) {
      return extra.branch === 9;
    }
    return currentSetting === extra.branch + 1;
  }, [dpElement, extra]);
  const title = useMemo(
    () => getDpElementTitle(dpElement, extra),
    [dpElement, extra]
  );
  const icon = useMemo(() => getDpElementIcon(dpElement.type), [dpElement]);
  const openedSidebarState = useSelector(selectOpenedSideBarState);
  const isActive = useMemo(() => {
    if (!openedSidebarState || !extra?.dialPlanPosition) {
      return false;
    }
    return (
      openedSidebarState.branch === extra.branch &&
      openedSidebarState.id === dpElement?._temp?.id
    );
  }, [openedSidebarState, extra, dpElement?._temp?.id]);
  const wrapClassName = useMemo(() => {
    const cssClasses = [styles.wrap];
    if (disabled) {
      cssClasses.push(styles["wrap--disabled"]);
    }
    if (isActive) {
      cssClasses.push(styles["wrap--active"]);
    }
    if (dropHoverDirection) {
      cssClasses.push(styles[`wrap--dropbox-${dropHoverDirection}`]);
    }
    if (className) {
      cssClasses.push(className);
    }
    return cssClasses.join(" ");
  }, [disabled, dropHoverDirection, className, isActive]);
  const childrenToggleClickHandler = () => {
    if (onChildrenToggleClick) {
      onChildrenToggleClick();
    }
  };
  return (
    <div
      ref={wrapRef}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className={wrapClassName}
    >
      {showUnderlay ? <div className={styles.boxUnderlay}></div> : null}
      <div
        onClick={disabled ? undefined : onClick?.bind(null, dpElement, extra)}
        ref={drag}
        className={`${styles.box}${
          !!onClick || (draggable && !disabled)
            ? ` ${styles["box--clickable"]}`
            : ""
        }`}
      >
        <div
          className={classNames("icon", { "icon--active": showActiveBadge })}
        >
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className={styles.title}>
          <span className={styles.titleText}>{title}</span>
          {showActiveBadge ? (
            <Badge color="green">{t("controls.active")}</Badge>
          ) : null}
        </div>
        {onRemove && !disabled ? (
          <div className={styles.removeBtn} onClick={onRemoveHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        ) : null}
      </div>
      {onChildrenToggleClick ? (
        <div
          className={styles.arrowBtn}
          onClick={childrenToggleClickHandler}
          data-rotated={childrenOpened}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      ) : null}
    </div>
  );
};

export default Element;
