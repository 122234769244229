import React from "react";
import { useForm } from "react-hook-form";
import { DpElementDpSwitch } from "src/app/types/dialplans";
import { ModalFormAction } from "src/features/DpEditor/types";
import { copyObject } from "src/utils";
import {
  nameFieldValidator,
  numberBetweenValidator,
  dpShortCodeValidator,
} from "src/utils/form";
import BaseModal, { BaseModalProps } from "src/components/BaseModal";
import { useTranslation } from "react-i18next";
import { useUsedShortCodes } from "src/utils/hooks";
import FormGroup from "src/components/FormGroup";

const CallFlowFormModal: React.FC<
  BaseModalProps & {
    dpElement?: DpElementDpSwitch;
    onRequestClose: (data?: { dpElement: DpElementDpSwitch }) => void;
    action?: ModalFormAction;
  }
> = ({ onRequestClose, isOpen, dpElement }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    name: string;
    shortcode: string;
    settingsCount: string;
  }>({
    defaultValues: {
      name: dpElement?.name || t("dp-editor.dp-switch-form-modal.default-name"),
      shortcode: (dpElement?.shortcode || "").toString(),
      settingsCount: dpElement?.settings
        ? dpElement.settings.length.toString()
        : "2",
    },
  });
  const usedDpShortCodes = useUsedShortCodes(dpElement?.shortcode);
  const submitHandler = handleSubmit((formData) => {
    if (!dpElement) {
      return onRequestClose();
    }
    const dpSwitchElement: DpElementDpSwitch = copyObject(dpElement);
    dpSwitchElement.name = formData.name;
    dpSwitchElement.shortcode = parseInt(formData.shortcode);
    dpSwitchElement.currentSetting = 1;
    const settingsCount = parseInt(formData.settingsCount);
    const settings: DpElementDpSwitch["settings"] = [];
    for (let i = 0; i < settingsCount; i++) {
      settings.push(
        dpSwitchElement.settings && dpSwitchElement.settings[i]
          ? dpSwitchElement.settings[i]
          : {
              number: i === 9 ? 0 : i + 1,
              name: "",
              steps: [],
            }
      );
    }
    dpSwitchElement.settings = settings;
    onRequestClose({
      dpElement: dpSwitchElement,
    });
  });
  return (
    <BaseModal
      title={t("dp-editor.dp-switch-form-modal.title")}
      onRequestClose={onRequestClose.bind(null, undefined)}
      isOpen={isOpen}
      buttons={[
        { text: t("controls.save"), props: { onClick: submitHandler } },
      ]}
    >
      <FormGroup
        header={`${t("form-labels.dp-switch-name")} *`}
        error={errors.name}
        htmlFor={"id_name"}
      >
        <input
          type="text"
          className="studio-form__control"
          {...register("name", {
            ...nameFieldValidator(true),
          })}
          id="id_name"
        />
      </FormGroup>
      <FormGroup
        header={t("form-labels.shortcode")}
        error={errors.shortcode}
        htmlFor={"id_shortcode"}
        description={t("dp-editor.dp-switch-form-modal.shortcode-tooltip")}
        size="m"
      >
        <input
          type="text"
          className="studio-form__control"
          {...register("shortcode", {
            ...dpShortCodeValidator(usedDpShortCodes),
          })}
          id="id_shortcode"
        />
      </FormGroup>
      <FormGroup
        header={`${t("form-labels.dp-switch-number-of-settings")} *`}
        error={errors.settingsCount}
        htmlFor="id_settingsCount"
        size="xs"
      >
        <input
          type="text"
          className="studio-form__control"
          id="id_settingsCount"
          {...register("settingsCount", {
            ...numberBetweenValidator(2, 10),
          })}
        />
      </FormGroup>
    </BaseModal>
  );
};

export default CallFlowFormModal;
