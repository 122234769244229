import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "src/features/Auth/authSlice";
import dpEditorSlice from "src/features/DpEditor/dpEditorSlice";
import extensionsSlice from "src/features/Extensions/extensionsSlice";
import externalNumbersSlice from "src/features/ExternalNumbers/externalNumbersSlice";
import forwardsSlice from "src/features/Forwards/forwardsSlice";
import conferencesSlice from "src/features/Conferences/conferencesSlice";
import usersSlice from "src/features/Users/usersSlice";
import promptsSlice from "src/features/Prompts/promptsSlice";
import voicemailsSlice from "src/features/Voicemails/voicemailsSlice";
import queuesSlice from "src/features/Queues/queuesSlice";
import dpSwitchesSlice from "src/features/DpSwitches/dpSwitchesSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  dpEditor: dpEditorSlice,
  extensions: extensionsSlice,
  forwards: forwardsSlice,
  conferences: conferencesSlice,
  users: usersSlice,
  prompts: promptsSlice,
  voicemails: voicemailsSlice,
  queues: queuesSlice,
  externalNumbers: externalNumbersSlice,
  dpSwitches: dpSwitchesSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
