// TODO: move to different directory

/**
 * From a username?cmpenv.com full username, return only the username part
 * @param fullUsername
 */
export function getLocalUsername(fullUsername: string) {
  return fullUsername.split('?')[0];
}

export const HELP_EMAIL = 'hello@talksome.com';
export const HELP_PHONE_NR = '31885552111';
export const HELP_PHONE_STR = '+31 88 555 2111';

export function formatPhoneNumber(number: string) {

  if (number.length === 11 && number.startsWith('31')) {
    return '+' + number.substring(0, 2) + ' ' + number.substring(2, 4) + ' ' +
      number.substring(4, 7) + ' ' + number.substring(7, 11);
  } else {
    // unknown format?
    return number;
  }

}
