import React, { useEffect } from "react";
import { FieldError, useForm } from "react-hook-form";
import { getDpElementTitle } from "src/utils/dialPlan";
import {
  nameFieldValidator,
  repeatValidator,
  requiredValidator,
  timeoutValidator,
} from "src/utils/form";
import { DpElementIvr } from "src/app/types/dialplans";
import BaseModal, { BaseModalProps } from "src/components/BaseModal";
import styles from "./IvrMenuFormModal.module.css";
import { copyObject } from "src/utils";
import { ArrayElementType } from "src/app/types";
import { useTranslation } from "react-i18next";
import Checkbox from "src/components/Checkbox";
import FormGroup from "src/components/FormGroup";
import PromptsSelect from "src/features/Prompts/PromptsSelect";

const keys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];

const IvrMenuFormModal: React.FC<
  BaseModalProps & {
    dpElement: DpElementIvr;
    onRequestClose: (data?: { dpElement: DpElementIvr }) => void;
  }
> = ({ onRequestClose, isOpen, dpElement }) => {
  const { t } = useTranslation();
  const form = useForm<{
    name: string;
    promptId: string;
    timeout: string;
    repeat: string;
    branches: {
      [key: string]: boolean;
    };
  }>({
    defaultValues: {
      name: getDpElementTitle(dpElement),
      promptId: dpElement.promptId.toString(),
      timeout: dpElement.timeout.toString(),
      repeat: dpElement.repeat.toString(),
      branches: (() => {
        const branches: { [key: string]: boolean } = {};
        Object.values(dpElement.branches).forEach((branch) => {
          branches[branch.key] = true;
        });
        return branches;
      })(),
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;
  useEffect(() => {
    register("branches", {
      validate: (branches) => {
        const isValid = !!Object.values(branches).find((item) => item === true);
        if (!isValid) {
          return t("form-validation-errors.ivr-key-required") as string;
        }
        return isValid;
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const submitHandler = handleSubmit((formData) => {
    if (!dpElement) {
      return onRequestClose();
    }
    const ivrElement = copyObject(dpElement);
    ivrElement.name = formData.name;
    ivrElement.promptId = parseInt(formData.promptId);
    ivrElement.timeout = parseInt(formData.timeout);
    ivrElement.repeat = parseInt(formData.repeat);
    ivrElement.branches = [];
    Object.keys(formData.branches).forEach((key) => {
      if (!formData.branches[key]) {
        return;
      }
      const currentSteps = dpElement.branches
        ? dpElement.branches.find((item) => item.key === key)?.steps
        : undefined;
      const branch: ArrayElementType<DpElementIvr["branches"]> = {
        key,
        steps: [],
      };
      if (currentSteps) {
        branch.steps = currentSteps;
      }
      ivrElement.branches.push(branch);
    });
    onRequestClose({
      dpElement: ivrElement,
    });
  });
  return (
    <BaseModal
      title={t("dp-editor.ivr-menu-form-modal.title")}
      onRequestClose={onRequestClose.bind(null, undefined)}
      isOpen={isOpen}
      buttons={[
        { text: t("controls.save"), props: { onClick: submitHandler } },
      ]}
    >
      <FormGroup
        header={`${t("form-labels.ivr-name")} *`}
        error={errors.name}
        htmlFor="id_name"
      >
        <input
          type="text"
          className="studio-form__control"
          id="id_name"
          {...register("name", {
            ...requiredValidator(
              t("form-validation-errors.name-field-required")
            ),
            ...nameFieldValidator(true),
          })}
        />
      </FormGroup>
      <FormGroup
        header={`${t("form-labels.voice-prompt-select")} *`}
        error={errors.promptId}
      >
        <PromptsSelect name="promptId" form={form} />
      </FormGroup>
      <FormGroup
        header={`${t("form-labels.repeats-number")} *`}
        error={errors.repeat}
        htmlFor="id_repeat"
        size="xs"
      >
        <input
          type="text"
          id="id_repeat"
          className="studio-form__control"
          {...register("repeat", {
            ...requiredValidator(),
            ...repeatValidator(),
          })}
        />
      </FormGroup>
      <FormGroup
        header={`${t("form-labels.timeout-seconds")} *`}
        htmlFor="id_timeout"
        error={errors.timeout}
        size="xs"
      >
        <input
          type="text"
          step="0"
          id="id_timeout"
          className="studio-form__control"
          {...register("timeout", {
            ...requiredValidator(),
            ...timeoutValidator(),
          })}
        />
      </FormGroup>
      <FormGroup
        header={`${t("form-labels.timeout-seconds")} *`}
        error={errors.branches as FieldError | undefined}
      >
        <div className={styles.formKeys}>
          {keys.map((key) => (
            <Checkbox
              {...register(`branches.${key}`)}
              className="studio-form__control"
              parentClassNames="m-1"
              key={key}
              label={key}
            />
          ))}
        </div>
      </FormGroup>
    </BaseModal>
  );
};

export default IvrMenuFormModal;
