import { CompassUserPermission } from "src/app/types/compass";
import { compareObjects } from "src/utils";
import { copyObject } from "src/utils/index";
import { DpElementCombined, DpElementType } from "src/app/types/dialplans";
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { RootState } from "src/app/rootReducer";
import {
  assignDialPlanIds,
  cleanupDialPlanIds,
  getBranchElementId,
  getDpBranchByPath,
  getDpElementBranches,
  getDpElementById,
  getModalForDpElement,
  getSideBarForDpElement,
  isDialPlanCollapseStateChangedManually,
  removeDpElementBranch,
  removeDpElementById,
} from "src/utils/dialPlan";
import { restApi } from "src/app/api";
import { DpModalType, DpSideBarType } from "src/app/types";
import {
  CompassResourceType,
  CompassExtension,
  CompassExternalNumber,
} from "src/app/types/compass";
import { ModalFormAction } from "./types";
import { loadDpSwitches } from "../DpSwitches/dpSwitchesSlice";

export enum DialPlanType {
  extension = "extension",
  externalNumber = "externalNumber",
}

export type ElementCollapsedState = undefined | true | false; // undefined means: no preference, follow global collapse state

const getUpdatedCollapseState = (
  dialPlan: DpElementCombined[],
  collapseState: BranchCollapseState,
  updatedAllOpenedState?: boolean,
  updatedElementsState?: { [key: string]: ElementCollapsedState }
): BranchCollapseState => {
  const updatedCollapseState = copyObject(collapseState);
  if (updatedAllOpenedState !== undefined) {
    updatedCollapseState.allOpened = updatedAllOpenedState;
  }
  if (updatedElementsState !== undefined) {
    updatedCollapseState.elements = {
      ...updatedCollapseState.elements,
      ...updatedElementsState,
    };
  }
  if (isDialPlanCollapseStateChangedManually(dialPlan, updatedCollapseState)) {
    updatedCollapseState.allOpened = !updatedCollapseState.allOpened;
    updatedCollapseState.elements = {};
  }
  return updatedCollapseState;
};

export type BranchCollapseState = {
  elements: { [key: string]: ElementCollapsedState };
  allOpened: boolean;
};

export type DpConfigModalState = {
  type: DpModalType;
  id: string;
  action: ModalFormAction;
  branch?: number;
};

export type DpSideBarState = {
  type: DpSideBarType;
  id: string;
  branch?: number;
};

type DpEditorState = {
  dialPlan: DpElementCombined[];
  savedDialPlan: DpElementCombined[];
  permission?: CompassUserPermission;
  isLoading: boolean;
  loaded: boolean;
  loadError?: SerializedError;
  numberDescription?: string;
  collapseState: BranchCollapseState;
  openedConfigModal?: DpConfigModalState;
  openedSideBar?: DpSideBarState;
};

const initialState: DpEditorState = {
  dialPlan: [],
  savedDialPlan: [],
  isLoading: false,
  loaded: false,
  collapseState: {
    elements: {},
    allOpened: false,
  },
};

export const loadDialPlan = createAsyncThunk<
  {
    dialPlan: DpElementCombined[];
    permission: CompassUserPermission;
    numberDescription: string;
  },
  { type: DialPlanType; id: number },
  { state: RootState }
>(
  "dpEditor/loadDialPlan",
  async ({ type, id }, { rejectWithValue, getState }) => {
    const companyId = getState().auth.companyId;
    if (!companyId) {
      return rejectWithValue(`auth.companyId is required`);
    }
    const user = getState().auth.user;
    let numberDescription: string = "";
    try {
      if (!user) {
        throw new Error("auth.user is required");
      }
      switch (type) {
        case DialPlanType.extension: {
          const extension = (
            await restApi.get<CompassExtension>(`extension/${id}`)
          ).data;
          numberDescription = `${extension.name} (${extension.number})`;
          break;
        }
        case DialPlanType.externalNumber: {
          const extension = (
            await restApi.get<CompassExternalNumber>(`externalNumber/${id}`)
          ).data;
          numberDescription = `${extension.name} (${extension.number})`;
          break;
        }
      }
      const permission: CompassUserPermission | null = (
        await restApi.get<{ value: CompassUserPermission }>(
          `user/${
            user.entityId
          }/permission?targetEntity=${restApi.getUrlForObject(
            CompassResourceType.company,
            companyId
          )}`
        )
      ).data.value;
      const { steps: dialPlan } = (
        await restApi.get<{ steps: DpElementCombined[] }>(
          `${type}/${id}/dialplan`
        )
      ).data;
      return {
        permission,
        dialPlan: assignDialPlanIds(dialPlan),
        numberDescription,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveDialPlan = createAsyncThunk<
  { dialPlan: DpElementCombined[] },
  { type: DialPlanType; id: number },
  { state: RootState }
>(
  "dpEditor/saveDialPlan",
  async ({ type, id }, { rejectWithValue, getState, dispatch }) => {
    try {
      let dialPlanToSave = copyObject(getState().dpEditor.dialPlan);
      await restApi.post(`${type}/${id}/dialplan`, {
        steps: cleanupDialPlanIds(copyObject(dialPlanToSave)),
      });
      // NOTE: backend assings new ids for dp switches on save
      // make sure redux has updated dp switches
      await dispatch(loadDpSwitches());
      return {
        dialPlan: dialPlanToSave,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const dpEditorSlice = createSlice({
  name: "dpEditor",
  initialState,
  reducers: {
    updateDialPlan(
      state,
      { payload: dialPlan }: PayloadAction<DpElementCombined[]>
    ) {
      state.dialPlan = dialPlan;
    },
    resetDialPlan(state) {
      state.dialPlan = [];
      state.savedDialPlan = [];
      state.isLoading = false;
      state.loaded = false;
      state.numberDescription = undefined;
      state.collapseState = {
        elements: {},
        allOpened: false,
      };
    },
    pushDialPlanElement(
      state,
      {
        payload: { dpElement, path, idx, openConfigModal },
      }: PayloadAction<{
        dpElement: DpElementCombined;
        path: number[][];
        idx?: number;
        openConfigModal?: boolean;
      }>
    ) {
      const newDialPlan: DpEditorState["dialPlan"] = copyObject(state.dialPlan);
      const branch = getDpBranchByPath(newDialPlan, path);
      const newDpElement = assignDialPlanIds([copyObject(dpElement)])[0];
      if (idx === undefined) {
        branch.push(newDpElement);
      } else {
        branch.splice(idx, 0, newDpElement);
      }
      // NOTE: expand new element by default
      if (newDpElement._temp?.id) {
        state.collapseState = getUpdatedCollapseState(
          state.dialPlan,
          state.collapseState,
          undefined,
          { [newDpElement._temp?.id]: true }
        );
      }
      state.dialPlan = newDialPlan;
      if (openConfigModal && newDpElement._temp?.id) {
        let modalType: DpModalType | null = getModalForDpElement(newDpElement);
        if (modalType) {
          state.openedConfigModal = {
            id: newDpElement._temp?.id,
            type: modalType,
            action: ModalFormAction.create,
          };
        }
      }
    },
    updateDialPlanElement(
      state,
      {
        payload: { id, dpElement, isNew },
      }: PayloadAction<{
        id: string;
        dpElement: DpElementCombined;
        isNew?: boolean;
      }>
    ) {
      const newDialPlan: DpEditorState["dialPlan"] = copyObject(state.dialPlan);
      const oldDpElement = getDpElementById(id, newDialPlan);
      if (!oldDpElement) {
        return;
      }
      const oldDpElementTemp = oldDpElement._temp;
      Object.keys(oldDpElement).forEach(
        (key) => delete (oldDpElement as any)[key]
      );
      const newDpElement = Object.assign(oldDpElement, dpElement);
      newDpElement._temp = oldDpElementTemp;

      state.dialPlan = newDialPlan;
      if (isNew) {
        // NOTE: expand new element childs by default
        const branches = getDpElementBranches(newDpElement);
        const updatedElementsCollapseState: {
          [key: string]: ElementCollapsedState;
        } = {};
        branches.forEach((_, idx) => {
          updatedElementsCollapseState[getBranchElementId(newDpElement, idx)] =
            true;
        });
        if (newDpElement.type === DpElementType.numberRecognition) {
          // NOTE: expand default step for number recognition element
          updatedElementsCollapseState[
            getBranchElementId(newDpElement, branches.length)
          ] = true;
        }
        state.collapseState = getUpdatedCollapseState(
          state.dialPlan,
          state.collapseState,
          undefined,
          updatedElementsCollapseState
        );
      }
      const sidebarType = getSideBarForDpElement(newDpElement);
      if (sidebarType && newDpElement._temp?.id) {
        state.openedSideBar = {
          id: newDpElement._temp?.id,
          type: sidebarType,
        };
      }
    },
    removeDialPlanElement(
      state,
      { payload: { id } }: PayloadAction<{ id: string }>
    ) {
      removeDpElementById(id, state.dialPlan);
    },
    removeDialPlanElementBranch(
      state,
      {
        payload: { elementId, branchIdx },
      }: PayloadAction<{ elementId: string; branchIdx: number }>
    ) {
      removeDpElementBranch(elementId, branchIdx, state.dialPlan);
    },
    moveDialPlanElement(
      state,
      {
        payload: { origin, destination },
      }: PayloadAction<{
        origin: {
          path: number[][];
          idx: number;
        };
        destination: {
          path: number[][];
          idx?: number;
        };
      }>
    ) {
      const newDialPlan: DpEditorState["dialPlan"] = copyObject(state.dialPlan);

      const originBranch = getDpBranchByPath(newDialPlan, origin.path);
      const destinationBranch = getDpBranchByPath(
        newDialPlan,
        destination.path
      );

      const element = originBranch[origin.idx];
      originBranch.splice(origin.idx, 1);
      if (destination.idx === undefined) {
        destinationBranch.push(element);
      } else {
        let destinationIdx = destination.idx;
        // NOTE: reduce 1 from destination in case we move element down in branch
        // since the 'source' position of this element is freed by the splice operation above
        if (
          compareObjects(origin.path, destination.path) &&
          destinationIdx > origin.idx
        ) {
          destinationIdx -= 1;
        }
        destinationBranch.splice(destinationIdx, 0, element);
      }
      state.dialPlan = newDialPlan;
    },
    collapseToggleAll(state) {
      state.collapseState = {
        elements: {},
        allOpened: !state.collapseState.allOpened,
      };
    },
    collapseToggleElement(state, { payload: id }: PayloadAction<string>) {
      const currentState =
        state.collapseState.elements[id] !== undefined
          ? state.collapseState.elements[id]
          : state.collapseState.allOpened;
      state.collapseState = getUpdatedCollapseState(
        state.dialPlan,
        state.collapseState,
        undefined,
        { [id]: !currentState }
      );
    },
    openConfigModal(state, { payload }: PayloadAction<DpConfigModalState>) {
      state.openedConfigModal = payload;
    },
    closeConfigModal(state) {
      state.openedConfigModal = undefined;
    },
    openSideBar(state, { payload }: PayloadAction<DpSideBarState>) {
      state.openedSideBar = payload;
    },
    closeSideBar(state) {
      state.openedSideBar = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadDialPlan.pending, (state) => {
      state.loadError = undefined;
      state.isLoading = true;
    });
    builder.addCase(
      loadDialPlan.fulfilled,
      (state, { payload: { dialPlan, permission, numberDescription } }) => {
        state.dialPlan = dialPlan;
        state.savedDialPlan = dialPlan;
        state.permission = permission;
        state.numberDescription = numberDescription;
        state.isLoading = true;
        state.loaded = true;
      }
    );
    builder.addCase(loadDialPlan.rejected, (state, { error }) => {
      state.loadError = error;
      state.isLoading = false;
    });

    builder.addCase(
      saveDialPlan.fulfilled,
      (state, { payload: { dialPlan } }) => {
        state.dialPlan = dialPlan;
        state.savedDialPlan = dialPlan;
      }
    );
  },
});

export const {
  updateDialPlan,
  resetDialPlan,
  pushDialPlanElement,
  removeDialPlanElement,
  updateDialPlanElement,
  removeDialPlanElementBranch,
  moveDialPlanElement,
  collapseToggleAll,
  collapseToggleElement,
  openConfigModal,
  closeConfigModal,
  openSideBar,
  closeSideBar,
} = dpEditorSlice.actions;
export const selectDialPlanState = ({
  dpEditor: { dialPlan, loaded, isLoading, loadError, numberDescription },
}: RootState) => ({
  dialPlan,
  loaded,
  isLoading,
  loadError,
  numberDescription,
});
export const selectSavedDialPlan = ({
  dpEditor: { savedDialPlan },
}: RootState) => savedDialPlan;
export const selectDialPlanPermission = ({
  dpEditor: { permission },
}: RootState) => permission;
export const selectCollapseState = ({
  dpEditor: { collapseState },
}: RootState) => collapseState;
export const selectOpenedConfigModalState = ({
  dpEditor: { openedConfigModal },
}: RootState) => openedConfigModal;
export const selectOpenedSideBarState = ({
  dpEditor: { openedSideBar },
}: RootState) => openedSideBar;
export default dpEditorSlice.reducer;
