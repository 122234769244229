import { RootState } from "src/app/rootReducer";
import { CompassConference, CompassResourceType } from "src/app/types/compass";
import { genericDataSliceFactory } from "src/utils/genericDataSliceFactory";
import { getOrderedResources } from "src/utils/resources";

const { slice, loadItemsThunk } = genericDataSliceFactory<CompassConference>(
  "conferences",
  (companyId) => getOrderedResources(companyId, CompassResourceType.conference)
);

export const loadConferences = loadItemsThunk;

export const selectConferencesState = ({ conferences }: RootState) =>
  conferences;

export default slice.reducer;
