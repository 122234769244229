import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import {
  embeddedAuthInitialize,
  selectAuthStatus,
} from "src/features/Auth/authSlice";
import DpEditor from "src/features/DpEditor";
import { DialPlanType } from "src/features/DpEditor/dpEditorSlice";
import { getAppElementId } from "src/utils";
import { useAppDispatch } from "./store";
import { useTranslation } from "react-i18next";

const EmbeddedDpEditorApp: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useSelector(selectAuthStatus);
  const { t } = useTranslation();
  const dialPlanType = useMemo(() => {
    switch (
      document
        .querySelector(`#${getAppElementId()}`)!
        .getAttribute("data-dialplan-type")!
    ) {
      case "extension":
        return DialPlanType.extension;
      case "did":
        return DialPlanType.externalNumber;
    }
  }, []);
  const dialPlanId = useMemo(() => {
    return parseInt(
      document
        .querySelector(`#${getAppElementId()}`)!
        .getAttribute("data-dialplan-id")!
    );
  }, []);
  useEffect(() => {
    dispatch(embeddedAuthInitialize());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isAuthenticated ? (
        <Router>
          <DpEditor type={dialPlanType!} id={dialPlanId} />
        </Router>
      ) : (
        <div className="w-full h-full flex items-center justify-center text-gs-200">
          {t("controls.page-loading")}
        </div>
      )}
    </>
  );
};

export default EmbeddedDpEditorApp;
