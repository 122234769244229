import { RootState } from "src/app/rootReducer";
import { CompassQueue, CompassResourceType } from "src/app/types/compass";
import { genericDataSliceFactory } from "src/utils/genericDataSliceFactory";
import { getOrderedResources } from "src/utils/resources";

const { slice, loadItemsThunk } = genericDataSliceFactory<CompassQueue>(
  "queues",
  (companyId) => getOrderedResources(companyId, CompassResourceType.queue)
);

export const loadQueues = loadItemsThunk;

export const selectQueuesState = ({ queues }: RootState) => queues;

export default slice.reducer;
