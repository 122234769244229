import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DpElementNumberRecognitionBranch } from "src/app/types/dialplans";
import TagsInput from "src/components/TagsInput";
import {
  HIDDEN_CALLER_ID_INPUT_VALUE,
  humanizeNumberRecognitionPrefix,
} from "src/utils/dialPlan";
import { isNumberPrefixValid } from "src/utils/form";

export const parseNumberRecognitionPrefixValue = (value: string): string => {
  if (value === HIDDEN_CALLER_ID_INPUT_VALUE) {
    return value;
  }
  return value.replace(/\D/g, "");
};

const NumberRecognitionInput: React.FC<{
  branch?: DpElementNumberRecognitionBranch;
  onChange: (data: { anonymous: boolean; numberPrefixes: string[] }) => void;
}> = ({ branch, onChange }) => {
  const { t } = useTranslation();
  const defaultTagsInputValue = useMemo(
    () => [
      ...(branch?.anonymous ? [HIDDEN_CALLER_ID_INPUT_VALUE] : []),
      ...(branch?.numberPrefixes || []),
    ],
    [branch]
  );
  const tagsInputOnChangeHandler = useCallback(
    (value: string[]) => {
      let anonymous = false;
      const numberPrefixes = value.filter((item) => {
        if (item !== HIDDEN_CALLER_ID_INPUT_VALUE) {
          return true;
        }
        anonymous = true;
        return false;
      });
      onChange({
        anonymous,
        numberPrefixes,
      });
    },
    [onChange]
  );
  return (
    <TagsInput
      defaultValue={defaultTagsInputValue}
      valueToDisplayString={humanizeNumberRecognitionPrefix}
      displayStringToValue={parseNumberRecognitionPrefixValue}
      suggestionsTitle={t("controls.suggested-numbers")}
      suggestions={["316", "31", HIDDEN_CALLER_ID_INPUT_VALUE]}
      validator={isNumberPrefixValid}
      onChange={tagsInputOnChangeHandler}
    />
  );
};

export default NumberRecognitionInput;
