import React, { useEffect } from "react";
import {
  Switch,
  BrowserRouter as Router,
  Redirect,
  Route,
  Link,
} from "react-router-dom";
import "./App.css";
import DpEditor from "src/features/DpEditor";
import { authInitialize } from "src/features/Auth/authSlice";
import Login from "src/features/Auth";
import Extensions from "src/features/Extensions";
import { useAppDispatch } from "./store";
import PrivateRoute from "src/components/PrivateRoute";
import GuestRoute from "src/components/GuestRoute";
import { DialPlanType } from "src/features/DpEditor/dpEditorSlice";
import ExternalNumbers from "src/features/ExternalNumbers";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(authInitialize());
  }, [dispatch]);
  return (
    <div className="App">
      <Router>
        <Switch>
          <PrivateRoute exact path="/home">
            <ul>
              <li>
                <Link to="/extensions">Extensions</Link>
              </li>
              <li>
                <Link to="/externalNumbers">External numbers</Link>
              </li>
            </ul>
          </PrivateRoute>
          <PrivateRoute exact path="/extensions">
            <Extensions />
          </PrivateRoute>
          <PrivateRoute exact path="/extensions/:id">
            <DpEditor type={DialPlanType.extension} />
          </PrivateRoute>
          <PrivateRoute exact path="/externalNumbers">
            <ExternalNumbers />
          </PrivateRoute>
          <PrivateRoute exact path="/externalNumbers/:id">
            <DpEditor type={DialPlanType.externalNumber} />
          </PrivateRoute>
          <GuestRoute path="/">
            <Login />
          </GuestRoute>
          <Route>
            <Redirect to="/"></Redirect>
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
