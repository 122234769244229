import classNames from "classnames";
import React from "react";

const Badge: React.FC<{ color: "green" | "gray" }> = ({ children, color }) => {
  return (
    <div
      className={classNames(
        "border border-solid rounded-full py-0.5 px-2 font-bold text-xs border-outline-300",
        {
          "bg-green-400 bg-opacity-30 text-green-200": color === "green",
        }
      )}
    >
      {children}
    </div>
  );
};

export default Badge;
