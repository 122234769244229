import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nanoid } from "nanoid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  DpElementNumberRecognition,
  DpElementNumberRecognitionBranch,
} from "src/app/types/dialplans";
import Button, {
  ButtonColor,
  ButtonFill,
  ButtonSize,
} from "src/components/Button";
import FormGroup from "src/components/FormGroup";
import PropertyDisplay from "src/components/PropertyDisplay";
import Modal from "src/components/BaseModal";
import { copyObject } from "src/utils";
import {
  getDpElementBranch,
  getDpElementTitle,
  numberRecognitionConditionsDisplay,
} from "src/utils/dialPlan";
import { nameFieldValidator, numberPrefixesValidator } from "src/utils/form";
import { usePrevious } from "src/utils/hooks";
import NumberRecognitionInput from "../NumberRecognitionInput";

const NumberRecognitionConditionForm: React.FC<{
  onUpdate: (
    branch: DpElementNumberRecognitionBranch,
    isValid: boolean
  ) => void;
  dpElement: DpElementNumberRecognition;
  branch?: number;
}> = ({ onUpdate, dpElement, branch }) => {
  const { t } = useTranslation();
  const branchElement = useMemo(() => {
    if (branch === undefined || branch >= dpElement.branches.length) {
      return undefined;
    }
    return getDpElementBranch(
      dpElement,
      branch
    ) as DpElementNumberRecognitionBranch;
  }, [dpElement, branch]);
  const {
    register,
    formState: { errors, isValid },
    control,
    setValue,
    watch,
    getValues,
  } = useForm<{
    name: string;
    numberPrefixes: string[];
    anonymous: boolean;
  }>({
    mode: "all",
    defaultValues: {
      name: branchElement?.name || "",
      numberPrefixes: branchElement?.numberPrefixes || [],
      anonymous: branchElement?.anonymous || false,
    },
  });
  const previousIsValid = usePrevious(isValid);
  useEffect(() => {
    if (previousIsValid !== isValid) {
      const data = getValues();
      onUpdate(
        {
          anonymous: data.anonymous,
          name: data.name,
          numberPrefixes: data.numberPrefixes,
          steps: [],
        },
        isValid
      );
    }
    const subscription = watch((data) => {
      onUpdate(
        {
          anonymous: data.anonymous,
          name: data.name,
          numberPrefixes: data.numberPrefixes as string[],
          steps: [],
        } as DpElementNumberRecognitionBranch,
        isValid
      );
    });
    return () => subscription.unsubscribe();
  }, [watch, onUpdate, isValid, previousIsValid, getValues]);
  return (
    <>
      <FormGroup
        header={`${t("controls.condition")} #${
          branch === undefined ? 1 : branch + 1
        }`}
        error={errors.name}
        htmlFor="id_name"
      >
        <input
          type="text"
          id="id_name"
          autoFocus
          className="studio-form__control"
          {...register("name", {
            ...nameFieldValidator(),
          })}
        />
      </FormGroup>
      <FormGroup
        header={t("form-labels.recognition-number-list")}
        description={t(
          "dp-editor.number-recognition-form-modal.number-list-tooltip"
        )}
        error={errors.numberPrefixes as FieldError | undefined}
      >
        <Controller
          control={control}
          name="numberPrefixes"
          rules={{
            ...numberPrefixesValidator(),
          }}
          render={() => (
            <NumberRecognitionInput
              branch={branchElement}
              onChange={(data) => {
                setValue("anonymous", data.anonymous);
                setValue("numberPrefixes", data.numberPrefixes, {
                  shouldValidate: true,
                });
              }}
            />
          )}
        ></Controller>
      </FormGroup>
    </>
  );
};

const NumberRecognitionConditions: React.FC<{
  onNext?: () => void;
  dpElement: DpElementNumberRecognition;
  onAddCondition?: () => void;
  onUpdateCondition?: (idx: number) => void;
  onDeleteCondition?: (idx: number) => void;
}> = ({
  onNext,
  dpElement,
  onAddCondition,
  onUpdateCondition,
  onDeleteCondition,
}) => {
  const { t } = useTranslation();
  const branchIds = useMemo(
    () => dpElement.branches.map(() => nanoid()),
    [dpElement]
  );
  const updateConditionHandler = useCallback(
    (idx: number) => {
      if (onUpdateCondition) {
        onUpdateCondition(idx);
      }
    },
    [onUpdateCondition]
  );
  return (
    <>
      {branchIds.length ? (
        branchIds.map((branchId, idx) => (
          <div key={branchId}>
            <PropertyDisplay
              title={
                <div className="flex justify-between items-center">
                  <span className="text-amber-500">
                    {t("controls.condition")} #{idx + 1}
                  </span>
                  <div>
                    <Button
                      fill={ButtonFill.clear}
                      size={ButtonSize.small}
                      color={ButtonColor.grey}
                      onClick={updateConditionHandler.bind(null, idx)}
                    >
                      {t("controls.update")}
                    </Button>
                    {branchIds.length > 1 ? (
                      <Button
                        fill={ButtonFill.clear}
                        size={ButtonSize.small}
                        color={ButtonColor.grey}
                        onClick={
                          onDeleteCondition
                            ? onDeleteCondition.bind(null, idx)
                            : undefined
                        }
                      >
                        {t("controls.delete")}
                      </Button>
                    ) : null}
                  </div>
                </div>
              }
              value={getDpElementTitle(dpElement, { branch: idx })}
              className="mb-3"
            />
            <PropertyDisplay
              className="mb-5"
              title={`${t("controls.numbers")}`}
              value={numberRecognitionConditionsDisplay(
                dpElement.branches[idx]
              )}
            />
          </div>
        ))
      ) : (
        <div className="pb-8 text-gs-600 text-s">
          {t("controls.no-conditions")}
        </div>
      )}
      <div className="flex items-center">
        <Button
          autoFocus={dpElement.branches.length === 0}
          onClick={onAddCondition}
          className="mr-2"
          iconOnly={true}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>{" "}
        <span className="text-s font-semibold">
          {t("controls.add-condition")}
        </span>
      </div>
    </>
  );
};

const NumberRecognitionCreateWizard: React.FC<{
  isOpen: boolean;
  dpElement: DpElementNumberRecognition;
  onRequestClose: (data?: { dpElement: DpElementNumberRecognition }) => void;
}> = ({ isOpen, dpElement, onRequestClose }) => {
  const { t } = useTranslation();
  const [createdDpElement, setCreatedDpElement] = useState({ ...dpElement });
  const [activeBranch, setActiveBranch] = useState<
    | {
        idx: number;
        branch: DpElementNumberRecognitionBranch;
      }
    | undefined
  >();
  const [isConditionFormValid, setIsConditionFormValid] = useState(true);
  const onConditionUpdateHandler = useCallback(
    (data: DpElementNumberRecognitionBranch, isValid: boolean) => {
      if (!activeBranch?.branch) {
        return;
      }
      setIsConditionFormValid(isValid);
      setActiveBranch({
        ...activeBranch,
        branch: data,
      });
    },
    [activeBranch]
  );
  const addConditionHandler = useCallback(() => {
    setActiveBranch({
      idx: createdDpElement.branches.length,
      branch: { steps: [], name: "", numberPrefixes: [], anonymous: false },
    });
  }, [createdDpElement]);
  const updateConditionHandler = useCallback(
    (branchIdx) => {
      setActiveBranch({
        idx: branchIdx,
        branch: copyObject(createdDpElement.branches[branchIdx]),
      });
    },
    [createdDpElement]
  );
  const deleteConditionHandler = useCallback(
    (branchIdx) => {
      setCreatedDpElement({
        ...createdDpElement,
        branches: createdDpElement.branches.filter(
          (_, idx) => idx !== branchIdx
        ),
      });
    },
    [createdDpElement]
  );
  const onFinishStepNextHandler = useCallback(() => {
    onRequestClose({
      dpElement: createdDpElement,
    });
  }, [createdDpElement, onRequestClose]);
  const onRequestCloseHandler = useCallback(() => {
    if (activeBranch !== undefined) {
      setActiveBranch(undefined);
      return;
    }
    onRequestClose();
  }, [activeBranch, onRequestClose]);
  const onBranchSaveHandler = () => {
    if (!activeBranch) {
      return;
    }
    const updatedDpElement = copyObject(createdDpElement);
    updatedDpElement.branches[activeBranch.idx] = activeBranch.branch;
    setCreatedDpElement(updatedDpElement);
    setActiveBranch(undefined);
  };
  return (
    <Modal
      title={t("dp-editor.number-recognition-create-wizard.title")}
      onRequestClose={onRequestCloseHandler}
      isOpen={isOpen}
      closeButtonText={activeBranch ? t("controls.back") : undefined}
      modalStyles={{
        content: {
          width: "70%",
          maxWidth: "600px",
        },
      }}
      buttons={
        activeBranch === undefined
          ? [
              {
                text: t("controls.create-element"),
                props: {
                  onClick: onFinishStepNextHandler,
                  disabled: createdDpElement.branches.length === 0,
                  autoFocus: createdDpElement.branches.length > 0,
                  tooltip: !createdDpElement.branches?.length
                    ? t("form-labels.add-condition-required-tooltip")
                    : undefined,
                },
              },
            ]
          : [
              {
                text: t("controls.done"),
                props: {
                  onClick: onBranchSaveHandler,
                  disabled: !isConditionFormValid,
                },
              },
            ]
      }
    >
      {activeBranch === undefined ? (
        <NumberRecognitionConditions
          dpElement={createdDpElement}
          onAddCondition={addConditionHandler}
          onDeleteCondition={deleteConditionHandler}
          onUpdateCondition={updateConditionHandler}
          onNext={onFinishStepNextHandler}
        />
      ) : (
        <NumberRecognitionConditionForm
          dpElement={createdDpElement}
          onUpdate={onConditionUpdateHandler}
          branch={activeBranch.idx}
        />
      )}
    </Modal>
  );
};

export default NumberRecognitionCreateWizard;
