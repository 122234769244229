import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { nameFieldValidator, requiredValidator } from "src/utils/form";
import { DpElementIvr } from "src/app/types/dialplans";
import styles from "./IvrMenuCreateWizard.module.css";
import { copyObject } from "src/utils";
import { ArrayElementType } from "src/app/types";
import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";
import Wizard, { WizardStep } from "src/components/Wizard";
import Checkbox from "src/components/Checkbox";
import PropertyDisplay from "src/components/PropertyDisplay";
import { useLoadDialplanElements, usePrevious } from "src/utils/hooks";
import FormGroup from "src/components/FormGroup";
import BaseModal from "src/components/BaseModal";
import PromptsSelect from "src/features/Prompts/PromptsSelect";

const keys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];

const IvrNameStep: React.FC<{
  onUpdate?: (formData: { name?: string }, isValid: boolean) => void;
  dpElement: DpElementIvr;
}> = ({ dpElement, onUpdate }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    getValues,
  } = useForm<{
    name: string;
  }>({
    mode: "all",
    defaultValues: {
      name: dpElement.name,
    },
  });
  const previousIsValid = usePrevious(isValid);
  useEffect(() => {
    if (!onUpdate) {
      return;
    }
    if (previousIsValid !== isValid) {
      onUpdate(getValues(), isValid);
    }
    const subscription = watch((data) => onUpdate(data, isValid));
    return () => subscription.unsubscribe();
  }, [watch, onUpdate, isValid, previousIsValid, getValues]);
  const { nextStep } = useWizard();
  const handleNextClick = handleSubmit((data) => {
    nextStep();
  });
  return (
    <WizardStep
      buttons={[
        {
          text: t("controls.next-step"),
          props: {
            onClick: handleNextClick,
            disabled: !isValid,
          },
        },
      ]}
    >
      <FormGroup
        header={t("form-labels.ivr-name")}
        error={errors.name}
        htmlFor="id_name"
      >
        <input
          type="text"
          id="id_name"
          className="studio-form__control"
          placeholder={t("form-labels.ivr-name-placeholder")}
          autoFocus={true}
          {...register("name", {
            ...requiredValidator(
              t("form-validation-errors.name-field-required")
            ),
            ...nameFieldValidator(true),
          })}
        />
      </FormGroup>
    </WizardStep>
  );
};

const IvrPromptStep: React.FC<{
  onUpdate?: (formData: { promptId?: number }, isValid: boolean) => void;
  dpElement: DpElementIvr;
}> = ({ dpElement, onUpdate }) => {
  const { t } = useTranslation();
  const form = useForm<{
    promptId: string;
  }>({
    mode: "all",
    defaultValues: {
      promptId: dpElement.promptId ? dpElement.promptId.toString() : undefined,
    },
  });
  const {
    formState: { errors, isValid },
    handleSubmit,
    watch,
    getValues,
  } = form;
  const { nextStep } = useWizard();
  const handleNextClick = handleSubmit((data) => {
    nextStep();
  });
  const convertData = useCallback((data: { promptId: string }) => {
    return {
      promptId: data.promptId ? parseInt(data.promptId) : undefined,
    };
  }, []);
  const previousIsValid = usePrevious(isValid);
  useEffect(() => {
    if (!onUpdate) {
      return;
    }
    if (previousIsValid !== isValid) {
      onUpdate(convertData(getValues()), isValid);
    }
    const subscription = watch((data) =>
      onUpdate(convertData(data as { promptId: string }), isValid)
    );
    return () => subscription.unsubscribe();
  }, [watch, onUpdate, isValid, getValues, previousIsValid, convertData]);
  return (
    <WizardStep
      buttons={[
        {
          text: t("controls.next-step"),
          props: {
            onClick: handleNextClick,
            disabled: !isValid,
          },
        },
      ]}
    >
      <FormGroup
        header={t("form-labels.voice-prompt-select")}
        description={t("form-labels.voice-prompt-select-info")}
        error={errors.promptId}
      >
        <PromptsSelect
          name="promptId"
          form={form}
          selectProps={{ autoFocus: true }}
        />
      </FormGroup>
    </WizardStep>
  );
};

const IvrOptionsStep: React.FC<{
  onUpdate?: (
    formData: { branches?: DpElementIvr["branches"] },
    isValid: boolean
  ) => void;
  dpElement: DpElementIvr;
}> = ({ dpElement, onUpdate }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    getValues,
  } = useForm<{
    branches: string[];
  }>({
    mode: "all",
    defaultValues: {
      branches: (() => {
        const branches: string[] = [];
        Object.values(dpElement.branches || []).forEach((branch) => {
          branches.push(branch.key);
        });
        return branches;
      })(),
    },
  });
  const { nextStep } = useWizard();
  const stringsArrayToBranches = useCallback(
    (arr?: Array<string | undefined>) => {
      if (!arr) {
        return;
      }
      const branches: DpElementIvr["branches"] = [];
      arr.forEach((key) => {
        const branch: ArrayElementType<DpElementIvr["branches"]> = {
          key: key!,
          steps: [],
        };
        branches.push(branch);
      });
      return branches;
    },
    []
  );
  const handleNextClick = handleSubmit((formData) => {
    nextStep();
  });
  const previousIsValid = usePrevious(isValid);
  useEffect(() => {
    if (!onUpdate) {
      return;
    }
    if (previousIsValid !== isValid) {
      onUpdate(
        {
          branches: stringsArrayToBranches(getValues().branches),
        },
        isValid
      );
    }
    const subscription = watch((data) => {
      onUpdate(
        {
          branches: stringsArrayToBranches(data.branches),
        },
        isValid
      );
    });
    return () => subscription.unsubscribe();
  }, [
    watch,
    onUpdate,
    isValid,
    stringsArrayToBranches,
    getValues,
    previousIsValid,
  ]);
  return (
    <WizardStep
      buttons={[
        {
          text: t("controls.next-step"),
          props: {
            onClick: handleNextClick,
            disabled: !isValid,
          },
        },
      ]}
    >
      <FormGroup
        header={t("form-labels.ivr-keys")}
        description={t("form-labels.ivr-keys-info")}
        error={errors.branches as FieldError | undefined}
      >
        <div className={styles.formKeys}>
          {keys.map((key, idx) => (
            <Checkbox
              {...register(`branches`, {
                ...requiredValidator(
                  t("form-validation-errors.ivr-key-required")
                ),
              })}
              autoFocus={idx === 0}
              parentClassNames="m-1"
              key={key}
              value={key}
              className="studio-form__control"
              label={key}
            />
          ))}
        </div>
      </FormGroup>
    </WizardStep>
  );
};

const IvrFinishStep: React.FC<{
  onNext?: () => void;
  dpElement: DpElementIvr;
}> = ({ onNext, dpElement }) => {
  const { t } = useTranslation();
  const { prompts } = useLoadDialplanElements();
  const promptName = useMemo(() => {
    return (
      prompts.find((item) => item.resourceId === dpElement.promptId)?.name || ""
    );
  }, [prompts, dpElement]);
  const branches = useMemo(() => {
    return dpElement.branches.map((item) => item.key).join(", ");
  }, [dpElement]);
  return (
    <WizardStep
      buttons={[
        {
          text: t("controls.create-element"),
          props: {
            autoFocus: true,
            onClick: onNext,
          },
        },
      ]}
    >
      <PropertyDisplay
        className="mb-5"
        title={t("form-labels.ivr-name")}
        value={dpElement.name}
      />
      <PropertyDisplay
        className="mb-5"
        title={t("form-labels.voice-prompt-select")}
        value={promptName}
      />
      <PropertyDisplay title={t("form-labels.ivr-keys")} value={branches} />
    </WizardStep>
  );
};

const IvrMenuCreateWizard: React.FC<{
  isOpen: boolean;
  dpElement: DpElementIvr;
  onRequestClose: (data?: { dpElement: DpElementIvr }) => void;
}> = ({ onRequestClose, isOpen, dpElement }) => {
  const { t } = useTranslation();
  const [createdDpElement, setCreatedDpElement] = useState({ ...dpElement });
  const [stepsState, setStepsState] = useState<{
    steps: {
      [key: string]: { visited: boolean };
    };
    activeStep: number;
  }>({ steps: { 0: { visited: true } }, activeStep: 0 });
  const [stepsValidState, setStepsValidState] = useState<{
    [key: string]: boolean;
  }>({});
  const { steps, activeStep } = stepsState;
  const activeStepChangeHandler = useCallback(
    (newActiveStep: number) => {
      setStepsState({
        activeStep: newActiveStep,
        steps: {
          ...steps,
          [newActiveStep]: {
            ...(steps[newActiveStep] || {}),
            visited: true,
          },
        },
      });
    },
    [steps, setStepsState]
  );
  const onStepUpdateHandler = useCallback(
    (stepNumber: number, data: Partial<DpElementIvr>, isValid: boolean) => {
      setStepsValidState({
        [stepNumber]: isValid,
      });
      setCreatedDpElement({
        ...createdDpElement,
        ...data,
      });
    },
    [createdDpElement]
  );
  const onFinishStepNextHandler = useCallback(() => {
    const ivrElement = copyObject(createdDpElement);
    // Default values
    // https://gitlab.iperitydev.com/compass/design-documents/-/blob/master/compass/dialplan/ivr-menu/ivr-menu-ux.md
    ivrElement.timeout = 10;
    ivrElement.repeat = 2;
    onRequestClose({
      dpElement: ivrElement,
    });
  }, [createdDpElement, onRequestClose]);
  const breadcrumbItems: { label: string; active?: boolean }[] = useMemo(() => {
    return [
      {
        label: t("controls.name"),
        active: stepsValidState[activeStep] && steps[0]?.visited,
      },
      {
        label: t("controls.prompt"),
        active: stepsValidState[activeStep] && steps[1]?.visited,
      },
      {
        label: t("controls.options"),
        active: stepsValidState[activeStep] && steps[2]?.visited,
      },
      {
        label: t("controls.finish"),
        active: stepsValidState[activeStep] && steps[3]?.visited,
      },
    ];
  }, [steps, activeStep, t, stepsValidState]);
  return (
    <BaseModal
      title={t("dp-editor.ivr-menu-create-wizard.title")}
      onRequestClose={onRequestClose.bind(null, undefined)}
      isOpen={isOpen}
      isWizard={true}
      hideFooterButtons={true}
    >
      <Wizard
        breadcrumbItems={breadcrumbItems}
        onActiveStepChange={activeStepChangeHandler}
      >
        <IvrNameStep
          dpElement={createdDpElement}
          onUpdate={onStepUpdateHandler.bind(null, 0)}
        />
        <IvrPromptStep
          dpElement={createdDpElement}
          onUpdate={onStepUpdateHandler.bind(null, 1)}
        />
        <IvrOptionsStep
          dpElement={createdDpElement}
          onUpdate={onStepUpdateHandler.bind(null, 2)}
        />
        <IvrFinishStep
          dpElement={createdDpElement}
          onNext={onFinishStepNextHandler}
        />
      </Wizard>
    </BaseModal>
  );
};

export default IvrMenuCreateWizard;
