import React, { useCallback, useEffect, useMemo } from "react";
import styles from "./DpEditor.module.css";
import Canvas from "./Canvas";
import Menu from "./Menu";
import {
  collapseToggleAll,
  DialPlanType,
  loadDialPlan,
  resetDialPlan,
  saveDialPlan,
  selectCollapseState,
  selectDialPlanPermission,
  selectDialPlanState,
} from "./dpEditorSlice";
import { useAppDispatch } from "src/app/store";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { CompassUserPermission } from "src/app/types/compass";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { handleApiError } from "src/utils/errorHandler";
import Button, { ButtonColor } from "src/components/Button";
import { getDpElementBranches } from "src/utils/dialPlan";
import ConfigModals from "./ConfigModals";
import DpEditorSideBar from "./DpEditorSideBar";

const DpEditor: React.FC<{ type: DialPlanType; id?: number }> = ({
  type,
  id,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const permission = useSelector(selectDialPlanPermission);
  const routeParams = useParams<{ id: string }>();
  const dialPlanId = useMemo(
    () => id || parseInt(routeParams?.id),
    [routeParams, id]
  );
  const collapseState = useSelector(selectCollapseState);
  const toggleCollapseStateHandler = useCallback(() => {
    dispatch(collapseToggleAll());
  }, [dispatch]);
  const { dialPlan } = useSelector(selectDialPlanState);
  const dpEditorHasBranches = useMemo(() => {
    return !!dialPlan.find((item) => getDpElementBranches(item).length > 0);
  }, [dialPlan]);

  const saveHandler = useCallback(async () => {
    if (dialPlanId === undefined) {
      return;
    }
    const resp = await dispatch(saveDialPlan({ id: dialPlanId, type }));
    const { type: respType } = resp;
    if (respType === saveDialPlan.fulfilled.type) {
      alert(
        (resp?.payload as AxiosError)?.response?.data.message ||
          t("dp-editor.messages.dialplan-saved")
      );
    } else if (respType === saveDialPlan.rejected.type) {
      handleApiError(
        t("error-messages.dial-plan-not-saved"),
        resp.payload as AxiosError
      );
    }
  }, [dispatch, dialPlanId, type, t]);

  useEffect(() => {
    if (dialPlanId) {
      dispatch(loadDialPlan({ id: dialPlanId, type }));
    }
    return () => {
      dispatch(resetDialPlan());
    };
  }, [dispatch, dialPlanId, type]);

  return (
    <div className={styles.dpEditor}>
      {permission === CompassUserPermission.permWrite ? (
        <div className={styles.menu}>
          <Menu />
        </div>
      ) : null}
      <div className={styles.canvas}>
        <Canvas />
        <div className={styles.canvasButtons}>
          <Button
            color={ButtonColor.grey}
            onClick={toggleCollapseStateHandler}
            className={styles.canvasButton}
            disabled={!dpEditorHasBranches}
          >
            {collapseState.allOpened
              ? t("dp-editor.header.collapse-all")
              : t("dp-editor.header.expand-all")}
          </Button>
          {permission === CompassUserPermission.permWrite ? (
            <Button onClick={saveHandler} className={styles.canvasButton}>
              {t("controls.save")}
            </Button>
          ) : null}
        </div>
      </div>
      <DpEditorSideBar />
      <ConfigModals />
    </div>
  );
};

export default DpEditor;
