import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nanoid } from "nanoid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";
import {
  DpElementTimeSwitch,
  DpElementTimeSwitchBranch,
  DpElementTimeSwitchCondition,
} from "src/app/types/dialplans";
import BaseModal from "src/components/BaseModal";
import Button, {
  ButtonColor,
  ButtonFill,
  ButtonSize,
} from "src/components/Button";
import FormGroup from "src/components/FormGroup";
import PropertyDisplay from "src/components/PropertyDisplay";
import Wizard, { WizardStep } from "src/components/Wizard";
import { copyObject } from "src/utils";
import { timeSwitchConditionDisplay } from "src/utils/datetime";
import { getDpElementBranch, getDpElementTitle } from "src/utils/dialPlan";
import { nameFieldValidator } from "src/utils/form";
import { usePrevious } from "src/utils/hooks";
import { TimeRoutingForm } from "../Modals/TimeRoutingFormModal";
import { ModalFormAction } from "../types";

const TimeSwitchNameStep: React.FC<{
  onBack: () => void;
  onUpdate: (
    branch: Partial<DpElementTimeSwitchBranch>,
    isValid: boolean
  ) => void;
  dpElement: DpElementTimeSwitch;
  branch?: number;
}> = ({ onUpdate, dpElement, branch, onBack }) => {
  const { t } = useTranslation();
  const branchElement = useMemo(() => {
    if (branch === undefined || branch >= dpElement.branches?.length) {
      return undefined;
    }
    return getDpElementBranch(dpElement, branch) as DpElementTimeSwitchBranch;
  }, [dpElement, branch]);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    getValues,
  } = useForm<{
    name: string;
  }>({
    mode: "all",
    defaultValues: {
      name: branchElement?.name,
    },
  });
  const previousIsValid = usePrevious(isValid);
  useEffect(() => {
    if (previousIsValid !== isValid) {
      onUpdate(getValues(), isValid);
    }
    const subscription = watch((data) => onUpdate(data, isValid));
    return () => subscription.unsubscribe();
  }, [watch, onUpdate, isValid, previousIsValid, getValues]);
  const { nextStep } = useWizard();
  const handleNextClick = handleSubmit(() => {
    nextStep();
  });
  return (
    <WizardStep
      buttons={[
        {
          text: t("controls.next-step"),
          props: {
            onClick: handleNextClick,
            disabled: !isValid,
          },
        },
        {
          text: t("controls.back"),
          props: {
            onClick: onBack,
            fill: ButtonFill.clear,
            color: ButtonColor.grey,
          },
        },
      ]}
    >
      {branch !== undefined ? (
        <>
          <div className="text-amber-500 font-bold mb-4">
            {t("controls.condition")} #{branch + 1}
          </div>
          <FormGroup
            header={t("form-labels.name").toString()}
            error={errors.name}
            htmlFor="id_name"
          >
            <input
              id="id_name"
              type="text"
              autoFocus={true}
              className="studio-form__control"
              {...register("name", {
                ...nameFieldValidator(),
              })}
            />
          </FormGroup>
        </>
      ) : null}
    </WizardStep>
  );
};

const TimeSwitchTimeStep: React.FC<{
  onUpdate: (
    branch: Partial<DpElementTimeSwitchBranch>,
    isValid: boolean
  ) => void;
  onSubmit: () => void;
  dpElement: DpElementTimeSwitch;
  branch?: number;
}> = ({ onUpdate, dpElement, branch, onSubmit }) => {
  const { t } = useTranslation();
  const branchElement = useMemo(() => {
    if (
      !dpElement.branches ||
      branch === undefined ||
      branch >= dpElement.branches?.length
    ) {
      return undefined;
    }
    return getDpElementBranch(dpElement, branch) as DpElementTimeSwitchBranch;
  }, [dpElement, branch]);
  const timeRoutingChangeHandler = useCallback(
    (condition: DpElementTimeSwitchCondition) => {
      onUpdate({ condition: condition || {} }, true);
    },
    [onUpdate]
  );
  return (
    <WizardStep
      buttons={[
        {
          text: t("controls.done"),
          props: {
            autoFocus: true,
            onClick: onSubmit,
          },
        },
      ]}
    >
      {branch !== undefined ? (
        <>
          <div className="text-amber-500 font-bold mb-4">
            {t("controls.condition")} #{branch + 1}
            {branchElement?.name ? `: ${branchElement.name}` : ""}
          </div>
          <TimeRoutingForm
            defaultValues={dpElement.branches[branch]?.condition}
            onChange={timeRoutingChangeHandler}
          />
        </>
      ) : null}
    </WizardStep>
  );
};

const TimeSwitchConditions: React.FC<{
  onNext?: () => void;
  dpElement: DpElementTimeSwitch;
  onAddCondition: () => void;
  onUpdateCondition: (idx: number) => void;
  onDeleteCondition: (idx: number) => void;
}> = ({
  onNext,
  dpElement,
  onAddCondition,
  onUpdateCondition,
  onDeleteCondition,
}) => {
  const { t } = useTranslation();
  const branchIds = useMemo(
    () => dpElement.branches?.map(() => nanoid()) || [],
    [dpElement]
  );
  return (
    <>
      {branchIds.length ? (
        branchIds.map((branchId, idx) => (
          <div key={branchId}>
            <div className="flex justify-between items-center text-amber-500 font-bold mb-1 text-s">
              {t("controls.condition")} #{idx + 1}
              <div>
                <Button
                  fill={ButtonFill.clear}
                  size={ButtonSize.small}
                  color={ButtonColor.grey}
                  onClick={onUpdateCondition.bind(null, idx)}
                >
                  {t("controls.update")}
                </Button>
                {branchIds.length > 1 ? (
                  <Button
                    fill={ButtonFill.clear}
                    size={ButtonSize.small}
                    color={ButtonColor.grey}
                    onClick={
                      onDeleteCondition
                        ? onDeleteCondition.bind(null, idx)
                        : undefined
                    }
                  >
                    {t("controls.delete")}
                  </Button>
                ) : null}
              </div>
            </div>
            <PropertyDisplay
              title={t("controls.name")}
              className="mb-2"
              value={getDpElementTitle(dpElement, { branch: idx }) || "-"}
            />
            <PropertyDisplay
              className="mb-5"
              title={t("controls.time")}
              value={timeSwitchConditionDisplay(
                dpElement.branches[idx].condition
              )}
            />
          </div>
        ))
      ) : (
        <div className="pb-8 text-gs-600 text-s">
          {t("controls.no-conditions")}
        </div>
      )}
      <div className="flex items-center">
        <Button
          onClick={onAddCondition}
          className="mr-2"
          iconOnly={true}
          autoFocus={!dpElement.branches?.length}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>{" "}
        <span className="text-s font-semibold">
          {t("controls.add-condition")}
        </span>
      </div>
    </>
  );
};

const TimeSwitchWizardContent: React.FC<{
  branch: number;
  onBack: () => void;
  dpElement: DpElementTimeSwitch;
  onSubmit: (dpElement: DpElementTimeSwitch) => void;
}> = ({ branch, dpElement, onBack, onSubmit }) => {
  const { t } = useTranslation();
  const [createdDpElement, setCreatedDpElement] = useState({ ...dpElement });
  const [stepsState, setStepsState] = useState<{
    steps: {
      [key: string]: { visited: boolean };
    };
    activeStep: number;
  }>({ steps: { 0: { visited: true } }, activeStep: 0 });
  const [stepsValidState, setStepsValidState] = useState<{
    [key: string]: boolean;
  }>({});
  const { steps, activeStep } = stepsState;
  const activeStepChangeHandler = useCallback(
    (newActiveStep: number) => {
      setStepsState({
        activeStep: newActiveStep,
        steps: {
          ...steps,
          [newActiveStep]: {
            ...(steps[newActiveStep] || {}),
            visited: true,
          },
        },
      });
    },
    [steps, setStepsState]
  );
  const onConditionStepUpdateHandler = useCallback(
    (
      stepNumber: number,
      data: Partial<DpElementTimeSwitchBranch>,
      isValid: boolean
    ) => {
      setStepsValidState({
        ...stepsValidState,
        [stepNumber]: isValid,
      });
      const updatedDpElement = copyObject(createdDpElement);
      if (!updatedDpElement.branches) {
        updatedDpElement.branches = [];
      }
      updatedDpElement.branches[branch] = {
        ...updatedDpElement.branches[branch],
        ...data,
        steps: [],
      };
      setCreatedDpElement(updatedDpElement);
    },
    [createdDpElement, branch, stepsValidState]
  );
  const isActiveStepValid = stepsValidState[activeStep];
  const breadcrumbItems: { label: string; active?: boolean }[] = useMemo(() => {
    return [
      {
        label: t("controls.name"),
        active: isActiveStepValid && steps[0]?.visited,
      },
      {
        label: t("controls.time"),
        active: isActiveStepValid && steps[1]?.visited,
      },
    ];
  }, [t, isActiveStepValid, steps]);
  const onTimeSwitchStepSubmitHandler = () => {
    onSubmit(createdDpElement);
  };
  return (
    <Wizard
      breadcrumbItems={breadcrumbItems}
      onActiveStepChange={activeStepChangeHandler}
    >
      <TimeSwitchNameStep
        dpElement={createdDpElement}
        onUpdate={onConditionStepUpdateHandler.bind(null, 0)}
        onBack={onBack}
        branch={branch}
      />
      <TimeSwitchTimeStep
        dpElement={createdDpElement}
        onUpdate={onConditionStepUpdateHandler.bind(null, 1)}
        branch={branch}
        onSubmit={onTimeSwitchStepSubmitHandler}
      />
    </Wizard>
  );
};

const TimeSwitchWizard: React.FC<{
  isOpen: boolean;
  dpElement: DpElementTimeSwitch;
  onRequestClose: (data?: { dpElement: DpElementTimeSwitch }) => void;
  action: ModalFormAction;
}> = ({ isOpen, dpElement, onRequestClose, action }) => {
  const { t } = useTranslation();
  const [createdDpElement, setCreatedDpElement] = useState({ ...dpElement });
  const [activeBranch, setActiveBranch] = useState<number | undefined>();
  const addConditionHandler = useCallback(() => {
    setActiveBranch(createdDpElement.branches?.length || 0);
  }, [createdDpElement]);
  const updateConditionHandler = useCallback((branchIdx) => {
    setActiveBranch(branchIdx);
  }, []);
  const deleteConditionHandler = useCallback(
    (branchIdx) => {
      setCreatedDpElement({
        ...createdDpElement,
        branches: createdDpElement.branches.filter(
          (_, idx) => idx !== branchIdx
        ),
      });
      if (branchIdx === activeBranch) {
        setActiveBranch(undefined);
      } else if (activeBranch !== undefined && branchIdx < activeBranch) {
        setActiveBranch(activeBranch - 1);
      }
    },
    [createdDpElement, activeBranch]
  );
  const onFinishStepNextHandler = useCallback(() => {
    onRequestClose({
      dpElement: createdDpElement,
    });
  }, [createdDpElement, onRequestClose]);
  const onWizardBackHandler = () => {
    setActiveBranch(undefined);
  };
  const onTimeSwitchWizardHandler = (dpElement: DpElementTimeSwitch) => {
    setCreatedDpElement(dpElement);
    setActiveBranch(undefined);
  };
  return (
    <BaseModal
      title={
        action === ModalFormAction.create
          ? t("dp-editor.time-switch-create-wizard.title")
          : t("controls.update-element")
      }
      onRequestClose={onRequestClose.bind(null, undefined)}
      isOpen={isOpen}
      isWizard={activeBranch !== undefined}
      hideFooterButtons={activeBranch !== undefined}
      modalStyles={{
        content: {
          width: "70%",
          maxWidth: "600px",
        },
      }}
      buttons={[
        {
          text:
            action === ModalFormAction.create
              ? t("controls.create-element")
              : t("controls.update-element"),
          props: {
            autoFocus: true,
            onClick: onFinishStepNextHandler,
            disabled: !createdDpElement.branches?.length,
            tooltip: !createdDpElement.branches?.length
              ? t("form-labels.add-condition-required-tooltip")
              : undefined,
          },
        },
      ]}
    >
      {activeBranch === undefined ? (
        <TimeSwitchConditions
          dpElement={createdDpElement}
          onAddCondition={addConditionHandler}
          onDeleteCondition={deleteConditionHandler}
          onUpdateCondition={updateConditionHandler}
          onNext={onFinishStepNextHandler}
        />
      ) : (
        <TimeSwitchWizardContent
          dpElement={createdDpElement}
          branch={activeBranch}
          onBack={onWizardBackHandler}
          onSubmit={onTimeSwitchWizardHandler}
        />
      )}
    </BaseModal>
  );
};

export default TimeSwitchWizard;
