import React, { useState } from "react";
import { RegisterOptions, useForm } from "react-hook-form";
import {
  nameFieldValidator,
  requiredValidator,
} from "src/utils/form";
import BaseModal, { BaseModalProps } from "src/components/BaseModal";
import { useTranslation } from "react-i18next";
import FormGroup from "src/components/FormGroup";
import { useHistory } from "react-router";
import { registerDemoAccount } from "./api";
import i18n from "i18next";

export const emailValidator = (required?: boolean): RegisterOptions => ({
  validate: (value: string) => {
    if (
      (required && !value.length) ||
      (value.length > 0 && !value.replace(/\s/g, "").length)
    ) {
      return i18n.t("form-validation-errors.field-required") as string;
    }

    // https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
    if (!value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      return "Invalid email address";
    }
    return true;
  },
});

export const maxLengthValidator = (length: number): RegisterOptions => ({
  maxLength: {
    value: length,
    message: "This field is too long",
  },
});

export const phoneNumberValidator = (required?: boolean): RegisterOptions => ({
  validate: (value: string) => {
    console.log('value is ', value.length, 'required is', required);
    if (
      (required && !value.length) ||
      (value.length > 0 && !value.replace(/\s/g, "").length)
    ) {
      return i18n.t("form-validation-errors.field-required") as string;
    }

    if (!value.length) return true;

    if (!value.match(/^(\+)?[0-9 ]+$/)) {
      return "Invalid phone number";
    }

    return true;
}});

const RegistrationForm: React.FC<BaseModalProps> = ({ onRequestClose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [apiError, setApiError] = useState<string|undefined>(undefined);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    fullName: string;
    companyName: string;
    email: string;
    phoneNumber?: string;
    termsOfUse: boolean;
  }>({
    defaultValues: {
      fullName: '',
      companyName: '',
      email: '',
      phoneNumber: '',
      termsOfUse: false,
    },
  });

  const submitHandler = handleSubmit(async (formData) => {
    try {
      await registerDemoAccount(formData.fullName, formData.companyName, formData.email, formData.phoneNumber);
      history.push('/register/emailSent');
    } catch (e: any) {
      console.warn("Could not register for demo", e);
      if (e.response && e.response.status) {
        if (e.response.status === 409) {
          // Unique value already used
          setApiError("This company name was already used for a demo.");
          return;
        }
      }
      setApiError("An unknown error occurred. Please try again later or contact Talksome support.");
    }
  });

  return (
    <BaseModal
      title={'Compass Demo'}
      onRequestClose={onRequestClose.bind(null, undefined)}
      isOpen={true}
      hideCloseButton={true}
      buttons={[
        { text: 'Continue', props: { onClick: submitHandler } },
      ]}
    >
      <div>
        <FormGroup
          header={'Name *'}
          error={errors.fullName}
          htmlFor={"id_fullname"}
        >
          <input
            type="text"
            className="studio-form__control"
            {...register("fullName", {
              ...nameFieldValidator(true),
            })}
            placeholder={"Your full name"}
            id="id_fullname"
          />
        </FormGroup>
        <FormGroup
          header={'Company name *'}
          error={errors.companyName}
          htmlFor={"id_companyname"}
        >
          <input
            type="text"
            className="studio-form__control"
            {...register("companyName", {
              ...nameFieldValidator(true),
              ...maxLengthValidator(30)
            })}
            placeholder={"Talksome"}
            id="id_companyname"
          />
        </FormGroup>
        <FormGroup
          header={'Email *'}
          error={errors.email}
          htmlFor="id_email"
        >
          <input
            type="text"
            className="studio-form__control"
            id="id_email"
            {...register("email", {
              ...emailValidator(true),
            })}
            placeholder={"taco@talksome.com"}
          />
        </FormGroup>
        <FormGroup
          header={'Phone number'}
          error={errors.phoneNumber}
          htmlFor="id_phonenumber"
        >
          <input
            type="text"
            className="studio-form__control"
            id="id_phonenumber"
            {...register("phoneNumber", {
              ...phoneNumberValidator(false),
            })}
            placeholder={"+31 6 123456789"}
          />
        </FormGroup>
        <FormGroup
          header={'Terms of use'}
          error={errors.termsOfUse}
          htmlFor="id_termsofuse"
        >
          <input
            type="checkbox"
            className="studio-form__control"
            id="id_termsofuse"
            {...register("termsOfUse", {
              ...requiredValidator()
            })}
          />
          By clicking continue you agree to our&nbsp;
          <a href="https://www.talksome.com/privacy-policy/" target="_blank">Privacy Policy</a> and &nbsp;
          <a href="https://www.talksome.com/compass-demo/terms/" target="_blank">Terms and Conditions</a>.
        </FormGroup>
        {apiError && <div style={{color: 'red'}}>{apiError}</div>}
      </div>
    </BaseModal>
  );
};

export default RegistrationForm;
