import React from "react";
import BaseModal, { BaseModalProps } from "src/components/BaseModal";
import { useTranslation } from "react-i18next";
import styles from "./EmailSentForm.module.css";
import { USE_MOCK_API_FOR_DEMODEMO } from "./api";
import { HELP_EMAIL, HELP_PHONE_NR, HELP_PHONE_STR } from "./utils";

const EmailSentForm: React.FC<BaseModalProps> = ({ onRequestClose }) => {
  const { t } = useTranslation();
  const backHandler = () => {
    window.location.href = 'https://www.talksome.com';
  };
  const dummyLink = "/confirmEmail/therighttoken";

  const header = (
    <div>
      <img src={"https://www.talksome.com/wp-content/uploads/2021/02/logo-alt-1.svg"} alt="Talksome logo"
         style={{width: 120}}
           className={"mt-8 mb-1"} />
      <br/>
      We sent you an email with a login link
    </div>
  );

  return (
    <div>
      <BaseModal
        title={''}
        onRequestClose={onRequestClose.bind(null, undefined)}
        isOpen={true}
        hideCloseButton={true}
        titleElem={header}
        buttons={[
          { text: 'Go back to Talksome.com', props: { onClick: backHandler } },
        ]}
      >
        <div className={styles.form}>
          <p>Please check your inbox. The link provided will take you to our demo.</p>

          <p>If you have any questions or you haven't received an email, don't hesitate to get in touch with us via phone
            (<a href={`tel:${HELP_PHONE_NR}`}>{HELP_PHONE_STR}</a>) or email (<a href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</a>).</p>

          {USE_MOCK_API_FOR_DEMODEMO && (
            <p>(because we don't actually send an email yet - click <a href={dummyLink}>here</a> to simulate clicking the mail link</p>
          )}
        </div>
      </BaseModal>
    </div>
  );
};

export default EmailSentForm;
