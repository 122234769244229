import { nanoid } from "nanoid";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DpElementTimeSwitch } from "src/app/types/dialplans";
import PropertyDisplay from "src/components/PropertyDisplay";
import SideBar, { SideBarProps } from "src/components/SideBar";
import { timeSwitchConditionDisplay } from "src/utils/datetime";
import { getDpElementTitle } from "src/utils/dialPlan";

type TimeSwitchSideBarProps = SideBarProps & {
  dpElement: DpElementTimeSwitch;
};

const TimeSwitchSideBar: React.FC<TimeSwitchSideBarProps> = (props) => {
  const { t } = useTranslation();
  const { dpElement } = props;
  const branchIds = useMemo(
    () => dpElement.branches.map(() => nanoid()),
    [dpElement]
  );
  return (
    <SideBar {...props}>
      <div className="px-3 py-2">
        <div className="text-l font-semibold mb-8">
          {t("dp-editor.time-switch-side-bar.title")}
        </div>
        {branchIds.map((branchId, idx) => (
          <div
            className="border border-r-0 border-l-0 border-t-0 border-solid border-gs-800 py-4"
            key={branchId}
          >
            <div className="flex justify-between items-center text-amber-500 font-bold mb-2 text-s">
              {t("controls.condition")} #{idx + 1}
            </div>
            <PropertyDisplay
              title={t("controls.name")}
              className="mb-2"
              value={getDpElementTitle(dpElement, { branch: idx }) || "-"}
            />
            <PropertyDisplay
              className="mb-3"
              title={t("controls.time")}
              value={timeSwitchConditionDisplay(
                dpElement.branches[idx].condition
              )}
            />
          </div>
        ))}
      </div>
    </SideBar>
  );
};

export default TimeSwitchSideBar;
