import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DpElementIvr } from "src/app/types/dialplans";
import PropertyDisplay from "src/components/PropertyDisplay";
import SideBar, { SideBarProps } from "src/components/SideBar";
import { useLoadDialplanElements } from "src/utils/hooks";

type IvrSideBarProps = SideBarProps & {
  dpElement: DpElementIvr;
};

const IvrSideBar: React.FC<IvrSideBarProps> = (props) => {
  const { t } = useTranslation();
  const { dpElement } = props;
  const { prompts } = useLoadDialplanElements();
  const detailsValues = useMemo(
    () => [
      {
        label: t("controls.prompt"),
        value:
          prompts.find((prompt) => prompt.resourceId === dpElement.promptId)
            ?.name || "-",
      },
      {
        label: t("dp-editor.ivr-side-bar.options-key-label"),
        value: dpElement.branches?.map((item) => item.key).join(", ") || "-",
      },
      {
        label: t("dp-editor.ivr-side-bar.number-of-repeats-label"),
        value: dpElement.repeat,
      },
      {
        label: t("dp-editor.ivr-side-bar.timeout-label"),
        value: dpElement.timeout,
      },
    ],
    [dpElement, t, prompts]
  );

  return (
    <SideBar {...props}>
      <div className="px-3 py-2">
        <div className="text-l font-semibold mb-8">{dpElement.name}</div>
        {detailsValues.map((item, idx) => (
          <PropertyDisplay
            key={idx}
            title={item.label}
            value={item.value}
            className="mb-5"
          />
        ))}
      </div>
    </SideBar>
  );
};

export default IvrSideBar;
