// TODO: move to different directory

import axios from "axios";
import {
  CompassCompany,
  CompassExternalNumber,
  CompassPhone,
  CompassPhoneModel,
  CompassSipAccount,
} from "../../app/types/compass";

export const COMPASS_BASEDOM = "demo.cmpdev.com";
const REST_BASE_URL = `https://rest.${COMPASS_BASEDOM}`;

// can be used for development and testing - avoids the real compass api if set to true
export const USE_MOCK_API_FOR_DEMODEMO = false;

export interface DemoAccount {
  username: string;
  password: string;
}

export interface DemoAccountUserData {
  externalNumber: string;
  isRealExternalNumber: string;
}

function createApi() {
  return axios.create({
    headers: {
      "Content-Type": "application/json",
    },
    baseURL: REST_BASE_URL,
  });
}

function createApiWithCredentials(username: string, password: string) {
  return axios.create({
    headers: {
      "Content-Type": "application/json",
    },
    auth: {
      username: username,
      password: password,
    },
    baseURL: REST_BASE_URL,
  });
}

export async function registerDemoAccount(
  fullname: string,
  companyName: string,
  email: string,
  phoneNumber?: string
) {
  // POST https://host/internal/demo/registration

  if (USE_MOCK_API_FOR_DEMODEMO) {
    return Promise.resolve();
  }

  const api = createApi();
  return api.post("/internal/demo/registration", {
    fullname: fullname,
    companyName: companyName,
    email: email,
    phoneNumber: phoneNumber,
  });
}

export async function createDemoAccount(token: string): Promise<DemoAccount> {
  // POST https://host/internal/demo/account

  if (USE_MOCK_API_FOR_DEMODEMO) {
    console.log("we got token", token);
    if (token === "therighttoken") {
      return Promise.resolve({
        username: "nathan2?nathan.cmpdev.com",
        password: "testtest",
      });
    } else {
      throw new Error("an api response of 400");
    }
  }

  const api = createApi();
  const response = await api.post("/internal/demo/account", {
    token: token,
  });
  return response.data as DemoAccount;
}

export async function loadAccountData(
  username: string,
  password: string
): Promise<DemoAccountUserData> {
  const api = createApi();
  const response = await api.get("/internal/demo/account/data", {
    auth: {
      username: username,
      password: password,
    },
  });
  return response.data as DemoAccountUserData;
}

export async function findExternalNumber(
  username: string,
  password: string,
  externalNumber: string
): Promise<CompassExternalNumber> {
  const api = createApiWithCredentials(username, password);
  const response = await api.get(
    `/externalNumber/lookup?phoneNumber=${externalNumber}`
  );
  return response.data as CompassExternalNumber;
}

export interface PhoneData {
  phone: CompassPhone;
  sipAccount: CompassSipAccount;
}

export async function findCloudsoftPhone(
  username: string,
  password: string
): Promise<PhoneData> {
  const api = createApiWithCredentials(username, password);

  const phoneModels = (await api.get(`/phoneModel/list`))
    .data as CompassPhoneModel[];
  const softphoneModel = phoneModels.find(
    (x) => x.shortname === "compasssoft"
  )!!;

  const company = (await api.get(`/company`)).data as CompassCompany;
  const phones = (await api.get(`/company/${company.entityId}/phones`))
    .data as CompassPhone[];

  const softphone = phones.find((x) => x.model === softphoneModel.self)!!;
  const sipAccounts = (
    await api.get(`/phone/${softphone.resourceId}/sipAccounts`)
  ).data as CompassSipAccount[];

  return {
    phone: softphone,
    sipAccount: sipAccounts[0],
  };
}

export async function doApiCallback(username: string, password: string, extNumberId: number, destination: string) {
  const api = createApiWithCredentials(username, password);
  await api.post(`/externalNumber/${extNumberId}/callback`,
    {
      outboundNumber: destination,
      outboundAnonymous: false,
    }
  );
}
