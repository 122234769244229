import { RootState } from "src/app/rootReducer";
import { CompassDpSwitch, CompassResourceType } from "src/app/types/compass";
import { genericDataSliceFactory } from "src/utils/genericDataSliceFactory";
import { getOrderedResources } from "src/utils/resources";

const { slice, loadItemsThunk } = genericDataSliceFactory<CompassDpSwitch>(
  "dpSwitches",
  (companyId) => getOrderedResources(companyId, CompassResourceType.dpswitch)
);

export const loadDpSwitches = loadItemsThunk;

export const selectDpSwitchesState = ({ dpSwitches }: RootState) => dpSwitches;

export default slice.reducer;
